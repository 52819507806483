export const GET_CONTROL_REFERENCES = "GET_CONTROL_REFERENCES";
export const GET_CONTROL_REFERENCES_SUCCESS = "GET_CONTROL_REFERENCES_SUCCESS";
export const GET_CONTROL_REFERENCES_FAILURE = "GET_CONTROL_REFERENCES_FAILURE";

export const GET_SINGLE_CONTROL_REFERENCE = "GET_SINGLE_CONTROL_REFERENCE";
export const GET_SINGLE_CONTROL_REFERENCE_SUCCESS =
  "GET_SINGLE_CONTROL_REFERENCE_SUCCESS";
export const GET_SINGLE_CONTROL_REFERENCE_FAILURE =
  "GET_SINGLE_CONTROL_REFERENCE_FAILURE";

export const ADD_CONTROL_REFERENCE = "ADD_CONTROL_REFERENCE";
export const ADD_CONTROL_REFERENCE_SUCCESS = "ADD_CONTROL_REFERENCE_SUCCESS";
export const ADD_CONTROL_REFERENCE_FAILURE = "ADD_CONTROL_REFERENCE_FAILURE";

export const EDIT_CONTROL_REFERENCE = "EDIT_CONTROL_REFERENCE";
export const EDIT_CONTROL_REFERENCE_SUCCESS = "EDIT_CONTROL_REFERENCE_SUCCESS";
export const EDIT_CONTROL_REFERENCE_FAILURE = "EDIT_CONTROL_REFERENCE_FAILURE";

export const DELETE_CONTROL_REFERENCE = "DELETE_CONTROL_REFERENCE";
export const DELETE_CONTROL_REFERENCE_SUCCESS =
  "DELETE_CONTROL_REFERENCE_SUCCESS";
export const DELETE_CONTROL_REFERENCE_FAILURE =
  "DELETE_CONTROL_REFERENCE_FAILURE";

export const SET_DEFAULT_CONTROL_REFERENCE_SUCCESS =
  "SET_DEFAULT_CONTROL_REFERENCE_SUCCESS";
