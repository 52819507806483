import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addAdmin, getRoles } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import Select from "react-select";
import { getId } from "helpers/functions";
import { useEffect } from "react";

const AddAdmin = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.admins);
  const { roles } = useSelector((state) => state?.roles);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addAdmin({ data, navigate }));
  };
  const renderRoles = () => {
    return roles?.map((city) => ({
      label: city.name,
      value: city?.name,
      id: getId(city),
    }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addAdmin" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="name" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="phone" />
                      </h5>
                      <div>
                        <input
                          type="number"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "phone" })}
                          {...register("phone", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.phone?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="email" />
                      </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "email" })}
                          {...register("email", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="password" />
                      </h5>
                      <div>
                        <input
                          type="password"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "password" })}
                          {...register("password", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.password?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="role" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="role"
                          render={({ field: { onChange, value: valuee } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderRoles()}
                              placeholder={formatMessage({
                                id: "selectRole",
                              })}
                              isMulti={false}
                              onChange={(value) => {
                                onChange(getId(value));
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="add" />
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
