import {
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_ALL_CONTACTS_FAILURE,

  DELETE_CONTACTS,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACTS_FAILURE,

  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
} from "./actionTypes";

export const getAllContacts = (payload) => {
  return {
    type: GET_ALL_CONTACTS,
    payload: payload
  };
}
export const getAllContactsSuccess = (payload) => {
  return {
    type: GET_ALL_CONTACTS_SUCCESS,
    payload: payload,
  };
}

export const getAllContactsFailure = (error) => {
  return {
    type: GET_ALL_CONTACTS_FAILURE,
    payload: error,
  };
}

export const deleteContact = (id) => {
  return {
    type: DELETE_CONTACTS,
    payload: id
  }
}

export const deleteContactSuccess = (id) => {
  return {
    type: DELETE_CONTACTS_SUCCESS,
    payload: id
  }
}

export const deleteContactFailure = (error) => {
  return {
    type: DELETE_CONTACTS_FAILURE,
    payload: error
  }
}

export const getContact = (id) => {

  return {
    type: GET_CONTACTS,
    payload: id
  }
}

export const getContactSuccess = (id) => {
  return {
    type: GET_CONTACTS_SUCCESS,
    payload: id
  }
}

export const getContactFailure = (error) => {
  return {
    type: GET_CONTACTS_FAILURE,
    payload: error
  }
}