import server from "./server";

export const getProductsApi = async (payload) => {
  const response =
    // payload?.searchParams
    // ? await server().get(
    //     `/products?page=${payload?.searchParams?.page || 1}&limit=${
    //       payload?.searchParams?.limit
    //     }`
    //   )
    // :
    await server().get(`/products?${payload||''}`);
  return response.data;
};

export const getProductsVariationsApi = async () => {
  const response = await server().get("/variations");
  return response.data;
};

export const getProductsVariationsStylesApi = async () => {
  const response = await server().get("/variationStyles");
  return response.data;
};

export const getSingleProductApi = async (id) => {
  const response = await server().get(`/products/${id}`);
  return response.data;
};

export const addProductApi = async (payload) => {
  const response = await server().post("/products", payload);
  return response.data;
};

export const editProductApi = async ({ id, data }) => {
  const response = await server().put(`/products/${id}`, data);
  return response.data;
};

export const updateProductMainDataApi = async ({ id, data }) => {
  const response = await server().put(`/products/updateMainData/${id}`, {
    ...data,
  });
  return response.data;
};

export const deleteProductApi = async (id) => {
  const response = await server().delete(`/products/${id}`);
  return response.data;
};
