import {
  GET_SUBCONTROL_DOMAINS,
  GET_SUBCONTROL_DOMAINS_FAILURE,
  GET_SUBCONTROL_DOMAINS_SUCCESS,
  ADD_SUBCONTROL_DOMAIN,
  ADD_SUBCONTROL_DOMAIN_SUCCESS,
  ADD_SUBCONTROL_DOMAIN_FAILURE,
  EDIT_SUBCONTROL_DOMAIN,
  EDIT_SUBCONTROL_DOMAIN_SUCCESS,
  EDIT_SUBCONTROL_DOMAIN_FAILURE,
  DELETE_SUBCONTROL_DOMAIN,
  DELETE_SUBCONTROL_DOMAIN_SUCCESS,
  DELETE_SUBCONTROL_DOMAIN_FAILURE,
  GET_SINGLE_SUBCONTROL_DOMAIN,
  GET_SINGLE_SUBCONTROL_DOMAIN_SUCCESS,
  GET_SINGLE_SUBCONTROL_DOMAIN_FAILURE,
  SET_DEFAULT_SUBCONTROL_DOMAIN_SUCCESS,
} from "./actionTypes";

export const getSubControlDomains = () => {
  return {
    type: GET_SUBCONTROL_DOMAINS,
  };
};

export const getSubControlDomainsSuccess = (users) => {
  return {
    type: GET_SUBCONTROL_DOMAINS_SUCCESS,
    payload: users,
  };
};

export const getSubControlDomainsFailure = (error) => {
  return {
    type: GET_SUBCONTROL_DOMAINS_FAILURE,
    payload: error,
  };
};

export const getSingleSubControlDomain = (id) => {
  return {
    type: GET_SINGLE_SUBCONTROL_DOMAIN,
    payload: id,
  };
};

export const getSingleSubControlDomainSuccess = (user) => {
  return {
    type: GET_SINGLE_SUBCONTROL_DOMAIN_SUCCESS,
    payload: user,
  };
};

export const getSingleSubControlDomainFailure = (error) => {
  return {
    type: GET_SINGLE_SUBCONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const addSubControlDomain = (payload) => {
  return {
    type: ADD_SUBCONTROL_DOMAIN,
    payload,
  };
};

export const addSubControlDomainSuccess = (payload) => {
  return {
    type: ADD_SUBCONTROL_DOMAIN_SUCCESS,
    payload,
  };
};

export const addSubControlDomainFailure = (error) => {
  return {
    type: ADD_SUBCONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const editSubControlDomain = (payload) => {
  return {
    type: EDIT_SUBCONTROL_DOMAIN,
    payload,
  };
};

export const editSubControlDomainSuccess = (payload) => {
  return {
    type: EDIT_SUBCONTROL_DOMAIN_SUCCESS,
    payload,
  };
};

export const editSubControlDomainFailure = (error) => {
  return {
    type: EDIT_SUBCONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const deleteSubControlDomain = (id) => {
  return {
    type: DELETE_SUBCONTROL_DOMAIN,
    payload: id,
  };
};

export const deleteSubControlDomainSuccess = (id) => {
  return {
    type: DELETE_SUBCONTROL_DOMAIN_SUCCESS,
    payload: id,
  };
};

export const deleteSubControlDomainFailure = (error) => {
  return {
    type: DELETE_SUBCONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const setDefaultSubControlDomainSuccess = (payload) => {
  return {
    type: SET_DEFAULT_SUBCONTROL_DOMAIN_SUCCESS,
    payload,
  };
};
