import {
  GET_SECTORS,
  GET_SECTORS_FAILURE,
  GET_SECTORS_SUCCESS,
  ADD_SECTOR,
  ADD_SECTOR_SUCCESS,
  ADD_SECTOR_FAILURE,
  EDIT_SECTOR,
  EDIT_SECTOR_SUCCESS,
  EDIT_SECTOR_FAILURE,
  DELETE_SECTOR,
  DELETE_SECTOR_SUCCESS,
  DELETE_SECTOR_FAILURE,
  GET_SINGLE_SECTOR,
  GET_SINGLE_SECTOR_SUCCESS,
  GET_SINGLE_SECTOR_FAILURE,
  SET_DEFAULT_SECTOR_SUCCESS,
  SET_DEFAULT_ITEM,
} from "./actionTypes";

export const getSectors = () => {
  return {
    type: GET_SECTORS,
  };
};

export const getSectorsSuccess = (users) => {
  return {
    type: GET_SECTORS_SUCCESS,
    payload: users,
  };
};

export const getSectorsFailure = (error) => {
  return {
    type: GET_SECTORS_FAILURE,
    payload: error,
  };
};

export const getSingleSector = (id) => {
  return {
    type: GET_SINGLE_SECTOR,
    payload: id,
  };
};

export const getSingleSectorSuccess = (user) => {
  return {
    type: GET_SINGLE_SECTOR_SUCCESS,
    payload: user,
  };
};

export const getSingleSectorFailure = (error) => {
  return {
    type: GET_SINGLE_SECTOR_FAILURE,
    payload: error,
  };
};

export const addSector = (payload) => {
  return {
    type: ADD_SECTOR,
    payload,
  };
};

export const addSectorSuccess = (payload) => {
  return {
    type: ADD_SECTOR_SUCCESS,
    payload,
  };
};

export const addSectorFailure = (error) => {
  return {
    type: ADD_SECTOR_FAILURE,
    payload: error,
  };
};

export const editSector = (payload) => {
  return {
    type: EDIT_SECTOR,
    payload,
  };
};

export const editSectorSuccess = (payload) => {
  return {
    type: EDIT_SECTOR_SUCCESS,
    payload,
  };
};

export const editSectorFailure = (error) => {
  return {
    type: EDIT_SECTOR_FAILURE,
    payload: error,
  };
};

export const deleteSector = (id) => {
  return {
    type: DELETE_SECTOR,
    payload: id,
  };
};

export const deleteSectorSuccess = (id) => {
  return {
    type: DELETE_SECTOR_SUCCESS,
    payload: id,
  };
};

export const deleteSectorFailure = (error) => {
  return {
    type: DELETE_SECTOR_FAILURE,
    payload: error,
  };
};

export const setDefaultSectorSuccess = (payload) => {
  return {
    type: SET_DEFAULT_SECTOR_SUCCESS,
    payload,
  };
};

export const setDefaultItem = (payload) => {
  return {
    type: SET_DEFAULT_ITEM,
    payload,
  };
};
