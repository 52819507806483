export const GET_SECTORS = "GET_SECTORS";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_FAILURE = "GET_SECTORS_FAILURE";

export const GET_SINGLE_SECTOR = "GET_SINGLE_SECTOR";
export const GET_SINGLE_SECTOR_SUCCESS = "GET_SINGLE_SECTOR_SUCCESS";
export const GET_SINGLE_SECTOR_FAILURE = "GET_SINGLE_SECTOR_FAILURE";

export const ADD_SECTOR = "ADD_SECTOR";
export const ADD_SECTOR_SUCCESS = "ADD_SECTOR_SUCCESS";
export const ADD_SECTOR_FAILURE = "ADD_SECTOR_FAILURE";

export const EDIT_SECTOR = "EDIT_SECTOR";
export const EDIT_SECTOR_SUCCESS = "EDIT_SECTOR_SUCCESS";
export const EDIT_SECTOR_FAILURE = "EDIT_SECTOR_FAILURE";

export const DELETE_SECTOR = "DELETE_SECTOR";
export const DELETE_SECTOR_SUCCESS = "DELETE_SECTOR_SUCCESS";
export const DELETE_SECTOR_FAILURE = "DELETE_SECTOR_FAILURE";

export const SET_DEFAULT_SECTOR_SUCCESS = "SET_DEFAULT_SECTOR_SUCCESS";
export const SET_DEFAULT_ITEM = "SET_DEFAULT_ITEM";
