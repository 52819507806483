import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import store from "./store";
import App from "./App";

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.css";
import "./styles/subGlobal.css";
import "./styles/responsive.css";

import Wrapper from "./wrapper";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer rtl position="bottom-left" limit={3} />
      <Wrapper>
        <App />
      </Wrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
