import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editCategory, getSingleProductCategory } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Loader from "components/shared/Loader";
import StickyLoader from "components/shared/StickyLoader";
import { toast } from "react-toastify";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { getId, handleImage } from "helpers/functions";
import { FormattedMessage } from "react-intl";
const EditCategory = ({ category = false, handleClose = () => {} }) => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let { singleCategory, loading, editLoading, error } = useSelector(
    (state) => state.categories
  );
  if (category !== false) singleCategory = category;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  useEffect(() => {
    // dispatch(getOptions());
    if (id?.length > 0) dispatch(getSingleProductCategory(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (error === "Validation Error")
      toast.error("تاكد من صحة البيانات (منها عدم تكرار الاسم)");
  }, [error]);

  useEffect(() => {
    reset({
      title: singleCategory?.title,
      description: singleCategory?.description,
      isAvailable: singleCategory?.isAvailable,
    });
  }, [reset, singleCategory]);

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // data.image = "uploads/" + data.image.split("/").pop();
    // data.icon = "uploads/" + data.image.split("/").pop();

    dispatch(
      editCategory({
        id: getId(category) || id,
        data,
        navigate,
        callBack: handleClose,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      {!!editLoading && <StickyLoader fill="#e9deff" />}
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editCategory" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col xs={12} md="6">
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  <Col xs={12} md="6">
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="isAvailable" />
                      </h5>
                      <div>
                        <input
                          type="checkbox"
                          className="form-control form-outline"
                          {...register("isAvailable")}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.isAvailable && (
                          <FormattedMessage id="requiredField" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Row />
                  <Col xs={12} md="6">
                    <MultiLangWrapper
                      label={
                        <h5>
                          <FormattedMessage id={"description"} />
                        </h5>
                      }
                      errors={errors?.description}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <textarea
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`description.${lang}`, {
                            required: true,
                          })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  <Row />

                  <Col lg={12} xs={12}>
                    <div className="form-group d-inline-flex">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="save" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
