import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addProductCategoriesApi,
  getCategoriesApi,
  deleteProductCategoriesApi,
  editCategoriesApi,
  getSingleProductCategoryApi,
  editCategoriesOrderApi,
} from "api/productCategories";

// Login Redux States
import {
  ADD_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORIES,
  EDIT_PRODUCT_CATEGORIES,
  EDIT_PRODUCT_CATEGORIES_ORDER,
  GET_PRODUCT_CATEGORIES,
  GET_SINGLE_PRODUCT_CATEGORY,
} from "./actionTypes";
import {
  addProductCategoriesFailure,
  addProductCategoriesSuccess,
  deleteProductCategoriesFailure,
  deleteProductCategoriesSuccess,
  editCategoriesFailure,
  editCategoriesOrderFailure,
  editCategoriesOrderSuccess,
  editCategoriesSuccess,
  getCategoriesFailure,
  getCategoriesSuccess,
  getSingleProductCategoryFailure,
  getSingleProductCategorySuccess,
} from "./actions";

function* getCategories() {
  try {
    const { data } = yield call(getCategoriesApi);
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCategoriesFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleProductCategory({ payload }) {
  try {
    const { data } = yield call(getSingleProductCategoryApi, payload);
    yield put(getSingleProductCategorySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleProductCategoryFailure(error?.response?.data || error));
  }
}

function* addCategory({ payload }) {
  try {
    const { data } = yield call(addProductCategoriesApi, payload.data);
    yield put(addProductCategoriesSuccess(data));
    if (typeof payload?.callBack == "function") yield payload.callBack();
    else yield payload.navigate("/categories");
  } catch (error) {
    console.log(error);
    yield put(
      addProductCategoriesFailure(error.response?.data?.message || error)
    );
  }
}

function* editCategory({ payload }) {
  try {
    let { data } = yield call(editCategoriesApi, payload);
    yield put(editCategoriesSuccess(data));
    yield payload.navigate("/categories");
    if (typeof payload?.callBack == "function") payload.callBack();
  } catch (error) {
    console.log(error);
    yield put(editCategoriesFailure(error?.response?.data || error));
  }
}

function* editCategoriesOrder({ payload }) {
  try {
    const { data } = yield call(editCategoriesOrderApi, payload);
    yield put(editCategoriesOrderSuccess(data));
    // yield payload.navigate("/categories");
  } catch (error) {
    console.log(error);
    yield put(editCategoriesOrderFailure(error?.response?.data || error));
  }
}

function* deleteProductCategories({ payload }) {
  try {
    yield call(deleteProductCategoriesApi, payload);
    yield put(deleteProductCategoriesSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteProductCategoriesFailure(error.response?.data || error));
  }
}

export function* watchProductCategories() {
  yield takeEvery(GET_PRODUCT_CATEGORIES, getCategories);
}

export function* watchGetSingleProductCategory() {
  yield takeEvery(GET_SINGLE_PRODUCT_CATEGORY, getSingleProductCategory);
}

export function* watchAddProductCategories() {
  yield takeEvery(ADD_PRODUCT_CATEGORIES, addCategory);
}

export function* watcheditCategories() {
  yield takeEvery(EDIT_PRODUCT_CATEGORIES, editCategory);
}

export function* watcheditCategoriesOrder() {
  yield takeEvery(EDIT_PRODUCT_CATEGORIES_ORDER, editCategoriesOrder);
}

export function* watchDeleteProductCategories() {
  yield takeEvery(DELETE_PRODUCT_CATEGORIES, deleteProductCategories);
}

function* productCategoriesSaga() {
  yield all([fork(watchProductCategories)]);
  yield all([fork(watchGetSingleProductCategory)]);
  yield all([fork(watchAddProductCategories)]);
  yield all([fork(watcheditCategories)]);
  yield all([fork(watcheditCategoriesOrder)]);
  yield all([fork(watchDeleteProductCategories)]);
}

export default productCategoriesSaga;
