import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getPermissionsApi,
  addPermissionApi,
  editPermissionApi,
  deletePermissionApi,
  getSinglePermissionApi,
} from "../../api/permissions";

// Login Redux States
import {
  ADD_PERMISSION,
  DELETE_PERMISSION,
  EDIT_PERMISSION,
  GET_SINGLE_PERMISSION,
  GET_PERMISSIONS,
} from "./actionTypes";
import {
  addPermissionFailure,
  addPermissionSuccess,
  getPermissionsSuccess,
  getPermissionsFailure,
  editPermissionSuccess,
  editPermissionFailure,
  deletePermissionSuccess,
  deletePermissionFailure,
  getSinglePermissionSuccess,
  getSinglePermissionFailure,
} from "./actions";

function* getPermissions() {
  try {
    const { data } = yield call(getPermissionsApi);
    yield put(getPermissionsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getPermissionsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSinglePermission({ payload }) {
  try {
    const { data } = yield call(getSinglePermissionApi, payload);
    yield put(getSinglePermissionSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSinglePermissionFailure(error?.response?.data || error));
  }
}

function* addPermission({ payload }) {
  try {
    const { data } = yield call(addPermissionApi, payload.data);
    yield put(addPermissionSuccess(data));
    yield payload.navigate("/permissions");
  } catch (error) {
    console.log(error);
    yield put(addPermissionFailure(error.response?.data || error));
  }
}

function* editPermission({ payload }) {
  try {
    const { data } = yield call(editPermissionApi, payload);
    yield put(editPermissionSuccess(data));
    yield payload.navigate("/permissions");
  } catch (error) {
    console.log(error);
    yield put(editPermissionFailure(error.response?.data || error));
  }
}

function* deletePermission({ payload }) {
  try {
    yield call(deletePermissionApi, payload);
    yield put(deletePermissionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deletePermissionFailure(error?.response?.data || error));
  }
}

export function* watchGetPermissions() {
  yield takeEvery(GET_PERMISSIONS, getPermissions);
}

export function* watchSinglePermission() {
  yield takeEvery(GET_SINGLE_PERMISSION, getSinglePermission);
}

export function* watchAddPermission() {
  yield takeEvery(ADD_PERMISSION, addPermission);
}

export function* watchEditPermission() {
  yield takeEvery(EDIT_PERMISSION, editPermission);
}

export function* watchDeletePermission() {
  yield takeEvery(DELETE_PERMISSION, deletePermission);
}

function* PermissionsSaga() {
  yield all([fork(watchGetPermissions)]);
  yield all([fork(watchSinglePermission)]);
  yield all([fork(watchAddPermission)]);
  yield all([fork(watchEditPermission)]);
  yield all([fork(watchDeletePermission)]);
}

export default PermissionsSaga;
