import {
  GET_CONTROL_DOMAINS,
  GET_CONTROL_DOMAINS_FAILURE,
  GET_CONTROL_DOMAINS_SUCCESS,
  ADD_CONTROL_DOMAIN,
  ADD_CONTROL_DOMAIN_SUCCESS,
  ADD_CONTROL_DOMAIN_FAILURE,
  EDIT_CONTROL_DOMAIN,
  EDIT_CONTROL_DOMAIN_SUCCESS,
  EDIT_CONTROL_DOMAIN_FAILURE,
  DELETE_CONTROL_DOMAIN,
  DELETE_CONTROL_DOMAIN_SUCCESS,
  DELETE_CONTROL_DOMAIN_FAILURE,
  GET_SINGLE_CONTROL_DOMAIN,
  GET_SINGLE_CONTROL_DOMAIN_SUCCESS,
  GET_SINGLE_CONTROL_DOMAIN_FAILURE,
  SET_DEFAULT_CONTROL_DOMAIN_SUCCESS,
} from "./actionTypes";

export const getControlDomains = () => {
  return {
    type: GET_CONTROL_DOMAINS,
  };
};

export const getControlDomainsSuccess = (users) => {
  return {
    type: GET_CONTROL_DOMAINS_SUCCESS,
    payload: users,
  };
};

export const getControlDomainsFailure = (error) => {
  return {
    type: GET_CONTROL_DOMAINS_FAILURE,
    payload: error,
  };
};

export const getSingleControlDomain = (id) => {
  return {
    type: GET_SINGLE_CONTROL_DOMAIN,
    payload: id,
  };
};

export const getSingleControlDomainSuccess = (user) => {
  return {
    type: GET_SINGLE_CONTROL_DOMAIN_SUCCESS,
    payload: user,
  };
};

export const getSingleControlDomainFailure = (error) => {
  return {
    type: GET_SINGLE_CONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const addControlDomain = (payload) => {
  return {
    type: ADD_CONTROL_DOMAIN,
    payload,
  };
};

export const addControlDomainSuccess = (payload) => {
  return {
    type: ADD_CONTROL_DOMAIN_SUCCESS,
    payload,
  };
};

export const addControlDomainFailure = (error) => {
  return {
    type: ADD_CONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const editControlDomain = (payload) => {
  return {
    type: EDIT_CONTROL_DOMAIN,
    payload,
  };
};

export const editControlDomainSuccess = (payload) => {
  return {
    type: EDIT_CONTROL_DOMAIN_SUCCESS,
    payload,
  };
};

export const editControlDomainFailure = (error) => {
  return {
    type: EDIT_CONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};

export const deleteControlDomain = (id) => {
  return {
    type: DELETE_CONTROL_DOMAIN,
    payload: id,
  };
};

export const deleteControlDomainSuccess = (id) => {
  return {
    type: DELETE_CONTROL_DOMAIN_SUCCESS,
    payload: id,
  };
};

export const deleteControlDomainFailure = (error) => {
  return {
    type: DELETE_CONTROL_DOMAIN_FAILURE,
    payload: error,
  };
};
export const setDefaultControlDomainSuccess = (payload) => {
  return {
    type: SET_DEFAULT_CONTROL_DOMAIN_SUCCESS,
    payload,
  };
};
