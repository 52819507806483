import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribers } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import ControlArea from "components/Layout/ControlArea";

const Subscribers = () => {
  const { subscribers } = useSelector((state) => state.subscribers);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getUsers());
    dispatch(getSubscribers());
  }, [dispatch]);

  return (
    <div className="users-wrap">
      <ControlArea cardTxt="المشتركين" icon={peopleIcon} />
      <Table data={subscribers} />
    </div>
  );
};

export default Subscribers;
