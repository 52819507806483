export const GET_ORGANIZATION_TYPES = "GET_ORGANIZATION_TYPES";
export const GET_ORGANIZATION_TYPES_SUCCESS = "GET_ORGANIZATION_TYPES_SUCCESS";
export const GET_ORGANIZATION_TYPES_FAILURE = "GET_ORGANIZATION_TYPES_FAILURE";

export const GET_SINGLE_ORGANIZATION_TYPE = "GET_SINGLE_ORGANIZATION_TYPE";
export const GET_SINGLE_ORGANIZATION_TYPE_SUCCESS =
  "GET_SINGLE_ORGANIZATION_TYPE_SUCCESS";
export const GET_SINGLE_ORGANIZATION_TYPE_FAILURE =
  "GET_SINGLE_ORGANIZATION_TYPE_FAILURE";

export const ADD_ORGANIZATION_TYPE = "ADD_ORGANIZATION_TYPE";
export const ADD_ORGANIZATION_TYPE_SUCCESS = "ADD_ORGANIZATION_TYPE_SUCCESS";
export const ADD_ORGANIZATION_TYPE_FAILURE = "ADD_ORGANIZATION_TYPE_FAILURE";

export const EDIT_ORGANIZATION_TYPE = "EDIT_ORGANIZATION_TYPE";
export const EDIT_ORGANIZATION_TYPE_SUCCESS = "EDIT_ORGANIZATION_TYPE_SUCCESS";
export const EDIT_ORGANIZATION_TYPE_FAILURE = "EDIT_ORGANIZATION_TYPE_FAILURE";

export const DELETE_ORGANIZATION_TYPE = "DELETE_ORGANIZATION_TYPE";
export const DELETE_ORGANIZATION_TYPE_SUCCESS =
  "DELETE_ORGANIZATION_TYPE_SUCCESS";
export const DELETE_ORGANIZATION_TYPE_FAILURE =
  "DELETE_ORGANIZATION_TYPE_FAILURE";

export const SET_DEFAULT_ORGANIZATION_TYPE_SUCCESS =
  "SET_DEFAULT_ORGANIZATION_TYPE_SUCCESS";
