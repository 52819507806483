import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editPackage, getSinglePackage } from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import SlidersArray from "./SliderArray";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { FormattedMessage } from "react-intl";

const EditPackage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singlePackage, loading } = useSelector((state) => state?.packages);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getSinglePackage(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singlePackage?.title,
      features: singlePackage?.features,
      price: singlePackage?.price,
      isRecommended: singlePackage?.isRecommended,
    });
    return () => reset({});
  }, [reset, singlePackage]);

  const onSubmit = (data) => {
    dispatch(editPackage({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editPackage" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  <Col xs={8}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="price" />
                      </h5>
                      <div>
                        <input
                          type="number"
                          className="form-control form-outline"
                          {...register("price", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="isRecommended" />
                      </h5>
                      <div>
                        <input
                          type="checkbox"
                          className="form-control form-outline"
                          {...register("isRecommended")}
                        />
                      </div>
                    </div>
                  </Col>

                  <SlidersArray
                    register={register}
                    control={control}
                    errors={errors}
                  />

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="save" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPackage;
