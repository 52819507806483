export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_SINGLE_ORDER_SUCCESS = "GET_SINGLE_ORDER_SUCCESS";
export const GET_SINGLE_ORDER_FAILURE = "GET_SINGLE_ORDER_FAILURE";

export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAILURE = "CHANGE_ORDER_STATUS_FAILURE";
