import { getId } from "helpers/functions";
import {
  GET_SUBSCRIBERS,
  GET_SUBSCRIBERS_SUCCESS,
  GET_SUBSCRIBERS_FAILURE,
  DELETE_SUBSCRIBERS,
  DELETE_SUBSCRIBERS_SUCCESS,
  DELETE_SUBSCRIBERS_FAILURE,
} from "./actionTypes";

const initialState = {
  subscribers: [],
  loading: false,
  error: "",
};

const productTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIBERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUBSCRIBERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        subscribers: [...action.payload?.subscribers],
      };
      break;
    case GET_SUBSCRIBERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ---------------------------------------------------------------

    case DELETE_SUBSCRIBERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SUBSCRIBERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        subscribers: state?.subscribers?.filter(
          (e) => getId(e) !== action.payload
        ),
      };
      break;
    case DELETE_SUBSCRIBERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default productTypes;
