import React, { useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
// import { Col, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Filter from "components/shared/Filter";
import { deleteSubscribers } from "store/actions";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const Table = (props) => {
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // dispatch(getUsers());
    const timeout = setTimeout(() => {
      setRows(rows);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, rows]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // eslint-disable-next-line no-unused-vars
  const [filterText, setFilterText] = useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  // const subHeaderComponent = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };

  //   return (
  //     <Filter
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  // const handleEditUser = (id) => {
  //   navigate(`/users/${id}`);
  // };

  const handleDeleteUser = (id) => {
    dispatch(deleteSubscribers(id));
  };

  const columns = [
    {
      name: "البريد الالكتروني",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "حذف",
      selector: (row) => (
        <button
          className="btn btn-danger px-2"
          onClick={() => handleDeleteUser(getId(row))}
        >
          <DeleteIcon fill="white" />
        </button>
      ),
      sortable: true,
    },
  ];

  return (
    <div className="table-wrap">
      <div className="card">
        <Col xl={6} md={12} xs={12}>
          <div className="card-head">
            <div>
              <h4>
                <FormattedMessage id={"subscripersTableShow"} />
              </h4>
              <p>
                <FormattedMessage id={"subscripersTableShowAll"} />
              </p>
            </div>
          </div>
        </Col>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={filteredItems}
            progressPending={pending}
            progressComponent={<Loader />}
            defaultSortField="name"
            // //selectableRows
            //subHeader
            // subHeaderComponent={subHeaderComponent}
            //selectableRowsComponent={BootyCheckbox}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
