import server from "./server";

export const getStoresApi = async () => {
  const response = await server().get("/stores");
  return response.data;
};

export const getSingleStoreApi = async (payload) => {
  // const storeId = localStorage.getItem("store");
  const response = await server().get(`/stores/${payload?.storeId}`, {
    headers: {
      deviceType: payload?.deviceType,
    },
  });
  return response.data;
};

export const setActiveThemeApi = async ({ storeId, themeId, deviceType }) => {
  const response = await server().post(
    `/stores/${storeId}/activeTheme/${themeId}`,
    {},
    {
      headers: {
        deviceType,
      },
    }
  );
  return response.data;
};

export const addThemeToStoreApi = async ({ storeId, themeId, deviceType }) => {
  const response = await server().post(
    `/stores/${storeId}/addTheme/${themeId}`,
    {},
    {
      headers: {
        deviceType,
      },
    }
  );
  return response.data;
};

export const addStoreApi = async (payload) => {
  const response = await server().post("/stores", payload);
  return response.data;
};

export const editStoreApi = async ({ data }) => {
  const storeId = localStorage.getItem("store");
  const response = await server().put(`/stores/${storeId}`, data);
  return response.data;
};

export const deleteStoreApi = async (id) => {
  const response = await server().delete(`/stores/${id}`);
  return response.data;
};

export const changestoreStatusApi = async ({ storeId, status }) => {
  const response = await server().post(
    `/stores/changeStatus/${storeId}`,
    status
  );
  return response.data;
};

export const editStoreThemeApi = async ({ themeId, formData }) => {
  const storeId = localStorage.getItem("store");
  const response = await server().put(
    `/stores/${storeId}/editCssTheme/${themeId}`,
    { css: formData }
  );
  return response.data;
};
