import {
  CHANGE_ORDER_STATUS,
  CHANGE_ORDER_STATUS_FAILURE,
  CHANGE_ORDER_STATUS_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_FAILURE,
  GET_SINGLE_ORDER_SUCCESS,
} from "./actionTypes";

export const getOrders = (payload) => {
  return {
    type: GET_ORDERS,
    payload,
  };
};

export const getOrdersSuccess = (orders) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const getOrdersFailure = (error) => {
  return {
    type: GET_ORDERS_FAILURE,
    payload: error,
  };
};

export const getSingleOrder = (id) => {
  return {
    type: GET_SINGLE_ORDER,
    payload: id,
  };
};

export const getSingleOrdersuccess = (order) => {
  return {
    type: GET_SINGLE_ORDER_SUCCESS,
    payload: order,
  };
};

export const getSingleOrderFailure = (error) => {
  return {
    type: GET_SINGLE_ORDER_FAILURE,
    payload: error,
  };
};

// -----------------------------------------------------------

export const changeOrderStatus = (id) => {
  return {
    type: CHANGE_ORDER_STATUS,
    payload: id,
  };
};

export const changeOrderStatusSuccess = (order) => {
  return {
    type: CHANGE_ORDER_STATUS_SUCCESS,
    payload: order,
  };
};

export const changeOrderStatusFailure = (error) => {
  return {
    type: CHANGE_ORDER_STATUS_FAILURE,
    payload: error,
  };
};
