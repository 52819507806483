import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getOrganizationTypesApi,
  addOrganizationTypeApi,
  editOrganizationTypeApi,
  deleteOrganizationTypeApi,
  getSingleOrganizationTypeApi,
} from "api/organizationTypes";

// Login Redux States
import {
  ADD_ORGANIZATION_TYPE,
  DELETE_ORGANIZATION_TYPE,
  EDIT_ORGANIZATION_TYPE,
  GET_SINGLE_ORGANIZATION_TYPE,
  GET_ORGANIZATION_TYPES,
} from "./actionTypes";
import {
  addOrganizationTypeFailure,
  addOrganizationTypeSuccess,
  getOrganizationTypesSuccess,
  getOrganizationTypesFailure,
  editOrganizationTypeSuccess,
  editOrganizationTypeFailure,
  deleteOrganizationTypeSuccess,
  deleteOrganizationTypeFailure,
  getSingleOrganizationTypeSuccess,
  getSingleOrganizationTypeFailure,
} from "./actions";

function* getOrganizationTypes() {
  try {
    const { data } = yield call(getOrganizationTypesApi);
    yield put(getOrganizationTypesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getOrganizationTypesFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleOrganizationType({ payload }) {
  try {
    const { data } = yield call(getSingleOrganizationTypeApi, payload);
    yield put(getSingleOrganizationTypeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleOrganizationTypeFailure(error?.response?.data || error));
  }
}

function* addOrganizationType({ payload }) {
  try {
    const { data } = yield call(addOrganizationTypeApi, payload.data);
    yield put(addOrganizationTypeSuccess(data));
    yield payload.navigate("/organizationTypes");
  } catch (error) {
    console.log(error);
    yield put(
      addOrganizationTypeFailure(error.response?.data?.message || error)
    );
  }
}

function* editOrganizationType({ payload }) {
  try {
    const { data } = yield call(editOrganizationTypeApi, payload);
    yield put(editOrganizationTypeSuccess(data));
    yield payload.navigate("/organizationTypes");
  } catch (error) {
    console.log(error);
    yield put(
      editOrganizationTypeFailure(error.response?.data?.message || error)
    );
  }
}

function* deleteOrganizationType({ payload }) {
  try {
    yield call(deleteOrganizationTypeApi, payload);
    yield put(deleteOrganizationTypeSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteOrganizationTypeFailure(error?.response?.data || error));
  }
}

export function* watchGetOrganizationTypes() {
  yield takeEvery(GET_ORGANIZATION_TYPES, getOrganizationTypes);
}

export function* watchSingleOrganizationType() {
  yield takeEvery(GET_SINGLE_ORGANIZATION_TYPE, getSingleOrganizationType);
}

export function* watchAddOrganizationType() {
  yield takeEvery(ADD_ORGANIZATION_TYPE, addOrganizationType);
}

export function* watchEditOrganizationType() {
  yield takeEvery(EDIT_ORGANIZATION_TYPE, editOrganizationType);
}

export function* watchDeleteOrganizationType() {
  yield takeEvery(DELETE_ORGANIZATION_TYPE, deleteOrganizationType);
}

function* OrganizationTypesSaga() {
  yield all([fork(watchGetOrganizationTypes)]);
  yield all([fork(watchSingleOrganizationType)]);
  yield all([fork(watchAddOrganizationType)]);
  yield all([fork(watchEditOrganizationType)]);
  yield all([fork(watchDeleteOrganizationType)]);
}

export default OrganizationTypesSaga;
