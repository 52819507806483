import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

// Import Api files
import {
  addBrandApi,
  deleteBrandApi,
  editBrandApi,
  getBrandsApi,
  getSingleBrandApi,
} from "api/brands";

// Login Redux States
import {
  ADD_BRAND,
  ADD_EMPTY_BRAND,
  DELETE_BRAND,
  DELETE_EMPTY_BRAND,
  EDIT_BRAND,
  GET_BRANDS,
  GET_SINGLE_BRAND,
} from "./actionTypes";
import {
  addBrandFailure,
  addBrandSuccess,
  addEmptyBrandFailure,
  addEmptyBrandSuccess,
  deleteBrandFailure,
  deleteBrandSuccess,
  deleteEmptyBrandFailure,
  deleteEmptyBrandSuccess,
  editBrandFailure,
  editBrandSuccess,
  getBrandsFailure,
  getBrandsSuccess,
  getSingleBrandFailure,
  getSingleBrandSuccess,
} from "./actions";
import { getId } from "helpers/functions";

function* getBrands() {
  try {
    const { data } = yield call(getBrandsApi);
    yield put(getBrandsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getBrandsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleBrand({ payload }) {
  try {
    const { data } = yield call(getSingleBrandApi, payload);
    yield put(getSingleBrandSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleBrandFailure(error?.response?.data || error));
  }
}

function* addBrand({ payload }) {
  try {
    const { data } = yield call(addBrandApi, payload.data);
    yield put(addBrandSuccess({ brand: data.brand, id: getId(payload) }));
  } catch (error) {
    console.log(error);
    yield put(addBrandFailure(error.response?.data?.message || error));
  }
}

function* editBrand({ payload }) {
  try {
    const { data } = yield call(editBrandApi, payload);
    yield put(editBrandSuccess(data.brand));
  } catch (error) {
    console.log(error);
    yield put(editBrandFailure(error?.response?.data || error));
  }
}

function* deleteBrand({ payload }) {
  try {
    yield call(deleteBrandApi, payload);
    yield put(deleteBrandSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteBrandFailure(error?.response?.data || error));
  }
}

function* addEmptyBrand({ payload }) {
  try {
    yield put(addEmptyBrandSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(addEmptyBrandFailure(error));
  }
}

function* deleteEmptyBrand({ payload }) {
  try {
    yield put(deleteEmptyBrandSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteEmptyBrandFailure(error?.response?.data || error));
  }
}

export function* watchGetBrands() {
  yield takeEvery(GET_BRANDS, getBrands);
}

export function* watchSingleBrand() {
  yield takeEvery(GET_SINGLE_BRAND, getSingleBrand);
}

export function* watchAddBrand() {
  yield takeEvery(ADD_BRAND, addBrand);
}

export function* watchEditBrand() {
  yield takeEvery(EDIT_BRAND, editBrand);
}

export function* watchDeleteBrand() {
  yield takeEvery(DELETE_BRAND, deleteBrand);
}

export function* watchAddEmptyBrand() {
  yield takeLatest(ADD_EMPTY_BRAND, addEmptyBrand);
}

export function* watchDeleteEmptyBrand() {
  yield takeLatest(DELETE_EMPTY_BRAND, deleteEmptyBrand);
}

function* brandsSaga() {
  yield all([fork(watchGetBrands)]);
  yield all([fork(watchSingleBrand)]);
  yield all([fork(watchAddBrand)]);
  yield all([fork(watchEditBrand)]);
  yield all([fork(watchDeleteBrand)]);
  yield all([fork(watchAddEmptyBrand)]);
  yield all([fork(watchDeleteEmptyBrand)]);
}

export default brandsSaga;
