import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILURE,
} from "./actionTypes";

const initialState = {
  languages: [],
  loading: false,
  error: "",
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LANGUAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        languages: action.payload.languages,
      };
      break;
    case GET_LANGUAGES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
