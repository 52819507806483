import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import blogCategoriesIcon from "assets/svgs/blogCategories.svg";
import Table from "./Table";
const Index = () => {
  const { blogCategories } = useSelector((state) => state.blogCategories);

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة تصنيف"
          cardTxt="تصنيفات المقالات"
          icon={blogCategoriesIcon}
          url="/blogs/categories/addcategory"
        />
        <Table data={blogCategories} />
      </div>
    </>
  );
};

export default Index;
