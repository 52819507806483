import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { editProfile } from "store/actions";
import Loader from "components/shared/Loader";
import ImageUpload from "components/shared/ImageUpload";
import { FormattedMessage } from "react-intl";

const Profile = () => {
  const dispatch = useDispatch();

  const {
    authentication: { user, loading },
  } = useSelector((state) => state);

  const [selectedImage, setSelectedImage] = useState({});

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
    });

    setSelectedImage({ path: user?.image });
  }, [reset, user]);

  const onSubmit = (data) => {
    if (selectedImage?.preview !== undefined)
      data.image = selectedImage?.path || "";

    dispatch(editProfile({ data }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"profile"} />
            </h4>
          </div>
          <div className="card-body">
            {/* <Row>
              <ImageUpload
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </Row> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="name" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="phoneNumber" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("phone", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="email" />
                      </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          {...register("email", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col className="col-12"></Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="email" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
