export const GET_SECTIONS = "GET_SECTIONS";
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS";
export const GET_SECTIONS_FAILURE = "GET_SECTIONS_FAILURE";

export const GET_SINGLE_SECTION = "GET_SINGLE_SECTION";
export const GET_SINGLE_SECTION_SUCCESS = "GET_SINGLE_SECTION_SUCCESS";
export const GET_SINGLE_SECTION_FAILURE = "GET_SINGLE_SECTION_FAILURE";

export const ADD_SECTION = "ADD_SECTION";
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS";
export const ADD_SECTION_FAILURE = "ADD_SECTION_FAILURE";

export const EDIT_SECTION = "EDIT_SECTION";
export const EDIT_SECTION_SUCCESS = "EDIT_SECTION_SUCCESS";
export const EDIT_SECTION_FAILURE = "EDIT_SECTION_FAILURE";

export const DELETE_SECTION = "DELETE_SECTION";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_FAILURE = "DELETE_SECTION_FAILURE";
