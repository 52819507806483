import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getPackagesApi,
  addPackageApi,
  editPackageApi,
  deletePackageApi,
  getSinglePackageApi,
} from "api/packages";

// Login Redux States
import {
  ADD_PACKAGE,
  DELETE_PACKAGE,
  EDIT_PACKAGE,
  GET_SINGLE_PACKAGE,
  GET_PACKAGES,
} from "./actionTypes";
import {
  addPackageFailure,
  addPackageSuccess,
  getPackagesSuccess,
  getPackagesFailure,
  editPackageSuccess,
  editPackageFailure,
  deletePackageSuccess,
  deletePackageFailure,
  getSinglePackageSuccess,
  getSinglePackageFailure,
} from "./actions";

function* getPackages() {
  try {
    const { data } = yield call(getPackagesApi);
    yield put(getPackagesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getPackagesFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSinglePackage({ payload }) {
  try {
    const { data } = yield call(getSinglePackageApi, payload);
    yield put(getSinglePackageSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSinglePackageFailure(error?.response?.data || error));
  }
}

function* addPackage({ payload }) {
  try {
    const { data } = yield call(addPackageApi, payload.data);
    yield put(addPackageSuccess(data));
    yield payload.navigate("/packages");
  } catch (error) {
    console.log(error);
    yield put(addPackageFailure(error.response?.data?.message || error));
  }
}

function* editPackage({ payload }) {
  try {
    const { data } = yield call(editPackageApi, payload);
    yield put(editPackageSuccess(data));
    yield payload.navigate("/packages");
  } catch (error) {
    console.log(error);
    yield put(editPackageFailure(error.response?.data?.message || error));
  }
}

function* deletePackage({ payload }) {
  try {
    yield call(deletePackageApi, payload);
    yield put(deletePackageSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deletePackageFailure(error?.response?.data || error));
  }
}

export function* watchGetPackages() {
  yield takeEvery(GET_PACKAGES, getPackages);
}

export function* watchSinglePackage() {
  yield takeEvery(GET_SINGLE_PACKAGE, getSinglePackage);
}

export function* watchAddPackage() {
  yield takeEvery(ADD_PACKAGE, addPackage);
}

export function* watchEditPackage() {
  yield takeEvery(EDIT_PACKAGE, editPackage);
}

export function* watchDeletePackage() {
  yield takeEvery(DELETE_PACKAGE, deletePackage);
}

function* PackagesSaga() {
  yield all([fork(watchGetPackages)]);
  yield all([fork(watchSinglePackage)]);
  yield all([fork(watchAddPackage)]);
  yield all([fork(watchEditPackage)]);
  yield all([fork(watchDeletePackage)]);
}

export default PackagesSaga;
