import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getLanguagesApi } from "api/languages";

// Login Redux States
import { GET_LANGUAGES } from "./actionTypes";
import { getLanguagesFailure, getLanguagesSuccess } from "./actions";

function* getLanguages() {
  try {
    const { data } = yield call(getLanguagesApi);
    yield put(getLanguagesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getLanguagesFailure(error?.response?.data.errors[0].msg));
  }
}

export function* watchGetLanguages() {
  yield takeEvery(GET_LANGUAGES, getLanguages);
}

function* languagesSaga() {
  yield all([fork(watchGetLanguages)]);
}

export default languagesSaga;
