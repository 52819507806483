import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { useState } from "react";
// import SliderArray from "./SliderArray";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { editPage, getPage } from "store/actions";
import { useNavigate } from "react-router-dom";

import SlidersArray from "./SliderArray";

export default function FilterPlaceholder({ slug }) {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, singlePage } = useSelector((state) => state.pages);

  const sections = useMemo(
    () =>
      Object.entries(singlePage?.content || {}).map(([key, value], id) => ({
        ...value,
        id,
      })),
    [singlePage]
  );

  const {
    reset,
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [sliders, setSliders] = useState([]);

  // get single page by slug
  useEffect(() => {
    dispatch(getPage("filters-placeholder"));
  }, [dispatch]);

  useEffect(() => {
    setSliders(sections);
    reset({ ...singlePage, sections });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    let index = 0;
    data.content = data?.sections.reduce((result, current) => {
      // const key = Object.keys(current)[0];
      result[`section${index}`] = current;
      index++;
      return result;
    }, {});

    dispatch(editPage({ slug: singlePage?.slug, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col>
              <Row>
                <Col lg={12}>
                  <SlidersArray
                    slug={slug}
                    register={register}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                  />
                  {/* <SliderArray sliders={sliders} setSliders={setSliders} /> */}
                </Col>
              </Row>

              <SaveChangesButton />
            </Col>
          </form>
        </div>
      </div>
    </>
  );
}
