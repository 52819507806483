import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getControlReferences, getSubControlDomains } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const ControlReferences = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { controlReferences } = useSelector((state) => state.controlReferences);
  const { subControlDomains } = useSelector((state) => state.subControlDomains);

  useEffect(() => {
    dispatch(getControlReferences());

    if (subControlDomains?.length === 0) dispatch(getSubControlDomains());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="controlReferences-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addControlReference" })}
          cardTxt={formatMessage({ id: "controlReferences" })}
          icon={peopleIcon}
          url="/controlReferences/add"
        />
        <Table data={controlReferences} subControlDomains={subControlDomains} />
      </div>
    </>
  );
};

export default ControlReferences;
