import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addAdminApi,
  deleteAdminApi,
  editAdminApi,
  getAdminsApi,
  getSingleAdminApi,
} from "api/admins";

// Login Redux States
import {
  ADD_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
  GET_ADMINS,
  GET_SINGLE_ADMIN,
} from "./actionTypes";

import {
  addAdminFailure,
  addAdminSuccess,
  deleteAdminFailure,
  deleteAdminSuccess,
  editAdminFailure,
  editAdminSuccess,
  getAdminsFailure,
  getAdminsSuccess,
  getSingleAdminFailure,
  getSingleAdminSuccess,
} from "./actions";

function* getAdmins() {
  try {
    const { data } = yield call(getAdminsApi);
    yield put(getAdminsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAdminsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleAdmin({ payload }) {
  try {
    const { data } = yield call(getSingleAdminApi, payload);
    yield put(getSingleAdminSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleAdminFailure(error.response.data || error));
  }
}

function* addAdmin({ payload }) {
  try {
    const { data } = yield call(addAdminApi, payload.data);
    yield put(addAdminSuccess(data));
    yield payload.navigate("/admins");
  } catch (error) {
    console.log(error);
    yield put(addAdminFailure(error.response?.data || error));
  }
}

function* editAdmin({ payload }) {
  try {
    yield call(editAdminApi, payload);
    yield put(editAdminSuccess(payload));
    yield payload.navigate("/admins");
  } catch (error) {
    console.log(error);
    yield put(editAdminFailure(error.response.data || error));
  }
}

function* deleteAdmin({ payload }) {
  try {
    yield call(deleteAdminApi, payload);
    yield put(deleteAdminSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteAdminFailure(error.response.data || error));
  }
}

export function* watchGetAdmins() {
  yield takeEvery(GET_ADMINS, getAdmins);
}

export function* watchSingleAdmin() {
  yield takeEvery(GET_SINGLE_ADMIN, getSingleAdmin);
}

export function* watchAddAdmin() {
  yield takeEvery(ADD_ADMIN, addAdmin);
}

export function* watchEditAdmin() {
  yield takeEvery(EDIT_ADMIN, editAdmin);
}

export function* watchDeleteAdmin() {
  yield takeEvery(DELETE_ADMIN, deleteAdmin);
}

function* citiesSaga() {
  yield all([fork(watchGetAdmins)]);
  yield all([fork(watchSingleAdmin)]);
  yield all([fork(watchAddAdmin)]);
  yield all([fork(watchEditAdmin)]);
  yield all([fork(watchDeleteAdmin)]);
}

export default citiesSaga;
