import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getControlDomainsApi,
  addControlDomainApi,
  editControlDomainApi,
  deleteControlDomainApi,
  getSingleControlDomainApi,
} from "api/controlDomains";

// Login Redux States
import {
  ADD_CONTROL_DOMAIN,
  DELETE_CONTROL_DOMAIN,
  EDIT_CONTROL_DOMAIN,
  GET_SINGLE_CONTROL_DOMAIN,
  GET_CONTROL_DOMAINS,
} from "./actionTypes";

import {
  addControlDomainFailure,
  addControlDomainSuccess,
  getControlDomainsSuccess,
  getControlDomainsFailure,
  editControlDomainSuccess,
  editControlDomainFailure,
  deleteControlDomainSuccess,
  deleteControlDomainFailure,
  getSingleControlDomainSuccess,
  getSingleControlDomainFailure,
} from "./actions";

function* getControlDomains() {
  try {
    const { data } = yield call(getControlDomainsApi);
    yield put(getControlDomainsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getControlDomainsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleControlDomain({ payload }) {
  try {
    const { data } = yield call(getSingleControlDomainApi, payload);
    yield put(getSingleControlDomainSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleControlDomainFailure(error?.response?.data || error));
  }
}

function* addControlDomain({ payload }) {
  try {
    const { data } = yield call(addControlDomainApi, payload.data);
    yield put(addControlDomainSuccess(data));
    yield payload.navigate("/controlDomains");
  } catch (error) {
    console.log(error);
    yield put(addControlDomainFailure(error.response?.data?.message || error));
  }
}

function* editControlDomain({ payload }) {
  try {
    const { data } = yield call(editControlDomainApi, payload);
    yield put(editControlDomainSuccess(data));
    yield payload.navigate("/controlDomains");
  } catch (error) {
    console.log(error);
    yield put(editControlDomainFailure(error.response?.data?.message || error));
  }
}

function* deleteControlDomain({ payload }) {
  try {
    yield call(deleteControlDomainApi, payload);
    yield put(deleteControlDomainSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteControlDomainFailure(error?.response?.data || error));
  }
}

export function* watchGetControlDomains() {
  yield takeEvery(GET_CONTROL_DOMAINS, getControlDomains);
}

export function* watchSingleControlDomain() {
  yield takeEvery(GET_SINGLE_CONTROL_DOMAIN, getSingleControlDomain);
}

export function* watchAddControlDomain() {
  yield takeEvery(ADD_CONTROL_DOMAIN, addControlDomain);
}

export function* watchEditControlDomain() {
  yield takeEvery(EDIT_CONTROL_DOMAIN, editControlDomain);
}

export function* watchDeleteControlDomain() {
  yield takeEvery(DELETE_CONTROL_DOMAIN, deleteControlDomain);
}

function* ControlDomainsSaga() {
  yield all([fork(watchGetControlDomains)]);
  yield all([fork(watchSingleControlDomain)]);
  yield all([fork(watchAddControlDomain)]);
  yield all([fork(watchEditControlDomain)]);
  yield all([fork(watchDeleteControlDomain)]);
}

export default ControlDomainsSaga;
