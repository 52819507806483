import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkPermissions } from "./checkPermissions";
import { sidebarData } from "data";

const AuthRoute = ({ path, children }) => {
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const permissions = sidebarData?.find((e) => e?.path === path)?.permissions;

  return isLoggedIn && checkPermissions(permissions || []) ? (
    children
  ) : (
    <Navigate to={isLoggedIn ? "/" : "/login"} />
  );
};

export default AuthRoute;
