const { default: server } = require("./server");

export const getSettingsApi = async (slug) => {
  const response = await server().get(`/settings/${slug}`);
  return response.data;
};

export const editSettingsApi = async (data) => {
  const response = await server().put(`/settings/siteInfo`, data);

  return response.data;
};

export const editStoreBasicSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/settings/basicSettings/${slug}`, data);

  return response.data;
};

export const editStoreSlugSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/settings/${slug}`, data);

  return response.data;
};

export const editStoreLangsApi = async ({ data }) => {
  const response = await server().put(`/settings/languageSettings`, data);

  return response.data;
};

export const editStoreCurrenciesApi = async ({ data }) => {
  const response = await server().put(`/settings/currencySettings`, data);

  return response.data;
};

export const addBankAccountApi = async () => {
  const response = await server().post(`/bankAccounts`);

  return response.data;
};

export const editBankAccountApi = async ({ data, id }) => {
  const response = await server().put(`/bankAccounts/${id}`, data);

  return response.data;
};

// -------------------------------------------------------

export const getAllSettingsApi = async () => {
  const response = await server().get("/settings");

  return response.data;
};

export const editMainSettingsApi = async (data) => {
  const response = await server().put("/settings/main", data);

  return response.data;
};

export const addAccountsSettingsApi = async (data) => {
  const response = await server().post("/settings/", data);

  return response.data;
};

export const editAccountsSettingsApi = async (data) => {
  const response = await server().put("/settings/bankAccounts", data);

  return response.data;
};

export const deleteSettingsApi = async ({ id }) => {
  const response = await server().delete(`/settings/${id}`);

  return response.data;
};

export const editSocialsSettingsApi = async (data) => {
  const response = await server().put("/settings/social", data);

  return response.data;
};
export const editMailSettingsApi = async (data) => {
  const response = await server().put("/settings/EmailContent", data);

  return response.data;
};
