import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAILURE,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  GET_SINGLE_ADMIN,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_FAILURE,
} from "./actionTypes";

export const getAdmins = () => {
  return {
    type: GET_ADMINS,
  };
};

export const getAdminsSuccess = (city) => {
  return {
    type: GET_ADMINS_SUCCESS,
    payload: city,
  };
};

export const getAdminsFailure = (error) => {
  return {
    type: GET_ADMINS_FAILURE,
    payload: error,
  };
};

export const getSingleAdmin = (id) => {
  return {
    type: GET_SINGLE_ADMIN,
    payload: id,
  };
};

export const getSingleAdminSuccess = (city) => {
  return {
    type: GET_SINGLE_ADMIN_SUCCESS,
    payload: city,
  };
};

export const getSingleAdminFailure = (error) => {
  return {
    type: GET_SINGLE_ADMIN_FAILURE,
    payload: error,
  };
};

export const addAdmin = (payload) => {
  return {
    type: ADD_ADMIN,
    payload,
  };
};

export const addAdminSuccess = (payload) => {
  return {
    type: ADD_ADMIN_SUCCESS,
    payload,
  };
};

export const addAdminFailure = (error) => {
  return {
    type: ADD_ADMIN_FAILURE,
    payload: error,
  };
};

export const editAdmin = (payload) => {
  return {
    type: EDIT_ADMIN,
    payload,
  };
};

export const editAdminSuccess = (payload) => {
  return {
    type: EDIT_ADMIN_SUCCESS,
    payload,
  };
};

export const editAdminFailure = (error) => {
  return {
    type: EDIT_ADMIN_FAILURE,
    payload: error,
  };
};

export const deleteAdmin = (id) => {
  return {
    type: DELETE_ADMIN,
    payload: id,
  };
};

export const deleteAdminSuccess = (id) => {
  return {
    type: DELETE_ADMIN_SUCCESS,
    payload: id,
  };
};

export const deleteAdminFailure = (error) => {
  return {
    type: DELETE_ADMIN_FAILURE,
    payload: error,
  };
};
