import { getId } from "helpers/functions";
import {
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATION_TYPES_FAILURE,
  GET_ORGANIZATION_TYPES_SUCCESS,
  ADD_ORGANIZATION_TYPE,
  ADD_ORGANIZATION_TYPE_SUCCESS,
  ADD_ORGANIZATION_TYPE_FAILURE,
  DELETE_ORGANIZATION_TYPE,
  DELETE_ORGANIZATION_TYPE_SUCCESS,
  DELETE_ORGANIZATION_TYPE_FAILURE,
  GET_SINGLE_ORGANIZATION_TYPE,
  GET_SINGLE_ORGANIZATION_TYPE_FAILURE,
  GET_SINGLE_ORGANIZATION_TYPE_SUCCESS,
  SET_DEFAULT_ORGANIZATION_TYPE_SUCCESS,
} from "./actionTypes";

const initialState = {
  organizationTypes: [],
  loading: false,
  error: "",
  singleOrganizationType: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ORGANIZATION_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        organizationTypes: action.payload.organizationTypes,
      };
      break;
    case GET_ORGANIZATION_TYPES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_ORGANIZATION_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ORGANIZATION_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleOrganizationType: action.payload.organizationType,
      };
      break;
    case GET_SINGLE_ORGANIZATION_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_ORGANIZATION_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ORGANIZATION_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        organizationTypes: action.payload.organizationTypes,
      };
      break;
    case ADD_ORGANIZATION_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_ORGANIZATION_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ORGANIZATION_TYPE_SUCCESS:
      const usersAfterDeleteing = [
        ...state?.organizationTypes?.filter(
          (organizationType) => getId(organizationType) !== action.payload
        ),
      ];

      state = {
        ...state,
        loading: false,
        organizationTypes: usersAfterDeleteing,
      };
      break;
    case DELETE_ORGANIZATION_TYPE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case SET_DEFAULT_ORGANIZATION_TYPE_SUCCESS:
      const defaultItems = [
        ...(state?.organizationTypes || [])?.map((item) =>
          getId(item) === action.payload?.id
            ? { ...item, isDefault: true }
            : { ...item, isDefault: false }
        ),
      ];

      state = {
        ...state,
        organizationTypes: defaultItems,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
