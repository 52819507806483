import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import citiesIcon from "assets/svgs/Setting.svg";
import Table from "./Table";
import { useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { getAdmins } from "store/actions";
import { useEffect } from "react";

const Admins = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { admins, loading } = useSelector((state) => state.admins);

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addAdmin" })}
          cardTxt={formatMessage({ id: "allAdmins" })}
          icon={citiesIcon}
          url="/admins/addAdmin"
        />
        <Table data={admins} />
      </div>
    </>
  );
};

export default Admins;
