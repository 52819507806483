import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { editOrganizationType, getSingleOrganizationType } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { FormattedMessage } from "react-intl";

const EditOrganizationType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singleOrganizationType, loading } = useSelector(
    (state) => state?.organizationTypes
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getSingleOrganizationType(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singleOrganizationType?.title,
    });
  }, [reset, singleOrganizationType]);

  const onSubmit = (data) => {
    dispatch(editOrganizationType({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editOrganizationType" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="save" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOrganizationType;
