import server from "./server";

export const getControlDomainsApi = async () => {
  const response = await server().get("/controlDomains");
  return response.data;
};

export const getSingleControlDomainApi = async (id) => {
  const response = await server().get(`/controlDomains/${id}`);
  return response.data;
};

export const addControlDomainApi = async (payload) => {
  const response = await server().post("/controlDomains", payload);
  return response.data;
};

export const editControlDomainApi = async ({ id, data }) => {
  const response = await server().put(`/controlDomains/${id}`, data);
  return response.data;
};

export const deleteControlDomainApi = async (id) => {
  const response = await server().delete(`/controlDomains/${id}`);
  return response.data;
};
