export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_FAILURE = "GET_SINGLE_PRODUCT_FAILURE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE";

//delete a product
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
//delete products
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";
export const DELETE_PRODUCTS_FAILURE = "DELETE_PRODUCTS_FAILURE";

export const UPDATE_PRODUCT_MAIN_DATA = "UPDATE_PRODUCT_MAIN_DATA";
export const UPDATE_PRODUCT_MAIN_DATA_SUCCESS =
  "UPDATE_PRODUCT_MAIN_DATA_SUCCESS";
export const UPDATE_PRODUCT_MAIN_DATA_FAILURE =
  "UPDATE_PRODUCT_MAIN_DATA_FAILURE";

export const ADD_FAKE_PRODUCT = "ADD_FAKE_PRODUCT";
export const ADD_FAKE_PRODUCT_SUCCESS = "ADD_FAKE_PRODUCT_SUCCESS";
export const ADD_FAKE_PRODUCT_FAILURE = "ADD_FAKE_PRODUCT_FAILURE";

export const DELETE_EMPTY_PRODUCT = "DELETE_EMPTY_PRODUCT";
export const DELETE_EMPTY_PRODUCT_SUCCESS = "DELETE_EMPTY_PRODUCT_SUCCESS";
export const DELETE_EMPTY_PRODUCT_FAILURE = "DELETE_EMPTY_PRODUCT_FAILURE";

export const GET_VARIATIONS = "GET_VARIATIONS";
export const GET_VARIATIONS_SUCCESS = "GET_VARIATIONS_SUCCESS";
export const GET_VARIATIONS_FAILURE = "GET_VARIATIONS_FAILURE";

export const GET_VARIATIONS_STYLES = "GET_VARIATIONS_STYLES";
export const GET_VARIATIONS_STYLES_SUCCESS = "GET_VARIATIONS_STYLES_SUCCESS";
export const GET_VARIATIONS_STYLES_FAILURE = "GET_VARIATIONS_STYLES_FAILURE";
