import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { editRole, getPermissions, getSingleRole } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { getId } from "helpers/functions";
import { toast } from "react-toastify";

const EditRole = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { permissions, loading } = useSelector((state) => state?.permissions);
  const { singleRole } = useSelector((state) => state?.roles);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "permissions",
  });

  useEffect(() => {
    dispatch(getSingleRole(id));
    dispatch(getPermissions());
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      ...singleRole,
      permissions: singleRole?.permissions?.map((p) => p?.slug),
    });
    return () => reset({});
  }, [reset, singleRole, permissions]);

  const onSubmit = (data) => {
    if (data?.permissions?.filter((item) => !!item)?.length === 0)
      toast.error("يجب تحديد الصلاحيات");

    dispatch(editRole({ id, data, navigate }));
  };

  // const unselectedPermissions = permissions
  //   ?.map((p) => p?.name)
  //   ?.filter((e) => !singleRole?.permissions?.includes(e));

  const handleSelectAll = () => {
    const checkboxes = document.querySelectorAll(".permission-checkbox");

    if (getValues("permissions").length === permissions.length) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      return setValue("permissions", []);
    }
    setValue(
      "permissions",
      permissions?.map((p) => p?.slug)
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  };

  const renderPermissions = useMemo(
    () =>
      permissions
        // ?.map((p) => p?.slug)
        ?.sort((a, b) => a?.slug?.localeCompare(b?.slug))
        ?.map((item, index) => {
          return (
            <Col xxl="4" lg={6} xs={12} key={index}>
              <label className="form-group permission">
                <input
                  key={watch("permissions")?.length + "" + index}
                  type="checkbox"
                  className="form-control form-outline permission-checkbox"
                  placeholder={formatMessage({
                    id: "permission",
                  })}
                  {...register(`permissions[]`)}
                  value={item?.slug}
                  defaultChecked={
                    // singleRole?.permissions?.includes(item) ||
                    (watch("permissions") || [])?.includes(item?.slug)
                  }
                />
                <h5 className="mx-2 d-inline-block mb-0">
                  {item?.name}
                  {/* <br />
                  <span className="small" style={{ color: "inherit" }}>
                    {item?.slug}
                  </span> */}
                </h5>
              </label>
            </Col>
          );
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, permissions, register, watch("permissions")]
  );

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editRole" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="name" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col xs="12">
                    <label
                      className="btn btn-info mb-3"
                      onClick={handleSelectAll}
                    >
                      <FormattedMessage id="selectAll" />
                    </label>
                    <div className="form-group branches features">
                      {/* <h5>
                        <FormattedMessage id={"statistics"} />
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            append({});
                          }}
                        >
                          <FormattedMessage id={"addstatistic"} />
                        </button>
                      </h5> */}
                      <Row>{renderPermissions}</Row>
                    </div>
                  </Col>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="save" />
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
