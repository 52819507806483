import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_FAILURE,
} from "./actionTypes";

const initialState = {
  orders: [],
  loading: false,
  error: "",
  singleOrder: {},
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        orders: action.payload.orders,
        metadata: action.payload.metadata,
      };
      break;
    case GET_ORDERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleOrder: action.payload.order,
      };
      break;
    case GET_SINGLE_ORDER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleOrder: {},
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
