import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editUser, getSingleUser } from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import { FormattedMessage } from "react-intl";

const EditUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singleUser, loading } = useSelector((state) => state?.users);
  const { register, handleSubmit, control, reset } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  useEffect(() => {
    dispatch(getSingleUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      firstName: singleUser?.firstName,
      lastName: singleUser?.lastName,
      email: singleUser?.email,
      // password: singleUser?.password,
      phone: singleUser?.phone,
      // addresses: singleUser?.addresses,
    });
  }, [reset, singleUser]);

  const onSubmit = (data) => {
    dispatch(editUser({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editUser" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="firstName" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("firstName", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="lastName" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("lastName", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="phone" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("phone", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="email" />
                      </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          {...register("email", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="save" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
