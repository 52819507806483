import { getId } from "helpers/functions";
import {
  ACTIVATE_COUPON,
  ACTIVATE_COUPON_FAILURE,
  ACTIVATE_COUPON_SUCCESS,
  ADD_COUPON,
  ADD_COUPON_FAILURE,
  ADD_COUPON_SUCCESS,
  DELETE_COUPON,
  DELETE_COUPON_FAILURE,
  DELETE_COUPON_SUCCESS,
  EDIT_COUPON,
  EDIT_COUPON_FAILURE,
  EDIT_COUPON_SUCCESS,
  GET_COUPONS,
  GET_COUPONS_FAILURE,
  GET_COUPONS_SUCCESS,
  GET_SINGLE_COUPON,
  GET_SINGLE_COUPON_FAILURE,
  GET_SINGLE_COUPON_SUCCESS,
} from "./actionTypes";

const initialState = {
  coupons: [],
  loading: false,
  error: "",
  singleCoupon: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COUPONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        coupons: action.payload.coupons,
      };
      break;
    case GET_COUPONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_COUPON_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCoupon: action.payload.coupon,
      };
      break;
    case GET_SINGLE_COUPON_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleCoupon: {},
      };
      break;

    case ADD_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_COUPON_SUCCESS:
      state = {
        ...state,
        loading: false,
        coupons: [...state.coupons, action.payload.coupon],
      };
      break;
    case ADD_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_COUPON_SUCCESS:
      state = {
        ...state,
        loading: false,
        coupons: state.coupons.map((coupon) => {
          if (getId(coupon) !== getId(action.payload.coupon)) return coupon;
          return action.payload.coupon;
        }),
      };
      break;
    case EDIT_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_COUPON_SUCCESS:
      const couponsAfterDeleteing = [
        ...state?.coupons?.filter((coupon) => getId(coupon) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        coupons: couponsAfterDeleteing,
      };
      break;
    case DELETE_COUPON_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case ACTIVATE_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ACTIVATE_COUPON_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCoupon: action.payload.coupon,
      };
      break;

    case ACTIVATE_COUPON_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
