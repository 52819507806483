import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Editor } from "components/shared/Editor";
import {
  addBlog,
  getBlogCategories,
  getLanguages,
  getTags,
} from "store/actions";
import { useNavigate } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import CreatableSelect from "react-select/creatable";
import { getId } from "helpers/functions";

const AddBlog = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const dir = locale === "ar" ? "rtl" : "ltr";

  const { blogCategories } = useSelector((state) => state.blogCategories);
  const { languages } = useSelector((state) => state.languages);

  const { blogTags } = useSelector((state) => state.blogs) || {};

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const renderCategoriesSelectOptions = () => {
    return blogCategories.map((category, index) => {
      return (
        <option value={getId(category)} key={index}>
          {category?.name?.[locale]}
        </option>
      );
    });
  };

  const renderLanguagesSelectOptions = () => {
    return languages.map((language, index) => {
      return (
        <option value={getId(language)} key={index}>
          {language?.name}
        </option>
      );
    });
  };

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getLanguages());
    dispatch(getTags());
  }, [dispatch]);

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    data.image = "uploads/" + data?.image?.split("/").pop();
    data.description = description;
    data.tags = tags;

    dispatch(addBlog({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف مقال جديد</h4>
          </div>
          <div className="card-body form-group settings-tabs">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Tabs
                defaultActiveKey="mainInfo"
                id="home_page_tabs"
                className="home_page_tabs__"
              >
                <Tab
                  eventKey="mainInfo"
                  title={formatMessage({ id: "mainInfo" })}
                >
                  <Col xl={10} md={12}>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group required">
                          <h5>صورة المقال</h5>
                          <div className="image-uploader image-contain">
                            <div className="img-pat">
                              <img
                                src={imageUpload}
                                width={46}
                                height={46}
                                alt=""
                              />
                            </div>
                            <label>
                              {selectedImage && (
                                <div>
                                  <img
                                    src={
                                      selectedImage &&
                                      selectedImage.preview &&
                                      URL.createObjectURL(selectedImage.preview)
                                    }
                                    width={160}
                                    height={160}
                                    alt=""
                                  />
                                  <button
                                    className="removeImg"
                                    onClick={removeSelectedImage}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      className="bi bi-x-lg"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={imageChange}
                              />
                            </label>

                            <p className="error-hint">
                              {errors.mainImage?.type === "required" &&
                                "يرجي اختيار صورة المتجر"}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>التصنيف</h5>
                          <div>
                            <select
                              className="form-control"
                              {...register("category", { required: true })}
                            >
                              <option>اختر التصنيف</option>
                              {renderCategoriesSelectOptions()}
                            </select>
                          </div>
                          <p className="error-hint">
                            {errors.category?.type === "required" &&
                              "يرجي اختيار التصنيف"}
                          </p>
                        </div>
                      </Col>

                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>لغة المقال</h5>
                          <div>
                            <select
                              className="form-control"
                              {...register("language", { required: true })}
                            >
                              <option>اختر لغة المقال</option>
                              {renderLanguagesSelectOptions()}
                            </select>
                          </div>
                          <p className="error-hint">
                            {errors.language?.type === "required" &&
                              "يرجي اختيار لغة المقال"}
                          </p>
                        </div>
                      </Col>

                      <Col lg={12} xs={12}>
                        <div className="form-group required">
                          <h5>عنوان المقال</h5>
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder="عنوان المقال"
                              {...register("title", {
                                required: true,
                              })}
                            />
                          </div>
                          <p className="error-hint">
                            {errors.title?.type === "required" &&
                              "يرجي ادخال عنوان المقال"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={12} xs={12}>
                        <div className="form-group required">
                          <h5>وصف المقال</h5>
                          <div>
                            <Controller
                              control={control}
                              name="description"
                              render={({ field: { value } }) => (
                                <Editor
                                  initialValue=""
                                  onChange={(e, editor) => {
                                    const data = editor.getContent();
                                    setDescription(data);
                                  }}
                                  tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    menubar:
                                      "file edit view insert format tools table help",
                                    toolbar:
                                      "customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link table | ltr rtl | language",
                                    toolbar_sticky: true,
                                    autosave_ask_before_unload: true,
                                    autosave_interval: "30s",
                                    autosave_prefix: "{path}{query}-{id}-",
                                    autosave_restore_when_empty: false,
                                    autosave_retention: "2m",
                                    mobile: {
                                      menubar: true,
                                    },
                                    directionality: `${dir}`,
                                    language: `${locale}`,
                                    height: 400,
                                    image_caption: true,
                                    quickbars_selection_toolbar:
                                      "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                    noneditable_noneditable_class:
                                      "mceNonEditable",
                                    toolbar_mode: "wrap",
                                    contextmenu: "link image imagetools table",
                                    content_style: "body {font-size:14px}",
                                    setup: function (editor) {
                                      editor.ui.registry.addButton(
                                        "customInsertButton",
                                        {
                                          text: "custom Button",
                                          onAction: function (_) {
                                            editor.insertContent(
                                              `&nbsp;<a href="" class="btn" style="    background: linear-gradient(
																148deg,#1d5cd1,#0ba1d8 84%);
															color: #fff;
															padding: 10px 20px;
															border-radius: 50px;
															display: inline-block;
															cursor: pointer;
															text-decoration: none;">my button!</a>&nbsp;`
                                            );
                                          },
                                        }
                                      );
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="form-group">
                          <h5>
                            <FormattedMessage id="keywords" />
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              name="tags"
                              render={({
                                field: { onChange, value, selected },
                              }) => (
                                <CreatableSelect
                                  isMulti
                                  isRtl={true}
                                  className="basic-single creatable-select"
                                  classNamePrefix="select"
                                  placeholder="اضف كلمات دلالية"
                                  onChange={(selected) => {
                                    const newTags = selected.map(
                                      (tag) => tag.label
                                    );

                                    setTags(newTags);
                                  }}
                                  options={blogTags.map((tag) => ({
                                    value: tag,
                                    label: tag,
                                  }))}
                                />
                              )}
                            />
                          </div>
                          <p className="error-hint">
                            {errors.tags?.type === "required" &&
                              "يرجي ادخال الكلمات الدلالية"}
                          </p>
                        </div>
                      </Col>
                      {/* <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة المقال
                      </button>
                    </div>
                  </Col> */}
                    </Row>
                  </Col>
                </Tab>
                <Tab
                  eventKey="seoInfo"
                  title={formatMessage({ id: "seoInfo" })}
                >
                  {" "}
                  <div className="form-body">
                    <Row className="gap-3">
                      <Col xs={12}>
                        <h5 htmlFor="">
                          <FormattedMessage id="title" />{" "}
                        </h5>
                        <div>
                          <input
                            type="text"
                            id=""
                            className="form-control"
                            placeholder="عنوان المتجر"
                            {...register("seo.pageTitle")}
                          />
                        </div>
                      </Col>

                      <Col xs={12}>
                        <h5 htmlFor="">الوصف</h5>
                        <div>
                          <input
                            type="text"
                            id=""
                            className="form-control"
                            placeholder="الوصف"
                            {...register("seo.pageDescription")}
                          />
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12}>
                        <div className="seoPreview">
                          <h5>{watch("seo.pageTitle")}</h5>
                          {/* <a
                            href={`https://nsn.sa/blogs/${singleBlog.slug}`}
                            target="_blank"
                            rel="noreferrer"
                            dir="ltr"
                          >
                            https://nsn.sa/blogs/
                            {watch(`name.${locale}`)}
                          </a> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: watch("seo.pageDescription")?.slice(
                                0,
                                160
                              ),
                            }}
                          ></p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
              <Col lg={4} xs={12}>
                <div className="form-group mt-3">
                  <button type="submit" className="btn btn-blue">
                    <FormattedMessage id="save" />
                  </button>
                </div>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
