import { getId } from "helpers/functions";
import {
  GET_CONTROL_DOMAINS,
  GET_CONTROL_DOMAINS_FAILURE,
  GET_CONTROL_DOMAINS_SUCCESS,
  ADD_CONTROL_DOMAIN,
  ADD_CONTROL_DOMAIN_SUCCESS,
  ADD_CONTROL_DOMAIN_FAILURE,
  DELETE_CONTROL_DOMAIN,
  DELETE_CONTROL_DOMAIN_SUCCESS,
  DELETE_CONTROL_DOMAIN_FAILURE,
  GET_SINGLE_CONTROL_DOMAIN,
  GET_SINGLE_CONTROL_DOMAIN_FAILURE,
  GET_SINGLE_CONTROL_DOMAIN_SUCCESS,
  SET_DEFAULT_CONTROL_DOMAIN_SUCCESS,
} from "./actionTypes";

const initialState = {
  controlDomains: [],
  loading: false,
  error: "",
  singleControlDomain: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTROL_DOMAINS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CONTROL_DOMAINS_SUCCESS:
      state = {
        ...state,
        loading: false,
        controlDomains: action.payload.controlDomains,
      };
      break;
    case GET_CONTROL_DOMAINS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_CONTROL_DOMAIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_CONTROL_DOMAIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleControlDomain: action.payload.controlDomain,
      };
      break;
    case GET_SINGLE_CONTROL_DOMAIN_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_CONTROL_DOMAIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CONTROL_DOMAIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        controlDomains: action.payload.controlDomains,
      };
      break;
    case ADD_CONTROL_DOMAIN_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_CONTROL_DOMAIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CONTROL_DOMAIN_SUCCESS:
      const usersAfterDeleteing = [
        ...state?.controlDomains?.filter(
          (controlDomain) => getId(controlDomain) !== action.payload
        ),
      ];

      state = {
        ...state,
        loading: false,
        controlDomains: usersAfterDeleteing,
      };
      break;
    case DELETE_CONTROL_DOMAIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case SET_DEFAULT_CONTROL_DOMAIN_SUCCESS:
      const defaultItems = [
        ...(state?.controlDomains || [])?.map((item) =>
          getId(item) === action.payload?.id
            ? { ...item, isDefault: true }
            : { ...item, isDefault: false }
        ),
      ];

      state = {
        ...state,
        controlDomains: defaultItems,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
