import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  EDIT_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD_SUCCESS,
  EDIT_PAYMENT_METHOD_FAILURE,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  GET_SINGLE_PAYMENT_METHOD,
  GET_SINGLE_PAYMENT_METHOD_SUCCESS,
  GET_SINGLE_PAYMENT_METHOD_FAILURE,
} from "./actionTypes";

const initialState = {
  paymentMethods: [],
  loading: false,
  error: "",
  singlepaymentMethod: {},
};

const features = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PAYMENT_METHODS_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods,
      };
      break;
    case GET_PAYMENT_METHODS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PAYMENT_METHOD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PAYMENT_METHOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlepaymentMethod: action.payload.paymentMethod,
      };

      break;
    case GET_SINGLE_PAYMENT_METHOD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singlepaymentMethod: {},
      };
      break;

    case ADD_PAYMENT_METHOD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PAYMENT_METHOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods,
      };
      break;
    case ADD_PAYMENT_METHOD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // ==========================================
    case EDIT_PAYMENT_METHOD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_PAYMENT_METHOD_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case EDIT_PAYMENT_METHOD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // ==============================================
    case DELETE_PAYMENT_METHOD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PAYMENT_METHOD_SUCCESS:
      const paymentMethodsAfterDeleteing = [
        ...state?.paymentMethods?.filter(
          (paymentMethod) => paymentMethod?.id !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        paymentMethods: paymentMethodsAfterDeleteing,
      };
      break;
    case DELETE_PAYMENT_METHOD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default features;
