import { getId } from "helpers/functions";
import {
  GET_QUESTIONS,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_SUCCESS,
  ADD_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAILURE,
  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  GET_SINGLE_QUESTION,
  GET_SINGLE_QUESTION_FAILURE,
  GET_SINGLE_QUESTION_SUCCESS,
  APPROVE_QUESTION_COMMENT,
  APPROVE_QUESTION_COMMENT_SUCCESS,
  APPROVE_QUESTION_COMMENT_FAILURE,
  REJECT_QUESTION_COMMENT,
  REJECT_QUESTION_COMMENT_SUCCESS,
  REJECT_QUESTION_COMMENT_FAILURE,
} from "./actionTypes";

const initialState = {
  questions: [],
  loading: false,
  error: "",
  singleQuestion: {},
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_QUESTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        questions: action.payload.questions,
        metadata: action.payload.metadata,
      };
      break;
    case GET_QUESTIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_QUESTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_QUESTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleQuestion: action.payload.question,
      };
      break;
    case GET_SINGLE_QUESTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_QUESTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_QUESTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        questions: action.payload.questions,
      };
      break;
    case ADD_QUESTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case APPROVE_QUESTION_COMMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case APPROVE_QUESTION_COMMENT_SUCCESS:
      const approvedQuestionComment = {
        ...state?.singleQuestion,
        sections: state?.singleQuestion?.sections?.map((item) =>
          getId(item) === getId(action.payload?.section)
            ? action.payload?.section
            : item
        ),
      };
      state = {
        ...state,
        loading: false,
        singleQuestion: approvedQuestionComment,
      };
      break;
    case APPROVE_QUESTION_COMMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case REJECT_QUESTION_COMMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REJECT_QUESTION_COMMENT_SUCCESS:
      const question = {
        ...state?.singleQuestion,
        sections: state?.singleQuestion?.sections?.map((item) => {
          if (getId(item) === action.payload?.sectionId) {
            return {
              ...item,
              comments: item?.comments?.filter(
                (comment) => getId(comment) !== action.payload?.commentId
              ),
            };
          }
          return item;
        }),
      };
      state = {
        ...state,
        loading: false,
        singleQuestion: question,
      };
      break;
    case REJECT_QUESTION_COMMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_QUESTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_QUESTION_SUCCESS:
      const usersAfterDeleteing = [
        ...state?.questions?.filter(
          (question) => getId(question) !== action.payload
        ),
      ];

      state = {
        ...state,
        loading: false,
        questions: usersAfterDeleteing,
      };
      break;
    case DELETE_QUESTION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
