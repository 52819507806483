import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Modal, Row } from "react-bootstrap";

import { editSection, getSingleQuestion } from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { Editor } from "components/shared/Editor";
import { ReactComponent as EyeIcon } from "assets/svgs/Setting.svg";
const EditSection = ({ singleSection, handleShowSection = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const { sectionId: id, id: questionId } = useParams();

  const { loading } = useSelector((state) => state?.sections);
  // const { singleQuestion } = useSelector((state) => state?.questions);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  // const singleSection = useMemo(
  //   () => singleQuestion?.sections?.find((section) => getId(section) === id),
  //   [singleQuestion, id]
  // );
  // useEffect(() => {
  //   dispatch(getSingleSection(id));
  // }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singleSection?.title,
      description: singleSection?.description,
    });
    return () => reset({});
  }, [reset, singleSection]);

  const onSubmit = (data) => {
    dispatch(
      editSection({
        id: getId(singleSection),
        data,
        callBack: () => {
          // dispatch(getSingleQuestion(questionId));
          // navigate(-1);
        },
      })
    );
  };

  // if (loading) return <Loader />;

  return (
    // <Modal show={true} onHide={() => navigate(-1)}>
    //   <Modal.Header closeButton>
    //     <h4>تعديل بيانات القسم</h4>
    //   </Modal.Header>
    <div className="acc-form mb-0">
      <div className="">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col md={12}>
              <Row>
                <Col xs={12}>
                  <MultiLangWrapper
                    label={
                      <label>
                        <FormattedMessage id={"title"} />
                      </label>
                    }
                    errors={errors?.title}
                    customClass="form-group selectHolder col-12"
                  >
                    {({ lang, classes, key }) => (
                      <>
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`)}
                        />
                        <p className={`error-hint ${classes}`}>
                          {(errors?.title?.en?.type === "required" ||
                            errors?.title?.ar?.type === "required") && (
                            <FormattedMessage id="required" />
                          )}
                        </p>
                      </>
                    )}
                  </MultiLangWrapper>
                </Col>

                <Col xs={12}>
                  <MultiLangWrapper
                    label={
                      <label>
                        <FormattedMessage id={"description"} />
                      </label>
                    }
                    errors={errors?.description}
                    customClass="form-group selectHolder col-12"
                  >
                    {({ lang, classes, key }) => (
                      <div className={`${classes}`}>
                        <Controller
                          key={key}
                          control={control}
                          name={`description.${lang}`}
                          render={({ field: { value, onChange } }) => (
                            <Editor
                              type="text"
                              key={key}
                              initialValue={value}
                              className={`form-control form-outline`}
                              onChange={(_, editor) => {
                                const data = editor.getContent();
                                onChange(data);
                              }}
                              init={{
                                directionality: lang === "ar" ? "rtl" : "ltr",
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                  </MultiLangWrapper>
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-blue"
                      disabled={loading}
                    >
                      <FormattedMessage id="save" />
                    </button>
                  </div>
                </Col>
                <Col
                  lg={4}
                  xs={12}
                  className={`d-flex justify-content-end ${
                    singleSection?.comments?.length === 0 ? "d-none" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => handleShowSection(getId(singleSection))}
                  >
                    <EyeIcon fill="#fff" />{" "}
                    {formatMessage({ id: "viewSection" })}
                  </button>
                </Col>
              </Row>
            </Col>
          </form>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default EditSection;
