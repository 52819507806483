import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import blogIcon from "assets/svgs/blog.svg";
import Table from "./Table";
import BootyPagination from "components/shared/Pagination";
const Index = () => {
  const { blogs, metadata } = useSelector((state) => state.blogs);

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة مقالة"
          cardTxt="المقالات"
          icon={blogIcon}
          url="/blogs/addblog"
        />
        <Table data={blogs} />
        {metadata?.totalResults > 8 && <BootyPagination metaData={metadata} />}
      </div>
    </>
  );
};

export default Index;
