import { getId } from "helpers/functions";
import {
  GET_SECTORS,
  GET_SECTORS_FAILURE,
  GET_SECTORS_SUCCESS,
  ADD_SECTOR,
  ADD_SECTOR_SUCCESS,
  ADD_SECTOR_FAILURE,
  DELETE_SECTOR,
  DELETE_SECTOR_SUCCESS,
  DELETE_SECTOR_FAILURE,
  GET_SINGLE_SECTOR,
  GET_SINGLE_SECTOR_FAILURE,
  GET_SINGLE_SECTOR_SUCCESS,
  SET_DEFAULT_SECTOR_SUCCESS,
} from "./actionTypes";

const initialState = {
  sectors: [],
  loading: false,
  error: "",
  singleSector: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTORS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SECTORS_SUCCESS:
      state = {
        ...state,
        loading: false,
        sectors: action.payload.sectors,
      };
      break;
    case GET_SECTORS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_SECTOR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_SECTOR_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSector: action.payload.sector,
      };
      break;
    case GET_SINGLE_SECTOR_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_SECTOR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_SECTOR_SUCCESS:
      state = {
        ...state,
        loading: false,
        sectors: action.payload.sectors,
      };
      break;
    case ADD_SECTOR_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_SECTOR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SECTOR_SUCCESS:
      const usersAfterDeleteing = [
        ...state?.sectors?.filter((sector) => getId(sector) !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        sectors: usersAfterDeleteing,
      };
      break;
    case DELETE_SECTOR_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case SET_DEFAULT_SECTOR_SUCCESS:
      const defaultItems = [
        ...(state?.sectors || [])?.map((item) =>
          getId(item) === action.payload?.id
            ? { ...item, isDefault: true }
            : { ...item, isDefault: false }
        ),
      ];

      state = {
        ...state,
        sectors: defaultItems,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
