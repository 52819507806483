import server from "./server";

export const getCouponsApi = async () => {
  const response = await server().get("/coupons");
  return response.data;
};

export const getSingleCouponApi = async (id) => {
  const response = await server().get(`/coupons/${id}`);
  return response.data;
};

export const addCouponApi = async (payload) => {
  const response = await server().post("/coupons", payload);
  return response.data;
};

export const editCouponApi = async ({ id, data }) => {
  const response = await server().put(`/coupons/${id}`, data);
  return response.data;
};

export const deleteCouponApi = async (id) => {
  const response = await server().delete(`/coupons/${id}`);
  return response.data;
};

export const activateCouponApi = async (id) => {
  const response = await server().post(`/coupons/activationCoupon/${id}`);
  return response.data;
};
