import {
  GET_BLOG_CATEGORIES,
  GET_BLOG_CATEGORIES_SUCCESS,
  GET_BLOG_CATEGORIES_FAILURE,
  ADD_BLOG_CATEGORY,
  ADD_BLOG_CATEGORY_SUCCESS,
  ADD_BLOG_CATEGORY_FAILURE,
  GET_SINGLE_BLOG_CATEGORY,
  GET_SINGLE_BLOG_CATEGORY_SUCCESS,
  GET_SINGLE_BLOG_CATEGORY_FAILURE,
  EDIT_BLOG_CATEGORY,
  EDIT_BLOG_CATEGORY_SUCCESS,
  EDIT_BLOG_CATEGORY_FAILURE,
  DELETE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY_FAILURE,
} from "./actionTypes";

export const getBlogCategories = () => {
  return {
    type: GET_BLOG_CATEGORIES,
  };
};

export const getBlogCategoriesSuccess = (blogCategories) => {
  return {
    type: GET_BLOG_CATEGORIES_SUCCESS,
    payload: blogCategories,
  };
};

export const getBlogCategoriesFailure = (error) => {
  return {
    type: GET_BLOG_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const getSingleBlogCategory = (id) => {
  return {
    type: GET_SINGLE_BLOG_CATEGORY,
    payload: id,
  };
};

export const getSingleBlogCategorySuccess = (blogCategory) => {
  return {
    type: GET_SINGLE_BLOG_CATEGORY_SUCCESS,
    payload: blogCategory,
  };
};

export const getSingleBlogCategoryFailure = (error) => {
  return {
    type: GET_SINGLE_BLOG_CATEGORY_FAILURE,
    payload: error,
  };
};

export const addBlogCategory = (payload) => {
  return {
    type: ADD_BLOG_CATEGORY,
    payload,
  };
};

export const addBlogCategorySuccess = (payload) => {
  return {
    type: ADD_BLOG_CATEGORY_SUCCESS,
    payload,
  };
};

export const addBlogCategoryFailure = (error) => {
  return {
    type: ADD_BLOG_CATEGORY_FAILURE,
    payload: error,
  };
};

export const editBlogCategory = (payload) => {
  return {
    type: EDIT_BLOG_CATEGORY,
    payload,
  };
};

export const editBlogCategorySuccess = (payload) => {
  return {
    type: EDIT_BLOG_CATEGORY_SUCCESS,
    payload,
  };
};

export const editBlogCategoryFailure = (error) => {
  return {
    type: EDIT_BLOG_CATEGORY_FAILURE,
    payload: error,
  };
};

export const deleteBlogCategory = (id) => {
  return {
    type: DELETE_BLOG_CATEGORY,
    payload: id,
  };
};

export const deleteBlogCategorySuccess = (id) => {
  return {
    type: DELETE_BLOG_CATEGORY_SUCCESS,
    payload: id,
  };
};

export const deleteBlogCategoryFailure = (error) => {
  return {
    type: DELETE_BLOG_CATEGORY_FAILURE,
    payload: error,
  };
};
