import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import stores from "./stores/reducer";
import categories from "./categories/reducer";
import packages from "./packages/reducer";
import users from "./users/reducer";
import productTypes from "./productTypes/reducer";
import products from "./products/reducer";
import brands from "./brands/reducer";
import options from "./options/reducer";
import features from "./features/reducer";
import blogs from "./blogs/reducer";
import blogCategories from "./blogCategories/reducer";
import languages from "./languages/reducer";
import orders from "./orders/reducer";
import dashboard from "./dashboard/reducer";
import notifications from "./notifications/reducer";
import coupons from "./coupons/reducer";
import units from "./measuringUnits/reducer";
import settings from "./settings/reducer";
import pages from "./pages/reducer";
import currencies from "./currencies/reducer";
import paymentMethods from "./paymentMethods/reducer";
import visitsReport from "./visitsReport/reducer";
import contacts from "./contacts/reducer";
import subscribers from "./subscribers/reducer";
import banks from "./banks/reducer";
import seoRedirects from "./seoRedirects/reducer";
import admins from "./admins/reducer";
import questions from "./questions/reducer";
import roles from "./roles/reducer";
import permissions from "./permissions/reducer";

import sections from "./questions/sections/reducer";

import controlReferences from "./filters/controlReferences/reducer";
import controlDomains from "./filters/controlDomains/reducer";
import sectors from "./filters/sectors/reducer";
import subControlDomains from "./filters/subControlDomains/reducer";
import organizationTypes from "./filters/organizationTypes/reducer";

const rootReducer = combineReducers({
  authentication,
  stores,
  categories,
  roles,
  permissions,
  contacts,
  packages,
  users,
  productTypes,
  products,
  brands,
  options,
  features,
  blogs,
  blogCategories,
  languages,
  orders,
  dashboard,
  notifications,
  coupons,
  units,
  settings,
  pages,
  currencies,
  paymentMethods,
  visitsReport,
  subscribers,
  banks,
  seoRedirects,
  admins,
  questions,
  sections,
  controlReferences,
  controlDomains,
  sectors,
  subControlDomains,
  organizationTypes,
});

export default rootReducer;
