import server from "./server";

export const getAllcontactsApi = async () => {
  const response = await server().get(`/contacts`);

  return response.data;
};

export const deleteContactApi = async (id) => {
  const response = await server().delete(`/contacts/${id}`);

  return response.data;
};

export const getcontactsApi = async (id) => {
  const response = await server().get(`/contacts/${id}`);

  return response.data;
};
