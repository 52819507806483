import {
  GET_CONTROL_REFERENCES,
  GET_CONTROL_REFERENCES_FAILURE,
  GET_CONTROL_REFERENCES_SUCCESS,
  ADD_CONTROL_REFERENCE,
  ADD_CONTROL_REFERENCE_SUCCESS,
  ADD_CONTROL_REFERENCE_FAILURE,
  EDIT_CONTROL_REFERENCE,
  EDIT_CONTROL_REFERENCE_SUCCESS,
  EDIT_CONTROL_REFERENCE_FAILURE,
  DELETE_CONTROL_REFERENCE,
  DELETE_CONTROL_REFERENCE_SUCCESS,
  DELETE_CONTROL_REFERENCE_FAILURE,
  GET_SINGLE_CONTROL_REFERENCE,
  GET_SINGLE_CONTROL_REFERENCE_SUCCESS,
  GET_SINGLE_CONTROL_REFERENCE_FAILURE,
  SET_DEFAULT_CONTROL_REFERENCE_SUCCESS,
} from "./actionTypes";

export const getControlReferences = () => {
  return {
    type: GET_CONTROL_REFERENCES,
  };
};

export const getControlReferencesSuccess = (users) => {
  return {
    type: GET_CONTROL_REFERENCES_SUCCESS,
    payload: users,
  };
};

export const getControlReferencesFailure = (error) => {
  return {
    type: GET_CONTROL_REFERENCES_FAILURE,
    payload: error,
  };
};

export const getSingleControlReference = (id) => {
  return {
    type: GET_SINGLE_CONTROL_REFERENCE,
    payload: id,
  };
};

export const getSingleControlReferenceSuccess = (user) => {
  return {
    type: GET_SINGLE_CONTROL_REFERENCE_SUCCESS,
    payload: user,
  };
};

export const getSingleControlReferenceFailure = (error) => {
  return {
    type: GET_SINGLE_CONTROL_REFERENCE_FAILURE,
    payload: error,
  };
};

export const addControlReference = (payload) => {
  return {
    type: ADD_CONTROL_REFERENCE,
    payload,
  };
};

export const addControlReferenceSuccess = (payload) => {
  return {
    type: ADD_CONTROL_REFERENCE_SUCCESS,
    payload,
  };
};

export const addControlReferenceFailure = (error) => {
  return {
    type: ADD_CONTROL_REFERENCE_FAILURE,
    payload: error,
  };
};

export const editControlReference = (payload) => {
  return {
    type: EDIT_CONTROL_REFERENCE,
    payload,
  };
};

export const editControlReferenceSuccess = (payload) => {
  return {
    type: EDIT_CONTROL_REFERENCE_SUCCESS,
    payload,
  };
};

export const editControlReferenceFailure = (error) => {
  return {
    type: EDIT_CONTROL_REFERENCE_FAILURE,
    payload: error,
  };
};

export const deleteControlReference = (id) => {
  return {
    type: DELETE_CONTROL_REFERENCE,
    payload: id,
  };
};

export const deleteControlReferenceSuccess = (id) => {
  return {
    type: DELETE_CONTROL_REFERENCE_SUCCESS,
    payload: id,
  };
};

export const deleteControlReferenceFailure = (error) => {
  return {
    type: DELETE_CONTROL_REFERENCE_FAILURE,
    payload: error,
  };
};

export const setDefaultControlReferenceSuccess = (payload) => {
  return {
    type: SET_DEFAULT_CONTROL_REFERENCE_SUCCESS,
    payload,
  };
};
