import axios from "axios";

const server = () => {
  const headers = {};
  const token = localStorage.getItem("token");
  // const store = localStorage.getItem("store");

  if (token) headers.Authorization = token;
  // if (store) headers.store = store;

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
};

export default server;
