import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { useFieldArray } from "react-hook-form";

const SlidersArray = ({ control, register, setValue, getValues, errors }) => {
  // const handleInputOnChange = (e, targetIndex) => {
  //   const { name, value } = e.target;

  //   const newSlidersArray = sliders.map((slider, index) => {
  //     if (targetIndex !== index) return slider;

  //     // make an object with locals inside the big one
  //     const inputName = name?.split(".")?.[0];
  //     const local = name?.split(".")?.[1];

  //     if (local) {
  //       return {
  //         ...slider,
  //         [inputName]: {
  //           ...slider?.[inputName],
  //           [local]: value,
  //         },
  //       };
  //     }
  //     return { ...slider, [name]: value };
  //   });
  //   setSliders(newSlidersArray);
  // };

  const { fields } = useFieldArray({
    control,
    name: "sections",
  });
  return (
    <div className="form-group branches sliders">
      <Row className="gap-2">
        <Col lg={12}>
          {/* <h5>
            <FormattedMessage id={"sliders"} />
          </h5> */}

          {fields.map((slider, index) => {
            return (
              <div key={index} className="add-multi-component">
                <Row>
                  <Col lg={12}>
                    {!slider?.sections && (
                      <ImageUpdate
                        value={getValues(`sections.${index}.image`)}
                        setValue={(v) => setValue(`sections.${index}.image`, v)}
                      />
                    )}
                  </Col>

                  <Col lg={6} xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"firstTitle"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.firstTitle}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(
                            `sections.${index}.firstTitle.${lang}`,
                            {}
                          )}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  <Col lg={6} xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`sections.${index}.title.${lang}`, {})}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"description"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.description}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}-${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(
                            `sections.${index}.description.${lang}`,
                            {}
                          )}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id={"link"} />
                      </h5>
                      <input
                        key={index}
                        type="text"
                        className="form-control form-outline"
                        {...register(`sections.${index}.link`, {})}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"label"} />
                          <FormattedMessage id={"link"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.linkLabel}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`sections.${index}.linkLabel.${lang}`)}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id={"subLink"} />
                      </h5>
                      <input
                        key={index}
                        type="text"
                        className="form-control form-outline"
                        {...register(`sections.${index}.subLink`, {})}
                      />
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"label"} />
                          <FormattedMessage id={"subLink"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.subLinkLabel}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(
                            `sections.${index}.subLinkLabel.${lang}`
                          )}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  {/* <div className="form-group card">
                    <Row>
                      {[1, 2, 3].map((_, subIndex) => (
                        <SubSection
                          register={register}
                          index={index}
                          subIndex={subIndex}
                          setValue={setValue}
                          getValues={getValues}
                          errors={errors}
                          key={subIndex}
                        />
                      ))}
                    </Row>
                  </div> */}
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const SubSection = ({
  register,
  index,
  subIndex,
  setValue,
  getValues,
  errors,
}) => {
  return (
    <Col lg={6} xs={12}>
      <Row className="add-multi-component my-2">
        <ImageUpdate
          value={getValues(`sections.${index}.sections.${subIndex}.image`)}
          setValue={(v) =>
            setValue(`sections.${index}.sections.${subIndex}.image`, v)
          }
        />
        <Col xs={12}>
          <MultiLangWrapper
            label={
              <label>
                <FormattedMessage id={"comment"} />
              </label>
            }
            errors={errors?.sections?.[index]?.sections?.[subIndex]?.comment}
            customClass="form-group selectHolder col-12"
          >
            {({ lang, classes, key }) => (
              <textarea
                key={`${index}-${key}-${subIndex}`}
                type="text"
                className={`form-control form-outline ${classes}`}
                {...register(
                  `sections.${index}.sections.${subIndex}.comment.${lang}`,
                  {}
                )}
              />
            )}
          </MultiLangWrapper>
        </Col>

        {/* <Col lg={6} xs={12}>
          <div className="form-group">
            <h5>
              <FormattedMessage id={"link"} />
            </h5>
            <input
              key={index}
              type="text"
              className="form-control form-outline"
              {...register(`sections.${index}.sections.${subIndex}.link`, {
                required: true,
              })}
            />
            <span className="error-hint" key="1">
              {errors?.sections?.[index]?.sections?.[subIndex]?.link &&
                "يجب ادخال بيانات"}
            </span>
          </div>
        </Col> */}
      </Row>
    </Col>
  );
};

export default SlidersArray;
