import React from "react";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import removeImg from "assets/svgs/close.svg";
import { FormattedMessage } from "react-intl";

const ImageUpdate = ({ value, setValue }) => {
  // const [selectedImage, setSelectedImage] = useState({
  //   preview: null,
  //   path: "",
  // });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      const path = data?.data?.files?.[0]?.fullpath;
      setValue(path);

      e.target.value = null;
    }
  };

  const removeSelectedImage = (e) => {
    setValue("");
  };

  return (
    <div className="form-group required">
      <h5>
        {" "}
        <FormattedMessage id={"image"} />
      </h5>

      <div className="image-uploader image-contain">
        <div className="img-pat">
          <img src={imageUpload} width={46} height={46} alt="" />
        </div>
        <label>
          {value && (
            <div>
              <img src={value} width={160} height={160} alt="" />
              <button className="removeImg" onClick={removeSelectedImage}>
                <img src={removeImg} alt="" />
              </button>
            </div>
          )}
          <input accept="image/*" type="file" onChange={imageChange} />
        </label>
      </div>
    </div>
  );
};

export default ImageUpdate;
