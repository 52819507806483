import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { DELETE_SUBSCRIBERS, GET_SUBSCRIBERS } from "./actionTypes";
import {
  getSubscribersSuccess,
  getSubscribersFailure,
  deleteSubscribersSuccess,
  deleteSubscribersFailure,
} from "./actions";
import { deleteSubscribersApi, getSubscribersApi } from "api/subscribers";

function* getSubscribers() {
  try {
    const { data } = yield call(getSubscribersApi);
    yield put(getSubscribersSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSubscribersFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* deleteSubscribers({ payload }) {
  try {
    yield call(deleteSubscribersApi, payload);
    yield put(deleteSubscribersSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      deleteSubscribersFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

export function* watchGetSubs() {
  yield takeEvery(GET_SUBSCRIBERS, getSubscribers);
}

export function* watchDelSubs() {
  yield takeEvery(DELETE_SUBSCRIBERS, deleteSubscribers);
}

function* subscribersSaga() {
  yield all([fork(watchGetSubs)]);
  yield all([fork(watchDelSubs)]);
}

export default subscribersSaga;
