import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { editProfileApi, getCurrentUser, postUserLogin } from "api/auth";

// Login Redux States
import {
  CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  EDIT_PROFILE,
} from "./actionTypes";
import {
  apiError,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  loginSuccess,
  logoutUserSuccess,
  editProfileSuccess,
  editProfileFailure,
} from "./actions";

function* loginUser({ payload }) {
  try {
    const { data } = yield call(postUserLogin, payload.data);
    yield put(loginSuccess(data));
    localStorage.setItem("token", data.token);
    // yield payload.navigate("/");
  } catch (error) {
    console.log(error);
    yield put(apiError(error?.response?.data.errors[0].msg));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem("token");
    localStorage.removeItem("store");
  } catch (error) {
    yield put(apiError(error?.response?.data.errors[0].msg));
  }
}

function* currentUser() {
  try {
    const { data } = yield call(getCurrentUser);
    yield put(getCurrentUserSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCurrentUserFailure(error?.response?.data.error || error));
  }
}

function* editProfile({ payload }) {
  try {
    const { data } = yield call(editProfileApi, payload);
    yield put(editProfileSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(editProfileFailure(error?.response?.data.error || error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUser);
}

export function* watchEditProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchCurrentUser),
    fork(watchEditProfile),
  ]);
}

export default authSaga;
