import server from "./server";

export const postUserLogin = async (data) => {
  const response = await server().post("/authAdmin/login", data);
  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get("/authAdmin/myProfile", data);
  return response.data;
};

export const editProfileApi = async ({ data }) => {
  const response = await server().put("/authAdmin/editProfile", data);
  return response.data;
};
