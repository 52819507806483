import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addOptionApi,
  deleteOptionApi,
  editOptionApi,
  getOptionsApi,
} from "api/options";

// Login Redux States
import {
  ADD_OPTION,
  DELETE_OPTION,
  EDIT_OPTION,
  GET_OPTIONS,
} from "./actionTypes";
import {
  addOptionFailure,
  addOptionSuccess,
  deleteOptionFailure,
  deleteOptionSuccess,
  editOptionFailure,
  editOptionSuccess,
  getOptionsFailure,
  getOptionsSuccess,
} from "./actions";

function* getOptions() {
  try {
    const { data } = yield call(getOptionsApi);
    yield put(getOptionsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getOptionsFailure(error?.response?.data.errors[0].msg));
  }
}

function* addOption({ payload }) {
  try {
    const { data } = yield call(addOptionApi, payload.data);
    yield put(addOptionSuccess(data));
    yield payload.navigate("/options");
  } catch (error) {
    console.log(error);
    yield put(addOptionFailure(error.response?.data?.message || error));
  }
}

function* editOption({ payload }) {
  try {
    yield call(editOptionApi, payload);
    yield put(editOptionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(editOptionFailure(error?.response?.data || error));
  }
}

function* deleteOption({ payload }) {
  try {
    yield call(deleteOptionApi, payload);
    yield put(deleteOptionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteOptionFailure(error?.response?.data || error));
  }
}

export function* watchGetOptions() {
  yield takeEvery(GET_OPTIONS, getOptions);
}

export function* watchAddOption() {
  yield takeEvery(ADD_OPTION, addOption);
}

export function* watchEditOption() {
  yield takeEvery(EDIT_OPTION, editOption);
}

export function* watchDeleteOption() {
  yield takeEvery(DELETE_OPTION, deleteOption);
}

function* brandsSaga() {
  yield all([fork(watchGetOptions)]);
  yield all([fork(watchAddOption)]);
  yield all([fork(watchEditOption)]);
  yield all([fork(watchDeleteOption)]);
}

export default brandsSaga;
