import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILURE,
} from "./actionTypes";

export const getLanguages = () => {
  return {
    type: GET_LANGUAGES,
  };
};

export const getLanguagesSuccess = (languages) => {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: languages,
  };
};

export const getLanguagesFailure = (error) => {
  return {
    type: GET_LANGUAGES_FAILURE,
    payload: error,
  };
};
