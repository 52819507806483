import { Outlet } from "react-router-dom";
import Table from "./Table";

const Sections = ({ data = [] }) => {
  return (
    <>
      <hr />
      <div className="sections-wrap">
        {/* <ControlArea
          btnTxt={formatMessage({ id: "addSection" })}
          cardTxt={formatMessage({ id: "sections" })}
          icon={peopleIcon}
          url="sections/add"
        /> */}
        <Outlet />
        <Table data={data} />
      </div>
    </>
  );
};

export default Sections;
