import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import citiesIcon from "assets/svgs/Setting.svg";
import Table from "./Table";
import { useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { getRoles } from "store/actions";
import { useEffect } from "react";

const Roles = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { roles, loading } = useSelector((state) => state.roles);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addRole" })}
          cardTxt={formatMessage({ id: "allRoles" })}
          icon={citiesIcon}
          url="/roles/addRole"
        />
        <Table data={roles} />
      </div>
    </>
  );
};

export default Roles;
