import {
  GET_SECTIONS,
  GET_SECTIONS_FAILURE,
  GET_SECTIONS_SUCCESS,
  ADD_SECTION,
  ADD_SECTION_SUCCESS,
  ADD_SECTION_FAILURE,
  EDIT_SECTION,
  EDIT_SECTION_SUCCESS,
  EDIT_SECTION_FAILURE,
  DELETE_SECTION,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
  GET_SINGLE_SECTION,
  GET_SINGLE_SECTION_SUCCESS,
  GET_SINGLE_SECTION_FAILURE,
} from "./actionTypes";

export const getSections = () => {
  return {
    type: GET_SECTIONS,
  };
};

export const getSectionsSuccess = (users) => {
  return {
    type: GET_SECTIONS_SUCCESS,
    payload: users,
  };
};

export const getSectionsFailure = (error) => {
  return {
    type: GET_SECTIONS_FAILURE,
    payload: error,
  };
};

export const getSingleSection = (id) => {
  return {
    type: GET_SINGLE_SECTION,
    payload: id,
  };
};

export const getSingleSectionSuccess = (user) => {
  return {
    type: GET_SINGLE_SECTION_SUCCESS,
    payload: user,
  };
};

export const getSingleSectionFailure = (error) => {
  return {
    type: GET_SINGLE_SECTION_FAILURE,
    payload: error,
  };
};

export const addSection = (payload) => {
  return {
    type: ADD_SECTION,
    payload,
  };
};

export const addSectionSuccess = (payload) => {
  return {
    type: ADD_SECTION_SUCCESS,
    payload,
  };
};

export const addSectionFailure = (error) => {
  return {
    type: ADD_SECTION_FAILURE,
    payload: error,
  };
};

export const editSection = (payload) => {
  return {
    type: EDIT_SECTION,
    payload,
  };
};

export const editSectionSuccess = (payload) => {
  return {
    type: EDIT_SECTION_SUCCESS,
    payload,
  };
};

export const editSectionFailure = (error) => {
  return {
    type: EDIT_SECTION_FAILURE,
    payload: error,
  };
};

export const deleteSection = (payload) => {
  return {
    type: DELETE_SECTION,
    payload,
  };
};

export const deleteSectionSuccess = (id) => {
  return {
    type: DELETE_SECTION_SUCCESS,
    payload: id,
  };
};

export const deleteSectionFailure = (error) => {
  return {
    type: DELETE_SECTION_FAILURE,
    payload: error,
  };
};
