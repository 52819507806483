import React from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Modal, Row } from "react-bootstrap";

import { addSection, getSingleQuestion } from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { Editor } from "components/shared/Editor";

const AddSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: questionId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    data.question = questionId;
    dispatch(
      addSection({
        data,
        callBack: () => {
          dispatch(getSingleQuestion(questionId));
          navigate(-1);
        },
      })
    );
  };

  return (
    <Modal show={true} onHide={() => navigate(-1)}>
      <Modal.Header closeButton>
        <h4>اضف قسم جديد</h4>
      </Modal.Header>
      <div className="acc-form sections-cards mb-0">
        <div className="">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <Row>
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>

                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"description"} />
                        </label>
                      }
                      errors={errors?.description}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <div className={`${classes}`}>
                          <Controller
                            key={key}
                            control={control}
                            name={`description.${lang}`}
                            render={({ field: { value, onChange } }) => (
                              <Editor
                                type="text"
                                key={key}
                                initialValue={value}
                                className={`form-control form-outline`}
                                onChange={(_, editor) => {
                                  const data = editor.getContent();
                                  onChange(data);
                                }}
                                init={{
                                  directionality: lang === "ar" ? "rtl" : "ltr",
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </MultiLangWrapper>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="add" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSection;
