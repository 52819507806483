import server from "./server";

export const getOrganizationTypesApi = async () => {
  const response = await server().get("/organizationTypes");
  return response.data;
};

export const getSingleOrganizationTypeApi = async (id) => {
  const response = await server().get(`/organizationTypes/${id}`);
  return response.data;
};

export const addOrganizationTypeApi = async (payload) => {
  const response = await server().post("/organizationTypes", payload);
  return response.data;
};

export const editOrganizationTypeApi = async ({ id, data }) => {
  const response = await server().put(`/organizationTypes/${id}`, data);
  return response.data;
};

export const deleteOrganizationTypeApi = async (id) => {
  const response = await server().delete(`/organizationTypes/${id}`);
  return response.data;
};
