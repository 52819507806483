export const GET_ALL_SEO_REDIRECTS = "GET_ALL_SEO_REDIRECTS";
export const GET_ALL_SEO_REDIRECTS_SUCCESS = "GET_ALL_SEO_REDIRECTS_SUCCESS";
export const GET_ALL_SEO_REDIRECTS_FAILURE = "GET_ALL_SEO_REDIRECTS_FAILURE";

export const DELETE_SEO_REDIRECTS = "DELETE_SEO_REDIRECTS";
export const DELETE_SEO_REDIRECTS_SUCCESS = "DELETE_SEO_REDIRECTS_SUCCESS";
export const DELETE_SEO_REDIRECTS_FAILURE = "DELETE_SEO_REDIRECTS_FAILURE";

export const GET_SEO_REDIRECTS = "GET_SEO_REDIRECTS";
export const GET_SEO_REDIRECTS_SUCCESS = "GET_SEO_REDIRECTS_SUCCESS";
export const GET_SEO_REDIRECTS_FAILURE = "GET_SEO_REDIRECTS_FAILURE";

export const EDIT_SEO_REDIRECTS = "EDIT_SEO_REDIRECTS";
export const EDIT_SEO_REDIRECTS_SUCCESS = "EDIT_SEO_REDIRECTS_SUCCESS";
export const EDIT_SEO_REDIRECTS_FAILURE = "EDIT_SEO_REDIRECTS_FAILURE";

export const ADD_SEO_REDIRECTS = "ADD_SEO_REDIRECTS";
export const ADD_SEO_REDIRECTS_SUCCESS = "ADD_SEO_REDIRECTS_SUCCESS";
export const ADD_SEO_REDIRECTS_FAILURE = "ADD_SEO_REDIRECTS_FAILURE";
