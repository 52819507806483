import React, { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
// import { getActiveStore, getStores } from "store/actions";
// import { useIntl } from "react-intl";

// import Notifications from "components/shared/Notifications";

import userimg from "assets/images/user.svg";
import logoIcon from "assets/images/logo-icon.svg";
// import logoInfo from "assets/images/logo-info.svg";
import userIcon from "assets/svgs/user.svg";
import logoutIcon from "assets/svgs/power.svg";
import searchIcon from "assets/svgs/search.svg";
import darkSwitcherIcon from "assets/svgs/dark-mode.svg";
import { getAllSettings } from "store/actions";
import { handleImage } from "helpers/functions";
// import rippon from "assets/images/rippon.svg";
import burgerIcon from "assets/svgs/burger.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Context } from "wrapper";

const Header = () => {
  const { locale } = useIntl();
  const context = useContext(Context);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const { mainSettings } = useSelector((state) => state.settings);

  const sidebarToggle = () => {
    document.querySelector("body").classList.toggle("sidebarToggled");
    document.querySelector(".overlay-s").classList.toggle("fire");
    document.querySelector("html").classList.toggle("offScroll");
  };

  const searchToggle = () => {
    document.querySelector("html").classList.toggle("offScroll");
    document.querySelector(".searchWrap").classList.toggle("active");
  };

  const handleChangeLangOnClick = () => {
    const lang = locale === "ar" ? "en" : "ar";
    localStorage.setItem("lang", lang);

    context.setLocale(lang);

    document.body.setAttribute("dir", lang === "ar" ? "rtl" : "ltr");
    document.documentElement.lang = lang;
    document.querySelector("title").textContent =
      lang === "ar" ? "لوحة التحكم" : "Dashboard";
  };

  // store the theme in the localStorage and invoke the theme function
  // const themeSwitcher = () => {
  //   const theme = localStorage.getItem("data-theme");
  //   if (!theme) {
  //     localStorage.setItem("theme", "dark");
  //   }
  //   if (theme) {
  //     localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
  //   }
  //   themeSwitcherFunction();
  // };

  const themeSwitcher = () => {
    const theme = localStorage.getItem("data-theme") || "light";
    document.documentElement.setAttribute(
      "data-theme",
      theme === "light" ? "dark" : "light"
    );
    localStorage.setItem("data-theme", theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    const theme = localStorage.getItem("data-theme") || "light";
    document.documentElement.setAttribute("data-theme", theme);

    dispatch(getAllSettings());
  }, []);

  // useEffect(() => {
  // dispatch(getActiveStore({ storeId: store, deviceType }));
  // dispatch(getStores());
  // }, [deviceType, dispatch, store]);
  const settingLogo = mainSettings?.find(
    (setting) => setting?.title === "logo"
  )?.value;
  return (
    <>
      <div className="main-head">
        <div className="logo">
          <div className="inner">
            <NavLink to="/">
              <div className="logo-icon">
                <img src={handleImage(settingLogo)} alt="" />
              </div>
              {/* <div className="logo-info">
                <img src={logoInfo} alt="" />
              </div> */}
            </NavLink>
          </div>
          {/* <div>
            <NavLink
              to="/stores/addstore"
              className="btn btn-blue"
              title="انشئ متجرك"
            >
              انشئ متجرك
            </NavLink>
          </div> */}
          <div className="burgerBtn">
            <button className="toggleSidebar" onClick={sidebarToggle}>
              <img src={burgerIcon} alt="" />
            </button>
          </div>
          <button className="btn px-0" onClick={handleChangeLangOnClick}>
            <h5>{locale === "ar" ? "EN" : "AR"}</h5>
          </button>
          <button className="darkSwitcher" onClick={themeSwitcher}>
            <img src={darkSwitcherIcon} alt="" />
          </button>
          {/* <Notifications /> */}
          <div className="authWrap">
            <ul>
              <li className="menu-item-has-children">
                <Dropdown>
                  <Dropdown.Toggle>
                    <div className="au-flow">
                      <div className="au-img">
                        <img src={user?.image || userimg} alt="" />
                      </div>
                      <div className="au-data">
                        <h4>{user?.name}</h4>
                        <p>{user?.role?.name}</p>
                      </div>
                    </div>
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                        fill="black"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/profile">
                      {/* <NavLink to="/profile" className="p-0 text"> */}
                      <img src={userIcon} alt="" />

                      <FormattedMessage id="profile" />

                      {/* </NavLink> */}
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/logout">
                      {/* <NavLink to="/logout" className="p-0"> */}
                      <img src={logoutIcon} alt="" />

                      <FormattedMessage id="logout" />

                      {/* </NavLink> */}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        <div className="head-wrp">
          <div className="burgerBtn">
            <button className="toggleSidebar" onClick={sidebarToggle}>
              <img src={burgerIcon} alt="" />
            </button>
          </div>

          <div className="left-actions">
            {/* <button className="searchToggle" onClick={searchToggle}>
              <img src={searchIcon} alt="" />
            </button> */}

            <button className="btn px-0" onClick={handleChangeLangOnClick}>
              <h5>{locale === "ar" ? "EN" : "AR"}</h5>
            </button>
            <button className="darkSwitcher" onClick={themeSwitcher}>
              <img src={darkSwitcherIcon} alt="" />
            </button>
            {/* <Notifications /> */}
            <div className="authWrap">
              <ul>
                <li className="menu-item-has-children">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <div className="au-flow">
                        <div className="au-img">
                          <img src={user?.image || userimg} alt="" />
                        </div>
                        <div className="au-data">
                          <h4>{user?.name}</h4>
                          <p>{user?.role?.name}</p>
                        </div>
                      </div>
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                          fill="black"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={NavLink} to="/profile">
                        {/* <NavLink to="/profile" className="p-0 text"> */}
                        <img src={userIcon} alt="" />
                        <FormattedMessage id="profile" />

                        {/* </NavLink> */}
                      </Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/logout">
                        {/* <NavLink to="/logout" className="p-0"> */}
                        <img src={logoutIcon} alt="" />
                        <FormattedMessage id="logout" />

                        {/* </NavLink> */}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay-s" onClick={sidebarToggle}></div>
    </>
  );
};

export default Header;
