import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedDate, FormattedMessage } from "react-intl";
import { getContact } from "store/actions";
import Loader from "components/shared/Loader";

const ShowContact = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    loading,
    singleContact: { contact: singleContact },
  } = useSelector((state) => state.contacts);

  useEffect(() => {
    dispatch(getContact(id));
  }, [dispatch, id]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form contacts">
        <div className="card job-details">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"showContact"} />
            </h4>
          </div>
          <div className="card-body table-details--">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id={"name"} />
                    </td>
                    <td>{singleContact?.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id={"email"} />
                    </td>
                    <td>{singleContact?.email} </td>
                  </tr>
                  {/* <tr>
                    <td > <FormattedMessage id={"phone"} /></td>
                    <td >{singleContact?.phone} </td>
                  </tr>
                  <tr>
                    <td > <FormattedMessage id={"service"} /></td>
                    <td >{singleContact?.service?.heading?.[locale]} </td>
                  </tr> */}
                  {singleContact?.package?.length > 0 && (
                    <tr>
                      <td>
                        <FormattedMessage id={"package"} />
                      </td>
                      <td>{singleContact?.package} </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <FormattedMessage id={"message"} />
                    </td>
                    <td>{singleContact?.message} </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id={"date"} />
                    </td>
                    <td>
                      <FormattedDate
                        value={singleContact?.createdAt || ""}
                        day="numeric"
                        month="long"
                        year="numeric"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowContact;
