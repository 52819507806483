import {
  GET_ALL_SEO_REDIRECTS,
  GET_ALL_SEO_REDIRECTS_SUCCESS,
  GET_ALL_SEO_REDIRECTS_FAILURE,
  DELETE_SEO_REDIRECTS,
  DELETE_SEO_REDIRECTS_SUCCESS,
  DELETE_SEO_REDIRECTS_FAILURE,
  GET_SEO_REDIRECTS,
  GET_SEO_REDIRECTS_SUCCESS,
  GET_SEO_REDIRECTS_FAILURE,
  ADD_SEO_REDIRECTS,
  ADD_SEO_REDIRECTS_SUCCESS,
  ADD_SEO_REDIRECTS_FAILURE,
  EDIT_SEO_REDIRECTS,
  EDIT_SEO_REDIRECTS_SUCCESS,
  EDIT_SEO_REDIRECTS_FAILURE,
} from "./actionTypes";

export const getAllSeoRedirects = (payload) => {
  return {
    type: GET_ALL_SEO_REDIRECTS,
    payload: payload,
  };
};
export const getAllSeoRedirectsSuccess = (payload) => {
  return {
    type: GET_ALL_SEO_REDIRECTS_SUCCESS,
    payload: payload,
  };
};

export const getAllSeoRedirectsFailure = (error) => {
  return {
    type: GET_ALL_SEO_REDIRECTS_FAILURE,
    payload: error,
  };
};

export const editSeoRedirect = (payload) => {
  return {
    type: EDIT_SEO_REDIRECTS,
    payload,
  };
};

export const editSeoRedirectSuccess = (payload) => {
  return {
    type: EDIT_SEO_REDIRECTS_SUCCESS,
    payload,
  };
};

export const editSeoRedirectFailure = (error) => {
  return {
    type: EDIT_SEO_REDIRECTS_FAILURE,
    payload: error,
  };
};

export const addSeoRedirect = (payload) => {
  return {
    type: ADD_SEO_REDIRECTS,
    payload,
  };
};

export const addSeoRedirectSuccess = (payload) => {
  return {
    type: ADD_SEO_REDIRECTS_SUCCESS,
    payload,
  };
};

export const addSeoRedirectFailure = (error) => {
  return {
    type: ADD_SEO_REDIRECTS_FAILURE,
    payload: error,
  };
};

export const deleteSeoRedirect = (id) => {
  return {
    type: DELETE_SEO_REDIRECTS,
    payload: id,
  };
};

export const deleteSeoRedirectSuccess = (id) => {
  return {
    type: DELETE_SEO_REDIRECTS_SUCCESS,
    payload: id,
  };
};

export const deleteSeoRedirectFailure = (error) => {
  return {
    type: DELETE_SEO_REDIRECTS_FAILURE,
    payload: error,
  };
};

export const getSeoRedirect = (id) => {
  return {
    type: GET_SEO_REDIRECTS,
    payload: id,
  };
};

export const getSeoRedirectSuccess = (id) => {
  return {
    type: GET_SEO_REDIRECTS_SUCCESS,
    payload: id,
  };
};

export const getSeoRedirectFailure = (error) => {
  return {
    type: GET_SEO_REDIRECTS_FAILURE,
    payload: error,
  };
};
