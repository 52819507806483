import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getQuestionsApi,
  addQuestionApi,
  editQuestionApi,
  deleteQuestionApi,
  getSingleQuestionApi,
  rejectQuestionCommentApi,
  approveQuestionCommentApi,
} from "api/questions";

// Login Redux States
import {
  ADD_QUESTION,
  DELETE_QUESTION,
  EDIT_QUESTION,
  GET_SINGLE_QUESTION,
  GET_QUESTIONS,
  APPROVE_QUESTION_COMMENT,
  REJECT_QUESTION_COMMENT,
} from "./actionTypes";
import {
  addQuestionFailure,
  addQuestionSuccess,
  getQuestionsSuccess,
  getQuestionsFailure,
  editQuestionSuccess,
  editQuestionFailure,
  deleteQuestionSuccess,
  deleteQuestionFailure,
  getSingleQuestionSuccess,
  getSingleQuestionFailure,
  approveQuestionCommentSuccess,
  approveQuestionCommentFailure,
  rejectQuestionCommentSuccess,
  rejectQuestionCommentFailure,
} from "./actions";

function* getQuestions({ payload }) {
  try {
    const { data } = yield call(getQuestionsApi, payload);
    yield put(getQuestionsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getQuestionsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleQuestion({ payload }) {
  try {
    const { data } = yield call(getSingleQuestionApi, payload);
    yield put(getSingleQuestionSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleQuestionFailure(error?.response?.data || error));
  }
}

function* addQuestion({ payload }) {
  try {
    const { data } = yield call(addQuestionApi, payload.data);
    yield put(addQuestionSuccess(data));
    if (!!payload?.callBack) yield payload?.callBack(data?.question);
    else yield payload.navigate("/questions");
  } catch (error) {
    console.log(error);
    yield put(addQuestionFailure(error.response?.data?.message || error));
  }
}

function* approveQuestionComment({ payload }) {
  try {
    const { data } = yield call(approveQuestionCommentApi, payload);
    yield put(approveQuestionCommentSuccess(data));
    // yield payload.navigate("/questions");
  } catch (error) {
    console.log(error);
    yield put(
      approveQuestionCommentFailure(error.response?.data?.message || error)
    );
  }
}

function* rejectQuestionComment({ payload }) {
  try {
    const { data } = yield call(rejectQuestionCommentApi, payload);
    yield put(rejectQuestionCommentSuccess(data));
    // yield payload.navigate("/questions");
  } catch (error) {
    console.log(error);
    yield put(
      rejectQuestionCommentFailure(error.response?.data?.message || error)
    );
  }
}

function* editQuestion({ payload }) {
  try {
    const { data } = yield call(editQuestionApi, payload);
    yield put(editQuestionSuccess(data));
    yield payload.navigate("/questions");
  } catch (error) {
    console.log(error);
    yield put(editQuestionFailure(error.response?.data?.message || error));
  }
}

function* deleteQuestion({ payload }) {
  try {
    yield call(deleteQuestionApi, payload);
    yield put(deleteQuestionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteQuestionFailure(error?.response?.data || error));
  }
}

export function* watchGetQuestions() {
  yield takeEvery(GET_QUESTIONS, getQuestions);
}

export function* watchSingleQuestion() {
  yield takeEvery(GET_SINGLE_QUESTION, getSingleQuestion);
}

export function* watchAddQuestion() {
  yield takeEvery(ADD_QUESTION, addQuestion);
}

export function* watchApproveQuestionComment() {
  yield takeEvery(APPROVE_QUESTION_COMMENT, approveQuestionComment);
}

export function* watchRejectQuestionComment() {
  yield takeEvery(REJECT_QUESTION_COMMENT, rejectQuestionComment);
}

export function* watchEditQuestion() {
  yield takeEvery(EDIT_QUESTION, editQuestion);
}

export function* watchDeleteQuestion() {
  yield takeEvery(DELETE_QUESTION, deleteQuestion);
}

function* QuestionsSaga() {
  yield all([fork(watchGetQuestions)]);
  yield all([fork(watchSingleQuestion)]);
  yield all([fork(watchAddQuestion)]);
  yield all([fork(watchApproveQuestionComment)]);
  yield all([fork(watchRejectQuestionComment)]);
  yield all([fork(watchEditQuestion)]);
  yield all([fork(watchDeleteQuestion)]);
}

export default QuestionsSaga;
