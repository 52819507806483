import server from "./server";

export const getQuestionsApi = async (payload) => {
  const response = await server().get(`/questions${payload || ""}`);
  return response.data;
};

export const getSingleQuestionApi = async (id) => {
  const response = await server().get(`/questions/${id}`);
  return response.data;
};

export const addQuestionApi = async (payload) => {
  const response = await server().post("/questions", payload);
  return response.data;
};

export const approveQuestionCommentApi = async ({ sectionId, commentId }) => {
  const response = await server().get(
    `/sections/approveComment/${sectionId}/${commentId}`
  );
  return response.data;
};

export const rejectQuestionCommentApi = async ({ sectionId, commentId }) => {
  const response = await server().delete(
    `/sections/deleteComment/${sectionId}/${commentId}`
  );
  return response.data;
};

export const editQuestionApi = async ({ id, data }) => {
  const response = await server().put(`/questions/${id}`, data);
  return response.data;
};

export const deleteQuestionApi = async (id) => {
  const response = await server().delete(`/questions/${id}`);
  return response.data;
};
