import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getControlDomains, getSubControlDomains } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const SubControlDomains = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { subControlDomains } = useSelector((state) => state.subControlDomains);
  const { controlDomains } = useSelector((state) => state.controlDomains);

  useEffect(() => {
    dispatch(getSubControlDomains());
    if (controlDomains?.length === 0) dispatch(getControlDomains());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="subControlDomains-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addSubControlDomain" })}
          cardTxt={formatMessage({ id: "subControlDomains" })}
          icon={peopleIcon}
          url="/subControlDomains/add"
        />
        <Table data={subControlDomains} controlDomains={controlDomains} />
      </div>
    </>
  );
};

export default SubControlDomains;
