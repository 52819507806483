import React, { useEffect } from "react";
import MainSettings from "./mainSettings";
import SocialsSettings from "./SocialsSettings";
// import MailSettings from './MailSettings'
import { getAllSettings } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";

const Settings = () => {
  const dispatch = useDispatch();
  const { error, mainSettings, socialSettings, accountsSettings } = useSelector(
    (state) => state.settings
  );
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={"sendingErrorMessage"} />, {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error]);

  // if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form settings_tabs">
        <Tabs
          defaultActiveKey="mainSettings"
          id="home_page_tabs"
          className="home_page_tabs__"
        >
          <Tab
            eventKey="mainSettings"
            title={formatMessage({ id: "mainSettings" })}
          >
            <MainSettings
              settings={mainSettings}
              title={<FormattedMessage id={"mainSettings"} />}
            />
          </Tab>
          <Tab
            eventKey="socialSettings"
            title={formatMessage({ id: "socialSettings" })}
          >
            <SocialsSettings
              settings={socialSettings}
              title={<FormattedMessage id={"socialSettings"} />}
            />
          </Tab>
          {/* <Tab
            eventKey="accountsSettings"
            title={formatMessage({ id: "accountsSettings" })}
          >
            <AccountSettings
              settings={accountsSettings}
              title={<FormattedMessage id={"accountsSettings"} />}
            />
          </Tab> */}
          {/* <Tab eventKey="mailSettings" title={formatMessage({ id: "mailSettings" })}   >
            <MailSettings settings={mailSettings} title={<FormattedMessage id={"mailSettings"} />} />
          </Tab> */}
        </Tabs>
      </div>
    </>
  );
};

export default Settings;
