import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { deleteAdmin } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import { getId } from "helpers/functions";
import Swal from "sweetalert2";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEditCity = (id) => {
    navigate(`/admins/${id}`);
  };

  const handleDeleteCity = (name, id) => {
    Swal.fire({
      title: `${formatMessage({ id: "delete" })} ${formatMessage({
        id: "admin",
      })} ${name}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b659e",
      cancelButtonColor: "#cc4949",
      confirmButtonText: formatMessage({ id: "yes" }),
      cancelButtonText: formatMessage({ id: "no" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAdmin(id));
      }
    });

    // dispatch(deleteAdmin(id));
  };

  const columns = [
    {
      name: <FormattedMessage id="name" />,
      selector: (row) => <span>{row?.name?.[locale] || row?.name}</span>,
      sortable: true,
    },
    {
      name: <FormattedMessage id="phone" />,
      selector: (row) => <span>{row?.phone}</span>,
      sortable: true,
    },
    {
      name: <FormattedMessage id="email" />,
      selector: (row) => <span>{row?.email}</span>,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <div className="global-dropdown">
            <Dropdown>
              <Dropdown.Toggle>
                <DotsIcon width="20" height="40" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handleEditCity(getId(row))}>
                  <EditIcon className="bi bi-pencil edit" />
                  <FormattedMessage id="edit" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    handleDeleteCity(
                      row?.name?.[locale] || row?.name,
                      getId(row)
                    )
                  }
                >
                  <DeleteIcon className="bi bi-trash delete" fill="#fff" />
                  <FormattedMessage id="delete" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="admins" />
                </h4>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              //selectableRows
              //subHeader
              subHeaderComponent={subHeaderComponent}
              //selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
