import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ReactComponent as CheckIcon } from "assets/svgs/check.svg";
import { ReactComponent as ArrowUpIcon } from "assets/svgs/arrow-up.svg";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";

import {
  approveQuestionComment,
  editSection,
  getSingleQuestion,
  rejectQuestionComment,
} from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { getId, handleImage } from "helpers/functions";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

const ShowSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sectionId: id, id: questionId } = useParams();
  const { locale, formatMessage } = useIntl();

  const { loading } = useSelector((state) => state?.sections);
  const { singleQuestion } = useSelector((state) => state?.questions);

  useEffect(() => {
    if (Object.keys(singleQuestion)?.length === 0)
      dispatch(getSingleQuestion(questionId));
  }, [dispatch, questionId, singleQuestion]);

  const singleSection = useMemo(
    () => singleQuestion?.sections?.find((section) => getId(section) === id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(singleQuestion), id]
  );

  const renderCards = singleSection?.comments?.map((item, index) => (
    <Col xs="12" key={index} className="comment">
      <img src={handleImage(item?.user?.image)} alt="" />
      <div className="card">
        <div className="card-head mb-0">
          <div className="info">
            <div className="user">
              <p>{`${item?.user?.firstName} ${item?.user?.lastName}`}</p>
              <div
                className={`actions ${
                  item?.status !== "approved" ? "" : "d-none"
                }`}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="button-tooltip">
                      {formatMessage({ id: "approveComment" })}
                    </Tooltip>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-info rounded-pill"
                    disabled={item?.status === "approved"}
                    onClick={() =>
                      dispatch(
                        approveQuestionComment({
                          sectionId: getId(singleSection),
                          commentId: getId(item),
                        })
                      )
                    }
                  >
                    <CheckIcon />
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <h4 className="fs-6" dir="auto" title={item?.comment}>
              {item?.comment}
            </h4>
          </div>
        </div>
        <div className="sub-info">
          {item?.status === "approved" && (
            <div className="approved text-success">
              <div type="button" className="btn btn-success rounded-pill">
                <CheckIcon />
              </div>
              <FormattedMessage id="approvedAt" />{" "}
              <FormattedDate
                value={item?.createdAt}
                day="numeric"
                month="long"
                year="numeric"
                hour="numeric"
                minute="numeric"
              />
            </div>
          )}
          <h5 className="small">
            <FormattedDate
              value={item?.createdAt}
              day="numeric"
              month="long"
              year="numeric"
              hour="numeric"
              minute="numeric"
            />
          </h5>
        </div>
      </div>
    </Col>
  ));

  if (loading) return <Loader />;

  return (
    <Modal
      show={true}
      size="lg"
      onHide={() => navigate(-1)}
      dialogClassName="show-section"
    >
      <Modal.Header closeButton>
        <h5>
          <FormattedMessage id="viewSection" />
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="acc-form sections-cards mb-0">
          <div className="">
            <div className="card-body">
              <div className="">
                <div className="card-head show-section-head">
                  <h4
                    dir="auto"
                    // className="w-100"
                    title={
                      singleSection?.title?.[locale] || singleSection?.title
                    }
                  >
                    {singleSection?.title?.[locale] || singleSection?.title}
                  </h4>
                  <div className="vote">
                    <ArrowUpIcon />
                    <span>{singleSection?.upVotes}</span>
                    <span className="opacity-50">|</span>
                    <ArrowUpIcon />
                    <span>{singleSection?.downVotes}</span>
                  </div>
                  {/* <div className="actions">
                    <button
                      type="button"
                      className="btn btn-danger rounded-pill"
                      onClick={() => handleDeleteSection(getId(item))}
                    >
                      <DeleteIcon />
                    </button>
                    <button
                      type="button"
                      className="btn btn-info rounded-pill"
                      onClick={() => handleEditSection(getId(item))}
                    >
                      <EditIcon />
                    </button>
                    <button
                      type="button"
                      className="btn btn-success rounded-pill"
                      onClick={() => handleShowSection(getId(item))}
                    >
                      <EyeIcon />
                    </button>
                  </div> */}
                </div>
                <div className="card-body">
                  <p
                    dir="auto"
                    dangerouslySetInnerHTML={{
                      __html:
                        singleSection?.description?.[locale] ||
                        singleSection?.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <hr />
            {singleSection?.comments?.length > 0 && (
              <h5>
                <FormattedMessage id="manageComments" />
              </h5>
            )}
            <div className="body row">{renderCards}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowSection;
