import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addBankApi,
  deleteBankApi,
  editBankApi,
  getBanksApi,
  getSingleBankApi,
} from "api/banks";

// Login Redux States
import {
  ADD_BANK,
  DELETE_BANK,
  EDIT_BANK,
  GET_BANKS,
  GET_SINGLE_BANK,
} from "./actionTypes";

import {
  addBankFailure,
  addBanksuccess,
  deleteBankFailure,
  deleteBanksuccess,
  editBankFailure,
  editBanksuccess,
  getBanksFailure,
  getBanksSuccess,
  getSingleBankFailure,
  getSingleBanksuccess,
} from "./actions";

function* getBanks() {
  try {
    const { data } = yield call(getBanksApi);
    yield put(getBanksSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getBanksFailure(error.response.data.errors[0].msg));
  }
}

function* addBank({ payload }) {
  try {
    const { data } = yield call(addBankApi, payload.data);
    yield put(addBanksuccess(data));
    yield payload.navigate("/banks");
  } catch (error) {
    console.log(error);
    yield put(addBankFailure(error.response?.data?.message || error));
  }
}

function* getSingleBank({ payload }) {
  try {
    const { data } = yield call(getSingleBankApi, payload);
    yield put(getSingleBanksuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleBankFailure(error.response.data || error));
  }
}

function* editBank({ payload }) {
  try {
    yield call(editBankApi, payload);
    yield put(editBanksuccess(payload));
    yield payload.navigate("/banks");
  } catch (error) {
    console.log(error);
    yield put(editBankFailure(error.response.data || error));
  }
}

function* deleteBank({ payload }) {
  try {
    yield call(deleteBankApi, payload);
    yield put(deleteBanksuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteBankFailure(error.response.data || error));
  }
}

export function* watchGetBanks() {
  yield takeEvery(GET_BANKS, getBanks);
}

export function* watchAddBank() {
  yield takeEvery(ADD_BANK, addBank);
}

export function* watchGetSingleBank() {
  yield takeEvery(GET_SINGLE_BANK, getSingleBank);
}

export function* watchEditBank() {
  yield takeEvery(EDIT_BANK, editBank);
}

export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK, deleteBank);
}

function* banksSaga() {
  yield all([fork(watchGetBanks)]);
  yield all([fork(watchGetSingleBank)]);
  yield all([fork(watchAddBank)]);
  yield all([fork(watchEditBank)]);
  yield all([fork(watchDeleteBank)]);
}

export default banksSaga;
