import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const Packages = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { packages } = useSelector((state) => state.packages);

  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  return (
    <div className="packages-wrap">
      <ControlArea
        btnTxt={formatMessage({ id: "addPackage" })}
        cardTxt={formatMessage({ id: "packages" })}
        icon={peopleIcon}
        url="/packages/add"
      />
      <Table data={packages} />
    </div>
  );
};

export default Packages;
