import ControlArea from "components/Layout/ControlArea";
import Loader from "components/shared/Loader";
import StickyLoader from "components/shared/StickyLoader";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/actions";
import categoriesIcon from "assets/svgs/categories.svg";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import Table from "./Table";
import { FormattedMessage, useIntl } from "react-intl";
// import DndWrapper from "components/categories/DndWrapper";
// import DndSortable from "components/categories/DndSortable";
// import { SortableTree } from "components/categories/SortableTree";

const Index = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [modalProps, setModalProps] = useState({ show: false });

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const { categories, loading, editLoading } = useSelector(
    (state) => state.categories
  );

  if (loading) return <Loader />;

  return (
    <>
      <div className="stores-wrap categories-page">
        {!!editLoading && <StickyLoader fill="#e9deff" />}

        <ControlArea
          btnTxt={formatMessage({ id: "addCategory" })}
          cardTxt={formatMessage({ id: "categories" })}
          icon={categoriesIcon}
          url="/categories/addcategory"
          handleClick={() =>
            setModalProps({
              show: true,
              add: true,
              category: {
                parentCategory: "",
              },
            })
          }
        />
        <Table
          // data={categories?.filter((e) => !e?.parentCategory)}
          data={categories}
          openModal={(parentCategory) =>
            setModalProps({
              show: true,
              add: true,
              category: {
                parentCategory,
              },
            })
          }
        />
        {/* <DndWrapper categories={categories} /> */}
        {/* <DndSortable
            categories={categories}
            modalProps={modalProps}
            setModalProps={setModalProps}
          /> */}
        {/* <SortableTree /> */}
      </div>
      <Modal
        dialogClassName="productDataModal"
        show={modalProps?.show}
        onHide={() => setModalProps({})}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>
              <FormattedMessage
                id={modalProps?.add ? "addCategory" : "editCategory"}
              />
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!modalProps?.show && !modalProps?.add ? (
            <EditCategory
              category={modalProps?.category}
              handleClose={() => setModalProps({})}
            />
          ) : (
            <AddCategory
              category={modalProps?.category}
              handleClose={() => setModalProps({})}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Index;
