import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { useState } from "react";
// import { v4 } from "uuid";
import SliderArray from "./SliderArray";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { editPage, getPage } from "store/actions";
import { useNavigate } from "react-router-dom";

export default function CookiesPolicy() {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, singlePage } = useSelector((state) => state.pages);
  const sections = useMemo(
    () =>
      Object.entries(singlePage?.content || {}).map(([key, value], id) => ({
        ...value,
        id,
      })),
    [singlePage]
  );

  const { reset, handleSubmit, control } = useForm();
  const [sliders, setSliders] = useState(sections);

  // get single page by slug
  useEffect(() => {
    dispatch(getPage("cookies-policy"));
  }, [dispatch]);

  useEffect(() => {
    setSliders(sections);
    reset({ sections });
  }, [reset, sections, singlePage]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    let index = 0;
    data = { ...singlePage, ...data };

    data.content = data?.sections.reduce((result, current) => {
      // const key = Object.keys(current)[0];
      result[`section${index}`] = current;
      index++;
      return result;
    }, {});

    dispatch(editPage({ slug: "cookies-policy", data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="cookiesPolicy" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="form-group ">
                <Col lg={12}>
                  <SliderArray
                    sliders={sliders}
                    setSliders={setSliders}
                    control={control}
                  />
                </Col>
              </Row>

              <SaveChangesButton />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
