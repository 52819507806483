import server from "./server";

export const getPackagesApi = async () => {
  const response = await server().get("/packages");
  return response.data;
};

export const getSinglePackageApi = async (id) => {
  const response = await server().get(`/packages/${id}`);
  return response.data;
};

export const addPackageApi = async (payload) => {
  const response = await server().post("/packages", payload);
  return response.data;
};

export const editPackageApi = async ({ id, data }) => {
  const response = await server().put(`/packages/${id}`, data);
  return response.data;
};

export const deletePackageApi = async (id) => {
  const response = await server().delete(`/packages/${id}`);
  return response.data;
};
