import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getControlDomains } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const ControlDomains = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { controlDomains } = useSelector((state) => state.controlDomains);
  useEffect(() => {
    dispatch(getControlDomains());
  }, [dispatch]);

  return (
    <>
      <div className="controlDomains-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addControlDomain" })}
          cardTxt={formatMessage({ id: "controlDomains" })}
          icon={peopleIcon}
          url="/controlDomains/add"
        />
        <Table data={controlDomains} />
      </div>
    </>
  );
};

export default ControlDomains;
