import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getSectionsApi,
  addSectionApi,
  editSectionApi,
  deleteSectionApi,
  getSingleSectionApi,
} from "api/sections";

// Login Redux States
import {
  ADD_SECTION,
  DELETE_SECTION,
  EDIT_SECTION,
  GET_SINGLE_SECTION,
  GET_SECTIONS,
} from "./actionTypes";
import {
  addSectionFailure,
  addSectionSuccess,
  getSectionsSuccess,
  getSectionsFailure,
  editSectionSuccess,
  editSectionFailure,
  deleteSectionSuccess,
  deleteSectionFailure,
  getSingleSectionSuccess,
  getSingleSectionFailure,
} from "./actions";

function* getSections({ payload }) {
  try {
    const { data } = yield call(getSectionsApi, payload);
    yield put(getSectionsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSectionsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleSection({ payload }) {
  try {
    const { data } = yield call(getSingleSectionApi, payload);
    yield put(getSingleSectionSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleSectionFailure(error?.response?.data || error));
  }
}

function* addSection({ payload }) {
  try {
    const { data } = yield call(addSectionApi, payload);
    yield put(addSectionSuccess(data));
    if (payload.callBack) payload.callBack();
    // yield payload.navigate("/sections");
  } catch (error) {
    console.log(error);
    yield put(addSectionFailure(error.response?.data?.message || error));
  }
}

function* editSection({ payload }) {
  try {
    const { data } = yield call(editSectionApi, payload);
    yield put(editSectionSuccess(data));
    if (payload.callBack) payload.callBack();
    // yield payload.navigate("/sections");
  } catch (error) {
    console.log(error);
    yield put(editSectionFailure(error.response?.data?.message || error));
  }
}

function* deleteSection({ payload }) {
  try {
    yield call(deleteSectionApi, payload?.id || payload);
    yield put(deleteSectionSuccess(payload?.id || payload));
    if (payload?.callBack) payload?.callBack();
  } catch (error) {
    console.log(error);
    yield put(deleteSectionFailure(error?.response?.data || error));
  }
}

export function* watchGetSections() {
  yield takeEvery(GET_SECTIONS, getSections);
}

export function* watchSingleSection() {
  yield takeEvery(GET_SINGLE_SECTION, getSingleSection);
}

export function* watchAddSection() {
  yield takeEvery(ADD_SECTION, addSection);
}

export function* watchEditSection() {
  yield takeEvery(EDIT_SECTION, editSection);
}

export function* watchDeleteSection() {
  yield takeEvery(DELETE_SECTION, deleteSection);
}

function* SectionsSaga() {
  yield all([fork(watchGetSections)]);
  yield all([fork(watchSingleSection)]);
  yield all([fork(watchAddSection)]);
  yield all([fork(watchEditSection)]);
  yield all([fork(watchDeleteSection)]);
}

export default SectionsSaga;
