import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editQuestion,
  getCategories,
  getControlDomains,
  getControlReferences,
  getOrganizationTypes,
  getSectors,
  getSingleQuestion,
  getSubControlDomains,
} from "store/actions";

import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { Editor } from "components/shared/Editor";
import ReactSelect from "react-select";
import Sections from "./sections/Index";
import StickyLoader from "components/shared/StickyLoader";

const EditQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { locale } = useIntl();

  const { singleQuestion, loading } = useSelector((state) => state?.questions);
  const { categories, loading: loading1 } = useSelector(
    (state) => state?.categories
  );
  const { controlReferences, loading: loading2 } = useSelector(
    (state) => state?.controlReferences
  );
  const { organizationTypes, loading: loading3 } = useSelector(
    (state) => state?.organizationTypes
  );
  const { controlDomains, loading: loading4 } = useSelector(
    (state) => state?.controlDomains
  );
  const { subControlDomains, loading: loading5 } = useSelector(
    (state) => state?.subControlDomains
  );
  const { sectors, loading: loading6 } = useSelector((state) => state?.sectors);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getSingleQuestion(id));
    if (categories?.length === 0) dispatch(getCategories());

    if (sectors?.length === 0) dispatch(getSectors());
    if (subControlDomains?.length === 0) dispatch(getSubControlDomains());
    if (controlDomains?.length === 0) dispatch(getControlDomains());
    if (controlReferences?.length === 0) dispatch(getControlReferences());
    if (organizationTypes?.length === 0) dispatch(getOrganizationTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singleQuestion?.title,
      description: singleQuestion?.description,
      category: getId(singleQuestion?.category),

      organizationtype: getId(singleQuestion?.organizationtype),

      controlreference: getId(singleQuestion?.controlreference),
      subcontroldomain: getId(
        singleQuestion?.controlreference?.subControlDomain
      ),
      controldomain: getId(
        singleQuestion?.controlreference?.subControlDomain?.controlDomain
      ),
      sector: getId(singleQuestion?.sector),
    });
  }, [reset, singleQuestion]);

  const onSubmit = (data) => {
    dispatch(editQuestion({ id, data, navigate }));
  };

  const controlDomainsOptions = controlDomains?.map((item) => ({
    label: item?.title?.[locale],
    value: getId(item),
  }));
  const subControlDomainsOptions = subControlDomains
    ?.filter((item) => getId(item?.controlDomain) === watch("controldomain"))
    ?.map((item) => ({
      label: item?.title?.[locale],
      value: getId(item),
    }));
  const controlReferencesOptions = controlReferences
    ?.filter(
      (item) => getId(item?.subControlDomain) === watch("subcontroldomain")
    )
    ?.map((item) => ({
      label: item?.title?.[locale],
      value: getId(item),
    }));

  // if (loading1 || loading2 || loading3 || loading4 || loading5 || loading6)
  //   return <Loader />;

  return (
    <>
      {(loading1 ||
        loading2 ||
        loading3 ||
        loading4 ||
        loading5 ||
        loading6) && <StickyLoader fill="#e9deff" />}

      <h5 className="mb-3">
        <Link to="/questions">
          <h5 className="text-info">
            <FormattedMessage id="questions" />
          </h5>
        </Link>
        {" > "}
        <FormattedMessage id="editQuestion" />
      </h5>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editQuestion" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xxl={8} md={12}>
                <Row>
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  {/* <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"description"} />
                        </label>
                      }
                      errors={errors?.description}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <div className={`${classes}`}>
                          <Controller
                            key={key}
                            control={control}
                            name={`description.${lang}`}
                            render={({ field: { value, onChange } }) => (
                              <Editor
                                type="text"
                                key={key}
                                initialValue={value}
                                className={`form-control form-outline`}
                                onChange={(_, editor) => {
                                  const data = editor.getContent();
                                  onChange(data);
                                }}
                                init={{
                                  directionality: lang === "ar" ? "rtl" : "ltr",
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </MultiLangWrapper>
                  </Col> */}

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="category" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="category"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={categories?.map((item) => ({
                                label: item?.title?.[locale],
                                value: getId(item),
                              }))}
                              value={categories
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find(({ value }) => valuee === value)}
                              isMulti={false}
                              onChange={(value) => {
                                onChange(getId(value));
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col md="4" sm={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="controlDomain" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="controldomain"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={controlDomainsOptions}
                              value={controlDomainsOptions?.find(
                                ({ value }) => valuee === value
                              )}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="4" sm={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="subControlDomain" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="subcontroldomain"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={subControlDomainsOptions}
                              value={subControlDomainsOptions?.find(
                                ({ value }) => valuee === value
                              )}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="4" sm={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="controlReference" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="controlreference"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={controlReferencesOptions}
                              value={controlReferencesOptions?.find(
                                ({ value }) => valuee === value
                              )}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="sector" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="sector"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={sectors?.map((item) => ({
                                label: item?.title?.[locale],
                                value: getId(item),
                              }))}
                              value={sectors
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find(({ value }) => valuee === value)}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="organizationType" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="organizationtype"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={organizationTypes?.map((item) => ({
                                label: item?.title?.[locale],
                                value: getId(item),
                              }))}
                              value={organizationTypes
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find(({ value }) => valuee === value)}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="save" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
            <Sections data={singleQuestion?.sections} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditQuestion;
