import server from "./server";

export const getBanksApi = async () => {
  const response = await server().get("/banks");
  return response.data;
};

export const getSingleBankApi = async (id) => {
  const response = await server().get(`/banks/${id}`);
  return response.data;
};

export const addBankApi = async (payload) => {
  const response = await server().post("/banks", payload);
  return response.data;
};

export const editBankApi = async ({ id, data }) => {
  const response = await server().put(`/banks/${id}`, data);
  return response.data;
};

export const deleteBankApi = async (id) => {
  const response = await server().delete(`/banks/${id}`);
  return response.data;
};
