export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";
export const GET_SINGLE_PAYMENT_METHOD = "GET_SINGLE_PAYMENT_METHOD";
export const GET_SINGLE_PAYMENT_METHOD_SUCCESS =
  "GET_SINGLE_PAYMENT_METHOD_SUCCESS";
export const GET_SINGLE_PAYMENT_METHOD_FAILURE =
  "GET_SINGLE_PAYMENT_METHOD_FAILURE";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAILURE = "ADD_PAYMENT_METHOD_FAILURE";
export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD";
export const EDIT_PAYMENT_METHOD_SUCCESS = "EDIT_PAYMENT_METHOD_SUCCESS";
export const EDIT_PAYMENT_METHOD_FAILURE = "EDIT_PAYMENT_METHOD_FAILURE";
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_FAILURE = "DELETE_PAYMENT_METHOD_FAILURE";
