import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCTS,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_FAILURE,
  UPDATE_PRODUCT_MAIN_DATA,
  UPDATE_PRODUCT_MAIN_DATA_SUCCESS,
  UPDATE_PRODUCT_MAIN_DATA_FAILURE,
  ADD_FAKE_PRODUCT,
  ADD_FAKE_PRODUCT_SUCCESS,
  ADD_FAKE_PRODUCT_FAILURE,
  DELETE_EMPTY_PRODUCT,
  DELETE_EMPTY_PRODUCT_SUCCESS,
  DELETE_EMPTY_PRODUCT_FAILURE,
  GET_VARIATIONS,
  GET_VARIATIONS_SUCCESS,
  GET_VARIATIONS_FAILURE,
  GET_VARIATIONS_STYLES,
  GET_VARIATIONS_STYLES_SUCCESS,
  GET_VARIATIONS_STYLES_FAILURE,
} from "./actionTypes";

export const getProducts = (payload) => {
  return {
    type: GET_PRODUCTS,
    payload,
  };
};

export const getProductsSuccess = (products) => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
  };
};

export const getProductsFailure = (error) => {
  return {
    type: GET_PRODUCTS_FAILURE,
    payload: error,
  };
};

export const getSingleProduct = (id) => {
  return {
    type: GET_SINGLE_PRODUCT,
    payload: id,
  };
};

export const getSingleProductSuccess = (product) => {
  return {
    type: GET_SINGLE_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const getSingleProductFailure = (error) => {
  return {
    type: GET_SINGLE_PRODUCT_FAILURE,
    payload: error,
  };
};

export const addProduct = (payload) => {
  return {
    type: ADD_PRODUCT,
    payload,
  };
};

export const addProductSuccess = (payload, index) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload,
    index,
  };
};

export const addProductFailure = (error) => {
  return {
    type: ADD_PRODUCT_FAILURE,
    payload: error,
  };
};

export const editProduct = (payload) => {
  return {
    type: EDIT_PRODUCT,
    payload,
  };
};

export const editProductSuccess = (payload) => {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload,
  };
};

export const editProductFailure = (error) => {
  return {
    type: EDIT_PRODUCT_FAILURE,
    payload: error,
  };
};
//--deleting a product
export const deleteProduct = (id) => {
  return {
    type: DELETE_PRODUCT,
    payload: id,
  };
};

export const deleteProductSuccess = (id) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: id,
  };
};

export const deleteProductFailure = (error) => {
  return {
    type: DELETE_PRODUCT_FAILURE,
    payload: error,
  };
};
//--deleting products
export const deleteProducts = (id) => {
  return {
    type: DELETE_PRODUCTS,
    payload: id,
  };
};

export const deleteProductsSuccess = (id) => {
  return {
    type: DELETE_PRODUCTS_SUCCESS,
    payload: id,
  };
};

export const deleteProductsFailure = (error) => {
  return {
    type: DELETE_PRODUCTS_FAILURE,
    payload: error,
  };
};

export const updateProductMainData = (payload) => {
  return {
    type: UPDATE_PRODUCT_MAIN_DATA,
    payload,
  };
};

export const updateProductMainDataSuccess = (payload) => {
  return {
    type: UPDATE_PRODUCT_MAIN_DATA_SUCCESS,
    payload,
  };
};

export const updateProductMainDataFailure = (error) => {
  return {
    type: UPDATE_PRODUCT_MAIN_DATA_FAILURE,
    payload: error,
  };
};

export const addFakeProduct = (payload) => {
  return {
    type: ADD_FAKE_PRODUCT,
    payload,
  };
};

export const addFakeProductSuccess = (payload) => {
  return {
    type: ADD_FAKE_PRODUCT_SUCCESS,
    payload,
  };
};

export const addFakeProductFailure = (error) => {
  return {
    type: ADD_FAKE_PRODUCT_FAILURE,
    payload: error,
  };
};

export const deleteEmptyProduct = (id) => {
  return {
    type: DELETE_EMPTY_PRODUCT,
    payload: id,
  };
};

export const deleteEmptyProductSuccess = (id) => {
  return {
    type: DELETE_EMPTY_PRODUCT_SUCCESS,
    payload: id,
  };
};

export const deleteEmptyProductFailure = (error) => {
  return {
    type: DELETE_EMPTY_PRODUCT_FAILURE,
    payload: error,
  };
};
//--get variations data

export const getProductsVariations = (id) => {
  return {
    type: GET_VARIATIONS,
    payload: id,
  };
};

export const getProductsVariationsSuccess = (id) => {
  return {
    type: GET_VARIATIONS_SUCCESS,
    payload: id,
  };
};

export const getProductsVariationsFailure = (error) => {
  return {
    type: GET_VARIATIONS_FAILURE,
    payload: error,
  };
};
//--get variations data

export const getProductsVariationsStyles = (id) => {
  return {
    type: GET_VARIATIONS_STYLES,
    payload: id,
  };
};

export const getProductsVariationsStylesSuccess = (id) => {
  return {
    type: GET_VARIATIONS_STYLES_SUCCESS,
    payload: id,
  };
};

export const getProductsVariationsStylesFailure = (error) => {
  return {
    type: GET_VARIATIONS_STYLES_FAILURE,
    payload: error,
  };
};
