import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getNotificationsApi } from "api/notifications";

// Login Redux States
import { getNotificationsFailure, getNotificationsSuccess } from "./actions";
import { GET_NOTIFICATIONS } from "./actionTypes";

function* getNotifications() {
  try {
    const { data } = yield call(getNotificationsApi);
    yield put(getNotificationsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getNotificationsFailure(error?.response?.data.errors[0].msg));
  }
}

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
}

function* notificationsSaga() {
  yield all([fork(watchGetNotifications)]);
}

export default notificationsSaga;
