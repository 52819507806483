import store from "store";

export const checkPermissions = (permissions) => {
  if (!permissions) return true;
  const currentUserPermissions =
    store.getState().authentication?.user?.role?.permissions;
  const found = currentUserPermissions?.some(
    (r) => permissions?.indexOf(r) >= 0 || permissions?.length === 0
  );
  return found;
};
