import server from "./server";

export const getOrdersApi = async (searchParams) => {
  const response = await server().get(`/orders${searchParams || ""}`);
  return response.data;
};

export const getSingleOrderApi = async (id) => {
  const response = await server().get(`/orders/${id}`);
  return response.data;
};

export const changeOrderStatusApi = async ({ id, status }) => {
  const response = await server().post(`/orders/changeStatus/${id}`, {
    status,
  });
  return response.data;
};
