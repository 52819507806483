import {
  GET_STORES,
  GET_STORES_FAILURE,
  GET_STORES_SUCCESS,
  GET_SINGLE_STORE,
  GET_SINGLE_STORE_SUCCESS,
  GET_SINGLE_STORE_FAILURE,
  ADD_STORE,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAILURE,
  EDIT_STORE,
  EDIT_STORE_SUCCESS,
  EDIT_STORE_FAILURE,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAILURE,
  GET_ACTIVE_STORE,
  GET_ACTIVE_STORE_SUCCESS,
  GET_ACTIVE_STORE_FAILURE,
  SET_ACTIVE_THEME,
  SET_ACTIVE_THEME_SUCCESS,
  SET_ACTIVE_THEME_FAILURE,
  ADD_THEME_TO_STORE,
  ADD_THEME_TO_STORE_SUCCESS,
  ADD_THEME_TO_STORE_FAILURE,
  CHANGE_STORE_STATUS,
  CHANGE_STORE_STATUS_SUCCESS,
  CHANGE_STORE_STATUS_FAILURE,
  EDIT_STORE_THEME,
  EDIT_STORE_THEME_SUCCESS,
  EDIT_STORE_THEME_FAILURE,
} from "./actionTypes";

export const getStores = () => {
  return {
    type: GET_STORES,
  };
};

export const getStoresSuccess = (stores) => {
  return {
    type: GET_STORES_SUCCESS,
    payload: stores,
  };
};

export const getStoresFailure = (error) => {
  return {
    type: GET_STORES_FAILURE,
    payload: error,
  };
};

export const getSingleStore = (payload) => {
  return {
    type: GET_SINGLE_STORE,
    payload,
  };
};

export const getSingleStoreSuccess = (store) => {
  return {
    type: GET_SINGLE_STORE_SUCCESS,
    payload: store,
  };
};

export const getSingleStoreFailure = (error) => {
  return {
    type: GET_SINGLE_STORE_FAILURE,
    payload: error,
  };
};

export const getActiveStore = (payload) => {
  return {
    type: GET_ACTIVE_STORE,
    payload,
  };
};

export const getActiveStoreSuccess = (store) => {
  return {
    type: GET_ACTIVE_STORE_SUCCESS,
    payload: store,
  };
};

export const getActiveStoreFailure = (error) => {
  return {
    type: GET_ACTIVE_STORE_FAILURE,
    payload: error,
  };
};

export const setActiveTheme = (payload) => {
  return {
    type: SET_ACTIVE_THEME,
    payload,
  };
};

export const setActiveThemeSuccess = (theme) => {
  return {
    type: SET_ACTIVE_THEME_SUCCESS,
    payload: theme,
  };
};

export const setActiveThemeFailure = (error) => {
  return {
    type: SET_ACTIVE_THEME_FAILURE,
    payload: error,
  };
};

export const addThemeToStore = (payload) => {
  return {
    type: ADD_THEME_TO_STORE,
    payload,
  };
};

export const addThemeToStoreSuccess = (theme) => {
  return {
    type: ADD_THEME_TO_STORE_SUCCESS,
    payload: theme,
  };
};

export const addThemeToStoreFailure = (error) => {
  return {
    type: ADD_THEME_TO_STORE_FAILURE,
    payload: error,
  };
};

export const addStore = (payload) => {
  return {
    type: ADD_STORE,
    payload,
  };
};

export const addStoreSuccess = (payload) => {
  return {
    type: ADD_STORE_SUCCESS,
    payload,
  };
};

export const addStoreFailure = (error) => {
  return {
    type: ADD_STORE_FAILURE,
    payload: error,
  };
};

export const editStore = (payload) => {
  return {
    type: EDIT_STORE,
    payload,
  };
};

export const editStoreSuccess = (payload) => {
  return {
    type: EDIT_STORE_SUCCESS,
    payload,
  };
};

export const editStoreFailure = (error) => {
  return {
    type: EDIT_STORE_FAILURE,
    payload: error,
  };
};

export const deleteStore = (id) => {
  return {
    type: DELETE_STORE,
    payload: id,
  };
};

export const deleteStoreSuccess = (id) => {
  return {
    type: DELETE_STORE_SUCCESS,
    payload: id,
  };
};

export const deleteStoreFailure = (error) => {
  return {
    type: DELETE_STORE_FAILURE,
    payload: error,
  };
};

export const changeStoreStatus = (id) => {
  return {
    type: CHANGE_STORE_STATUS,
    payload: id,
  };
};

export const changeStoreStatusSuccess = (id) => {
  return {
    type: CHANGE_STORE_STATUS_SUCCESS,
    payload: id,
  };
};

export const changeStoreStatusFailure = (error) => {
  return {
    type: CHANGE_STORE_STATUS_FAILURE,
    payload: error,
  };
};

export const editStoreTheme = (payload) => {
  return {
    type: EDIT_STORE_THEME,
    payload,
  };
};

export const editStoreThemeSuccess = (id) => {
  return {
    type: EDIT_STORE_THEME_SUCCESS,
    payload: id,
  };
};

export const editStoreThemeFailure = (error) => {
  return {
    type: EDIT_STORE_THEME_FAILURE,
    payload: error,
  };
};
