import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getSubControlDomainsApi,
  addSubControlDomainApi,
  editSubControlDomainApi,
  deleteSubControlDomainApi,
  getSingleSubControlDomainApi,
} from "api/subControlDomains";

// Login Redux States
import {
  ADD_SUBCONTROL_DOMAIN,
  DELETE_SUBCONTROL_DOMAIN,
  EDIT_SUBCONTROL_DOMAIN,
  GET_SINGLE_SUBCONTROL_DOMAIN,
  GET_SUBCONTROL_DOMAINS,
} from "./actionTypes";
import {
  addSubControlDomainFailure,
  addSubControlDomainSuccess,
  getSubControlDomainsSuccess,
  getSubControlDomainsFailure,
  editSubControlDomainSuccess,
  editSubControlDomainFailure,
  deleteSubControlDomainSuccess,
  deleteSubControlDomainFailure,
  getSingleSubControlDomainSuccess,
  getSingleSubControlDomainFailure,
} from "./actions";

function* getSubControlDomains() {
  try {
    const { data } = yield call(getSubControlDomainsApi);
    yield put(getSubControlDomainsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSubControlDomainsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleSubControlDomain({ payload }) {
  try {
    const { data } = yield call(getSingleSubControlDomainApi, payload);
    yield put(getSingleSubControlDomainSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleSubControlDomainFailure(error?.response?.data || error));
  }
}

function* addSubControlDomain({ payload }) {
  try {
    const { data } = yield call(addSubControlDomainApi, payload.data);
    yield put(addSubControlDomainSuccess(data));
    yield payload.navigate("/subControlDomains");
  } catch (error) {
    console.log(error);
    yield put(
      addSubControlDomainFailure(error.response?.data?.message || error)
    );
  }
}

function* editSubControlDomain({ payload }) {
  try {
    const { data } = yield call(editSubControlDomainApi, payload);
    yield put(editSubControlDomainSuccess(data));
    yield payload.navigate("/subControlDomains");
  } catch (error) {
    console.log(error);
    yield put(
      editSubControlDomainFailure(error.response?.data?.message || error)
    );
  }
}

function* deleteSubControlDomain({ payload }) {
  try {
    yield call(deleteSubControlDomainApi, payload);
    yield put(deleteSubControlDomainSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteSubControlDomainFailure(error?.response?.data || error));
  }
}

export function* watchGetSubControlDomains() {
  yield takeEvery(GET_SUBCONTROL_DOMAINS, getSubControlDomains);
}

export function* watchSingleSubControlDomain() {
  yield takeEvery(GET_SINGLE_SUBCONTROL_DOMAIN, getSingleSubControlDomain);
}

export function* watchAddSubControlDomain() {
  yield takeEvery(ADD_SUBCONTROL_DOMAIN, addSubControlDomain);
}

export function* watchEditSubControlDomain() {
  yield takeEvery(EDIT_SUBCONTROL_DOMAIN, editSubControlDomain);
}

export function* watchDeleteSubControlDomain() {
  yield takeEvery(DELETE_SUBCONTROL_DOMAIN, deleteSubControlDomain);
}

function* SubControlDomainsSaga() {
  yield all([fork(watchGetSubControlDomains)]);
  yield all([fork(watchSingleSubControlDomain)]);
  yield all([fork(watchAddSubControlDomain)]);
  yield all([fork(watchEditSubControlDomain)]);
  yield all([fork(watchDeleteSubControlDomain)]);
}

export default SubControlDomainsSaga;
