export const handleImage = (image = "") => {
  if (image && typeof image === "string") {
    if (image?.includes("https://") || image?.includes("http://")) return image;
    if (!image.startsWith("/")) image = `/${image}`;
    return `${`${process.env.REACT_APP_API_URL}`?.replace("/api", "")}${image}`;
  }

  // return require("../assets/images/placeholder-image.png")?.default;
  return image;
};

export const checkIfUserHasPermission = (
  userPermissions = [],
  testedPermission
) => {
  if (userPermissions.length === 0) return false;
  const hasPermission = userPermissions.includes(testedPermission);
  return hasPermission;
};

export const handleSearchParamsChange = (search, params = {}) => {
  const url = new URLSearchParams("");
  search = { ...search, ...params };
  Object.entries(search).map(([key, value]) => {
    return url.set(key, value);
  });
  return "?" + url;
};

export const themeSwitcherFunction = () => {
  document.querySelector(".darkSwitcher")?.classList.toggle("fire");
  document.querySelector("body")?.classList.toggle("dark");
};

export const sidebarToggleFunction = () => {
  document.querySelector("body")?.classList.remove("sidebarToggled");
  document.querySelector(".overlay-s")?.classList.remove("fire");
  document.querySelector("html")?.classList.remove("offScroll");
};

export const handleIsoDate = (isoDate) => {
  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month}-${day}`;
};

export const getOptionsFrom = (data, locale) =>
  data?.length > 0
    ? data?.map((c) => ({
        label: c?.name?.[locale] || c?.name,
        value: getId(c),
      }))
    : {
        label: data?.name?.[locale] || data?.name,
        value: getId(data),
      };

export const getId = (obj) => obj?._id || obj?.id;

export const validateKeysValues = (obj, values = false) => {
  let res = Object.keys(obj)?.map((key) => obj?.[key]?.length > 0);

  if (values)
    res = values?.map(({ language: { locale } }) => obj?.[locale]?.length > 0);

  res = res?.find((e) => !e);
  if (res === undefined) return true;

  return res;
};

export const getSettingsBySlug = (settings, slug) => {
  return settings.find((setting) => {
    return setting._id === slug;
  });
};

export const imageTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/jpg",
  "image/webp",
];
export const videoTypes = ["video/mp4", "video/mov", "video/avi"];

export const checkFileType = (file) => {
  let type = "img";

  // Check MIME type
  if (imageTypes.includes(file?.type)) {
  } else if (videoTypes.includes(file?.type)) {
    type = "video";
  } else {
    // Check file extension
    const extension = file?.split(".").pop().toLowerCase();
    if (extension === "jpg" || extension === "png" || extension === "gif") {
    } else if (
      extension === "mp4" ||
      extension === "mov" ||
      extension === "avi"
    ) {
      type = "video";
    } else {
      console.log("File is neither an image nor a video");
    }
  }

  return type;
};

export const formatCurrency = (num) =>
  new Intl.NumberFormat("en-US", {}).format(Number(num || "0")?.toFixed(2));
