import { getId } from "helpers/functions";
import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_SUCCESS,
  ADD_PERMISSION,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  GET_SINGLE_PERMISSION,
  GET_SINGLE_PERMISSION_FAILURE,
  GET_SINGLE_PERMISSION_SUCCESS,
} from "./actionTypes";

const initialState = {
  permissions: [],
  loading: false,
  error: "",
  singlePermission: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissions: action.payload.permissions,
      };
      break;
    case GET_PERMISSIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PERMISSION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_SINGLE_PERMISSION_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePermission: action.payload.permission,
      };
      break;
    case GET_SINGLE_PERMISSION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_PERMISSION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case ADD_PERMISSION_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissions: action.payload.permissions,
      };
      break;
    case ADD_PERMISSION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PERMISSION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case DELETE_PERMISSION_SUCCESS:
      const permissionsAfterDeleteing = [
        ...state?.permissions?.filter((user) => getId(user) !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        permissions: permissionsAfterDeleteing,
      };
      break;
    case DELETE_PERMISSION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
