import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getProductTypesApi } from "api/productTypes";

// Login Redux States
import { GET_PRODUCT_TYPES } from "./actionTypes";
import { getProductTypesFailure, getProductTypesSuccess } from "./actions";

function* getProductTypes() {
  try {
    const { data } = yield call(getProductTypesApi);
    yield put(getProductTypesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getProductTypesFailure(error?.response?.data.errors[0].msg));
  }
}

export function* watchProductTypes() {
  yield takeEvery(GET_PRODUCT_TYPES, getProductTypes);
}

function* productTypesSaga() {
  yield all([fork(watchProductTypes)]);
}

export default productTypesSaga;
