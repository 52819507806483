export const GET_CONTROL_DOMAINS = "GET_CONTROL_DOMAINS";
export const GET_CONTROL_DOMAINS_SUCCESS = "GET_CONTROL_DOMAINS_SUCCESS";
export const GET_CONTROL_DOMAINS_FAILURE = "GET_CONTROL_DOMAINS_FAILURE";

export const GET_SINGLE_CONTROL_DOMAIN = "GET_SINGLE_CONTROL_DOMAIN";
export const GET_SINGLE_CONTROL_DOMAIN_SUCCESS =
  "GET_SINGLE_CONTROL_DOMAIN_SUCCESS";
export const GET_SINGLE_CONTROL_DOMAIN_FAILURE =
  "GET_SINGLE_CONTROL_DOMAIN_FAILURE";

export const ADD_CONTROL_DOMAIN = "ADD_CONTROL_DOMAIN";
export const ADD_CONTROL_DOMAIN_SUCCESS = "ADD_CONTROL_DOMAIN_SUCCESS";
export const ADD_CONTROL_DOMAIN_FAILURE = "ADD_CONTROL_DOMAIN_FAILURE";

export const EDIT_CONTROL_DOMAIN = "EDIT_CONTROL_DOMAIN";
export const EDIT_CONTROL_DOMAIN_SUCCESS = "EDIT_CONTROL_DOMAIN_SUCCESS";
export const EDIT_CONTROL_DOMAIN_FAILURE = "EDIT_CONTROL_DOMAIN_FAILURE";

export const DELETE_CONTROL_DOMAIN = "DELETE_CONTROL_DOMAIN";
export const DELETE_CONTROL_DOMAIN_SUCCESS = "DELETE_CONTROL_DOMAIN_SUCCESS";
export const DELETE_CONTROL_DOMAIN_FAILURE = "DELETE_CONTROL_DOMAIN_FAILURE";

export const SET_DEFAULT_CONTROL_DOMAIN_SUCCESS =
  "SET_DEFAULT_CONTROL_DOMAIN_SUCCESS";
