import server from "./server";

export const getPermissionsApi = async () => {
  const response = await server().get("/permissions");
  return response.data;
};

export const getSinglePermissionApi = async (id) => {
  const response = await server().get(`/permissions/${id}`);
  return response.data;
};

export const addPermissionApi = async (payload) => {
  const response = await server().post("/permissions", payload);
  return response.data;
};

export const editPermissionApi = async ({ id, data }) => {
  const response = await server().put(`/permissions/${id}`, data);
  return response.data;
};

export const deletePermissionApi = async (id) => {
  const response = await server().delete(`/permissions/${id}`);
  return response.data;
};
