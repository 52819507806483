import { getId } from "helpers/functions";
import {
  GET_ROLES,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_SINGLE_ROLE,
  GET_SINGLE_ROLE_FAILURE,
  GET_SINGLE_ROLE_SUCCESS,
} from "./actionTypes";

const initialState = {
  roles: [],
  loading: false,
  error: "",
  singleRole: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_ROLES_SUCCESS:
      state = {
        ...state,
        loading: false,
        roles: action.payload.roles,
      };
      break;
    case GET_ROLES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_ROLE:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_SINGLE_ROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleRole: action.payload.role,
      };
      break;
    case GET_SINGLE_ROLE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_ROLE:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case ADD_ROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        roles: action.payload.roles,
      };
      break;
    case ADD_ROLE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_ROLE:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case DELETE_ROLE_SUCCESS:
      const rolesAfterDeleteing = [
        ...state?.roles?.filter((user) => getId(user) !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        roles: rolesAfterDeleteing,
      };
      break;
    case DELETE_ROLE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
