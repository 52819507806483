export const GET_SUBCONTROL_DOMAINS = "GET_SUBCONTROL_DOMAINS";
export const GET_SUBCONTROL_DOMAINS_SUCCESS = "GET_SUBCONTROL_DOMAINS_SUCCESS";
export const GET_SUBCONTROL_DOMAINS_FAILURE = "GET_SUBCONTROL_DOMAINS_FAILURE";

export const GET_SINGLE_SUBCONTROL_DOMAIN = "GET_SINGLE_SUBCONTROL_DOMAIN";
export const GET_SINGLE_SUBCONTROL_DOMAIN_SUCCESS =
  "GET_SINGLE_SUBCONTROL_DOMAIN_SUCCESS";
export const GET_SINGLE_SUBCONTROL_DOMAIN_FAILURE =
  "GET_SINGLE_SUBCONTROL_DOMAIN_FAILURE";

export const ADD_SUBCONTROL_DOMAIN = "ADD_SUBCONTROL_DOMAIN";
export const ADD_SUBCONTROL_DOMAIN_SUCCESS = "ADD_SUBCONTROL_DOMAIN_SUCCESS";
export const ADD_SUBCONTROL_DOMAIN_FAILURE = "ADD_SUBCONTROL_DOMAIN_FAILURE";

export const EDIT_SUBCONTROL_DOMAIN = "EDIT_SUBCONTROL_DOMAIN";
export const EDIT_SUBCONTROL_DOMAIN_SUCCESS = "EDIT_SUBCONTROL_DOMAIN_SUCCESS";
export const EDIT_SUBCONTROL_DOMAIN_FAILURE = "EDIT_SUBCONTROL_DOMAIN_FAILURE";

export const DELETE_SUBCONTROL_DOMAIN = "DELETE_SUBCONTROL_DOMAIN";
export const DELETE_SUBCONTROL_DOMAIN_SUCCESS =
  "DELETE_SUBCONTROL_DOMAIN_SUCCESS";
export const DELETE_SUBCONTROL_DOMAIN_FAILURE =
  "DELETE_SUBCONTROL_DOMAIN_FAILURE";

export const SET_DEFAULT_SUBCONTROL_DOMAIN_SUCCESS =
  "SET_DEFAULT_SUBCONTROL_DOMAIN_SUCCESS";
