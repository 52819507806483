import React, { useState, useCallback } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { deleteSection, getSingleQuestion } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import { ReactComponent as EyeIcon } from "assets/svgs/Setting.svg";
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";

import "styles/questions.css";
import Swal from "sweetalert2";
import EditSection from "./Edit";

// import { deleteReport } from "../../store/actions";
const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const { id: questionId } = useParams();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const SubHeaderComponent = useCallback(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleShowSection = (id) => {
    navigate(`/questions/${questionId}/sections/show/${id}`);
  };

  const handleEditSection = (id) => {
    navigate(`/questions/${questionId}/sections/${id}`);
  };

  const handleDeleteSection = (id) => {
    Swal.fire({
      title: `${formatMessage({ id: "delete" })} ${formatMessage({
        id: "section",
      })}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b659e",
      cancelButtonColor: "#cc4949",
      confirmButtonText: formatMessage({ id: "yes" }),
      cancelButtonText: formatMessage({ id: "no" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteSection({
            id,
            callBack: () => dispatch(getSingleQuestion(questionId)),
          })
        );
      }
    });
  };

  const handleAddSection = () => {
    navigate(`/questions/${questionId}/sections/add`);
  };

  const renderCards = filteredItems?.map((item, index) => (
    <Col xs="12" key={index}>
      <div className="card">
        <div className="card-head mb-0">
          {/* <h5 title={item?.title?.[locale] || item?.title}>
            {item?.title?.[locale] || item?.title}
          </h5> */}
          <div className="actions">
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip">
                  {formatMessage({ id: "deleteSection" })}
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-danger rounded-pill"
                onClick={() => handleDeleteSection(getId(item))}
              >
                <DeleteIcon />
              </button>
            </OverlayTrigger> */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip">
                  {formatMessage({ id: "editSection" })}
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-info rounded-pill"
                onClick={() => handleEditSection(getId(item))}
              >
                <EditIcon />
              </button>
            </OverlayTrigger> */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="button-tooltip">
                  {formatMessage({ id: "viewSection" })}
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-success "
                onClick={() => handleShowSection(getId(item))}
              >
                <EyeIcon /> {formatMessage({ id: "viewSection" })}
              </button>
            </OverlayTrigger> */}
          </div>
        </div>
        <div className="card-body">
          <EditSection
            singleSection={item}
            handleShowSection={handleShowSection}
          />
          {/* <p
            dir="auto"
            dangerouslySetInnerHTML={{
              __html: item?.description?.[locale] || item?.description,
            }}
          ></p> */}
        </div>
      </div>
    </Col>
  ));

  return (
    <div className="sections-cards">
      <div className="heading d-flex justify-content-between align-items-center">
        <h5>
          <FormattedMessage id="qustionSections" />
        </h5>

        {/* <button
          type="button"
          title={formatMessage({ id: "addSection" })}
          className="btn btn-info rounded-pill"
          onClick={handleAddSection}
        >
          <PlusIcon /> {formatMessage({ id: "addSection" })}
        </button> */}
      </div>
      <div className="body row">{renderCards}</div>
    </div>
  );
};

export default Table;
