import { takeLatest, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addProductApi,
  deleteProductApi,
  editProductApi,
  getProductsApi,
  getProductsVariationsApi,
  getProductsVariationsStylesApi,
  getSingleProductApi,
  updateProductMainDataApi,
} from "api/products";

// Login Redux States
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCTS,
  GET_SINGLE_PRODUCT,
  UPDATE_PRODUCT_MAIN_DATA,
  ADD_FAKE_PRODUCT,
  DELETE_EMPTY_PRODUCT,
  GET_VARIATIONS,
  GET_VARIATIONS_STYLES,
  DELETE_PRODUCTS,
} from "./actionTypes";
import {
  addProductFailure,
  addProductSuccess,
  deleteProductFailure,
  deleteProductSuccess,
  editProductFailure,
  editProductSuccess,
  getProductsFailure,
  getProductsSuccess,
  getSingleProductFailure,
  getSingleProductSuccess,
  updateProductMainDataFailure,
  addFakeProductSuccess,
  addFakeProductFailure,
  updateProductMainDataSuccess,
  deleteEmptyProductSuccess,
  deleteEmptyProductFailure,
  getProductsVariationsSuccess,
  getProductsVariationsFailure,
  getProductsVariationsStylesSuccess,
  getProductsVariationsStylesFailure,
  deleteProductsSuccess,
  deleteProductsFailure,
} from "./actions";
import { handleSearchParamsChange } from "helpers/functions";

function* getProducts({ payload }) {
  try {
    const { data } = yield call(getProductsApi, payload);
    yield put(getProductsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getProductsFailure(error?.response?.data.errors[0].msg));
  }
}
function* getProductsVariations() {
  try {
    const { data } = yield call(getProductsVariationsApi);
    yield put(getProductsVariationsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getProductsVariationsFailure(error?.response?.data.errors[0].msg)
    );
  }
}
function* getProductsVariationsStyles() {
  try {
    const { data } = yield call(getProductsVariationsStylesApi);
    yield put(getProductsVariationsStylesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getProductsVariationsStylesFailure(error?.response?.data.errors[0].msg)
    );
  }
}

function* addProduct({ payload }) {
  try {
    const { data } = yield call(addProductApi, payload.data);
    yield put(addProductSuccess(data));
    // if (typeof payload?.callBack == "function") payload.callBack();
    payload?.navigate("/products");
  } catch (error) {
    console.log(error);
    yield put(addProductFailure(error));
  }
}

function* getSingleProduct({ payload: id }) {
  try {
    const { data } = yield call(getSingleProductApi, id);
    // if (data?.product) data.product.id = id;
    yield put(getSingleProductSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleProductFailure(error?.response?.data || error));
  }
}

function* editProduct({ payload }) {
  try {
    const { data } = yield call(editProductApi, payload);
    yield put(editProductSuccess(data));
    // if (typeof payload?.callBack == "function") payload.callBack();
    payload?.navigate("/products");
  } catch (error) {
    console.log(
      JSON.parse(JSON.stringify(error?.response?.data.errors[0].msg))
    );
    yield put(editProductFailure(error));
  }
}

function* deleteProduct({ payload }) {
  try {
    yield call(deleteProductApi, payload);
    yield put(deleteProductSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteProductFailure(error?.response?.data || error));
  }
}

function* deleteProducts({ payload: { products, searchParams } }) {
  try {
    for (let index in products) {
      yield call(deleteProductApi, products[index]);
    }
    yield put(deleteProductsSuccess(products));

    //renewing the products

    const { data } = yield call(
      getProductsApi,
      handleSearchParamsChange(searchParams)?.replace("?", "")
    );
    yield put(getProductsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(deleteProductsFailure(error?.response?.data || error));
  }
}

function* updateProductMainData({ payload }) {
  try {
    const { data } = yield call(updateProductMainDataApi, payload);
    yield put(updateProductMainDataSuccess(data.product));
  } catch (error) {
    console.log(error);
    yield put(updateProductMainDataFailure(error));
  }
}

function* addFakeProduct({ payload }) {
  try {
    yield put(addFakeProductSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(addFakeProductFailure(error));
  }
}

function* deleteEmptyProduct({ payload }) {
  try {
    yield put(deleteEmptyProductSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteEmptyProductFailure(error?.response?.data || error));
  }
}

export function* watchGetProducts() {
  yield takeLatest(GET_PRODUCTS, getProducts);
}

export function* watchAddProduct() {
  yield takeLatest(ADD_PRODUCT, addProduct);
}

export function* watchGetSingleProduct() {
  yield takeLatest(GET_SINGLE_PRODUCT, getSingleProduct);
}

export function* watchEditProduct() {
  yield takeLatest(EDIT_PRODUCT, editProduct);
}

export function* watchUpdateProductMainData() {
  yield takeLatest(UPDATE_PRODUCT_MAIN_DATA, updateProductMainData);
}

export function* watchDeleteProduct() {
  yield takeLatest(DELETE_PRODUCT, deleteProduct);
}
export function* watchDeleteProducts() {
  yield takeLatest(DELETE_PRODUCTS, deleteProducts);
}

export function* watchAddFakeProduct() {
  yield takeLatest(ADD_FAKE_PRODUCT, addFakeProduct);
}

export function* watchDeleteEmptyProduct() {
  yield takeLatest(DELETE_EMPTY_PRODUCT, deleteEmptyProduct);
}
export function* watchGetProductsVariations() {
  yield takeLatest(GET_VARIATIONS, getProductsVariations);
}
export function* watchGetProductsVariationsStyles() {
  yield takeLatest(GET_VARIATIONS_STYLES, getProductsVariationsStyles);
}

function* productsSaga() {
  yield all([fork(watchGetProducts)]);
  yield all([fork(watchAddProduct)]);
  yield all([fork(watchGetSingleProduct)]);
  yield all([fork(watchEditProduct)]);
  yield all([fork(watchDeleteProduct)]);
  yield all([fork(watchDeleteProducts)]);
  yield all([fork(watchUpdateProductMainData)]);
  yield all([fork(watchAddFakeProduct)]);
  yield all([fork(watchDeleteEmptyProduct)]);
  yield all([fork(watchGetProductsVariations)]);
  yield all([fork(watchGetProductsVariationsStyles)]);
}

export default productsSaga;
