import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { useFieldArray } from "react-hook-form";
import removeImg from "assets/svgs/close.svg";

const SlidersArray = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "features",
  });

  return (
    <div className="form-group branches sliders">
      <Row className="gap-2">
        <Col lg={12}>
          {/* <h5>
            <FormattedMessage id={"sliders"} />
          </h5> */}

          {fields.map((slider, index) => {
            return (
              <div key={index} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row className="justify-content-center">
                  <Col lg={12} xs={12}>
                    {/* <input
                      type="text"
                      className={`form-control form-outline mb-2`}
                      {...register(`features.${index}`, {})}
                    /> */}
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"feature"} /> {index + 1}
                        </label>
                      }
                      errors={errors?.features}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`features.${index}.${lang}`, {
                            required: true,
                          })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
      <Col lg={4} xs={12} className="text-left">
        <button
          type="button"
          className="btn btn-green add_new_section__ mt-2"
          onClick={() => append("")}
        >
          <FormattedMessage id={"addFeature"} />
        </button>
      </Col>
    </div>
  );
};

export default SlidersArray;
