import server from "./server";

export const getAdminsApi = async () => {
  const response = await server().get("/admins");
  return response.data;
};

export const getSingleAdminApi = async (id) => {
  const response = await server().get(`/admins/${id}`);
  return response.data;
};

export const addAdminApi = async (payload) => {
  const response = await server().post("/admins", payload);
  return response.data;
};

export const editAdminApi = async ({ id, data }) => {
  const response = await server().put(`/admins/${id}`, data);
  return response.data;
};

export const deleteAdminApi = async (id) => {
  const response = await server().delete(`/admins/${id}`);
  return response.data;
};
