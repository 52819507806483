import server from "./server";

export const getSubControlDomainsApi = async () => {
  const response = await server().get("/subControlDomains");
  return response.data;
};

export const getSingleSubControlDomainApi = async (id) => {
  const response = await server().get(`/subControlDomains/${id}`);
  return response.data;
};

export const addSubControlDomainApi = async (payload) => {
  const response = await server().post("/subControlDomains", payload);
  return response.data;
};

export const editSubControlDomainApi = async ({ id, data }) => {
  const response = await server().put(`/subControlDomains/${id}`, data);
  return response.data;
};

export const deleteSubControlDomainApi = async (id) => {
  const response = await server().delete(`/subControlDomains/${id}`);
  return response.data;
};
