export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAILURE = "GET_COUPONS_FAILURE";

export const GET_SINGLE_COUPON = "GET_SINGLE_COUPON";
export const GET_SINGLE_COUPON_SUCCESS = "GET_SINGLE_COUPON_SUCCESS";
export const GET_SINGLE_COUPON_FAILURE = "GET_SINGLE_COUPON_FAILURE";

export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAILURE = "ADD_COUPON_FAILURE";

export const EDIT_COUPON = "EDIT_COUPON";
export const EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS";
export const EDIT_COUPON_FAILURE = "EDIT_COUPON_FAILURE";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAILURE = "DELETE_COUPON_FAILURE";

export const ACTIVATE_COUPON = "ACTIVATE_COUPON";
export const ACTIVATE_COUPON_SUCCESS = "ACTIVATE_COUPON_SUCCESS";
export const ACTIVATE_COUPON_FAILURE = "ATIVATE_COUPON_FAILURE";
