import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Editor } from "components/shared/Editor";
import { ReactComponent as StockIcon } from "assets/svgs/product/3d-cube-scan.svg";

import {
  editBlog,
  getBlogCategories,
  getLanguages,
  getSingleBlog,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Loader from "components/shared/Loader";
import CreatableSelect from "react-select/creatable";
import { getId } from "helpers/functions";

const EditProduct = () => {
  const { locale, formatMessage } = useIntl();
  const dir = locale === "ar" ? "rtl" : "ltr";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const [selectedBanner, setSelectedBanner] = useState({
    preview: null,
    path: null,
  });
  const [description, setDescription] = useState({ ar: "", en: "" });
  const [tags, setTags] = useState([]);

  const { register, handleSubmit, reset, control, watch } = useForm();

  const { singleBlog, loading } = useSelector((state) => state.blogs);
  const { blogCategories } = useSelector((state) => state.blogCategories);
  const { languages } = useSelector((state) => state.languages);
  const { blogTags } = useSelector((state) => state.blogs) || {};

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getLanguages());
    dispatch(getSingleBlog(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      ...singleBlog,
      language: getId(singleBlog.language),
      category: getId(singleBlog.category),
    });
  }, [reset, singleBlog]);

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    data.image = "uploads/" + data.image.split("/").pop();

    if (selectedBanner && selectedBanner.path)
      data["banner"] = selectedBanner.path;
    data.banner = "uploads/" + data.banner.split("/").pop();

    data.description = description;
    data.tags = tags;

    delete data.relatedBlogs;

    dispatch(editBlog({ id, data, navigate }));
    reset({});
  };

  const renderCategoriesSelectOptions = () => {
    return blogCategories.map((category) => {
      return (
        <option value={getId(category)} key={getId(category)}>
          {category?.name?.[locale]}
        </option>
      );
    });
  };

  const renderLanguagesSelectOptions = () => {
    return languages.map((language, index) => {
      return (
        <option value={getId(language)} key={index}>
          {language?.name}
        </option>
      );
    });
  };

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };
  const bannerChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedBanner({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeBanner = () => {
    setSelectedBanner();
  };

  useEffect(() => {
    setTags(singleBlog.tags);
  }, [singleBlog]);

  useEffect(() => {
    setDescription(singleBlog.description);
  }, [singleBlog]);

  const renderTags = () => {
    return singleBlog?.tags?.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editBlog" />
            </h4>
          </div>
          <div className="card-body form-group settings-tabs">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Tabs
                defaultActiveKey="mainInfo"
                id="home_page_tabs"
                className="home_page_tabs__"
              >
                <Tab
                  eventKey="mainInfo"
                  title={formatMessage({ id: "mainInfo" })}
                >
                  <Col xl={10} md={12}>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group required">
                          <h5>صورة المقال</h5>
                          <div className="image-uploader image-contain">
                            <div className="img-pat">
                              <img
                                src={imageUpload}
                                width={46}
                                height={46}
                                alt=""
                              />
                            </div>
                            <label>
                              {selectedImage && (
                                <div>
                                  <img
                                    src={
                                      selectedImage && selectedImage.preview
                                        ? URL.createObjectURL(
                                            selectedImage.preview
                                          )
                                        : singleBlog?.image
                                    }
                                    width={160}
                                    height={160}
                                    alt=""
                                  />
                                  <button
                                    className="removeImg"
                                    onClick={removeSelectedImage}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      className="bi bi-x-lg"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={imageChange}
                              />
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>التصنيف</h5>
                          <div>
                            <select
                              className="form-control"
                              {...register("category", { required: true })}
                            >
                              <option>اختر التصنيف</option>
                              {renderCategoriesSelectOptions()}
                            </select>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>لغة المقال</h5>
                          <div>
                            <select
                              className="form-control"
                              {...register("language", { required: true })}
                            >
                              <option>اختر لغة المقال</option>
                              {renderLanguagesSelectOptions()}
                            </select>
                          </div>
                        </div>
                      </Col>

                      <Col lg={12} xs={12}>
                        <div className="form-group required">
                          <h5>عنوان المقال</h5>
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder="عنوان المقال"
                              {...register("title", { required: true })}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={12} xs={12}>
                        <div className="form-group required">
                          <h5>وصف المقال</h5>
                          <div>
                            <Controller
                              control={control}
                              name="description"
                              render={({ field: { value } }) => (
                                <Editor
                                  initialValue={singleBlog?.description}
                                  onChange={(e, editor) => {
                                    const data = editor.getContent();
                                    setDescription(data);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="form-group">
                          <h5>
                            <FormattedMessage id="keywords" />
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              name="tags"
                              render={({
                                field: { onChange, value, selected },
                              }) => (
                                <CreatableSelect
                                  isMulti
                                  isRtl={true}
                                  className="basic-single creatable-select"
                                  classNamePrefix="select"
                                  options={blogTags.map((tag) => ({
                                    value: tag,
                                    label: tag,
                                  }))}
                                  defaultValue={
                                    renderTags()?.filter((tag) => {
                                      return tag;
                                    }) || []
                                  }
                                  onChange={(selected) => {
                                    const newTags = selected.map(
                                      (tag) => tag.label
                                    );

                                    setTags(newTags);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form-group required">
                          <h5>صورة الاعلان</h5>
                          <div className="image-uploader image-contain">
                            <div className="img-pat">
                              <img
                                src={imageUpload}
                                width={46}
                                height={46}
                                alt=""
                              />
                            </div>
                            <label>
                              {selectedBanner && (
                                <div>
                                  <img
                                    src={
                                      selectedBanner && selectedBanner.preview
                                        ? URL.createObjectURL(
                                            selectedBanner.preview
                                          )
                                        : singleBlog?.banner
                                    }
                                    width={160}
                                    height={160}
                                    alt=""
                                  />
                                  <button
                                    className="removeImg"
                                    onClick={removeBanner}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      className="bi bi-x-lg"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={bannerChange}
                              />
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Tab>
                <Tab
                  eventKey="seoInfo"
                  title={formatMessage({ id: "seoInfo" })}
                >
                  {" "}
                  <div className="form-body">
                    <Row className="gap-3">
                      <Col xs={12}>
                        <h5 htmlFor="">
                          <FormattedMessage id="title" />{" "}
                        </h5>
                        <div>
                          <input
                            type="text"
                            id=""
                            className="form-control"
                            placeholder="عنوان المتجر"
                            {...register("seo.pageTitle")}
                          />
                        </div>
                      </Col>

                      <Col xs={12}>
                        <h5 htmlFor="">الوصف</h5>
                        <div>
                          <input
                            type="text"
                            id=""
                            className="form-control"
                            placeholder="الوصف"
                            {...register("seo.pageDescription")}
                          />
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12}>
                        <div className="seoPreview">
                          <h5>
                            {watch("seo.pageTitle") ||
                              singleBlog?.name?.[locale]}
                          </h5>
                          <a
                            href={`https://nsn.sa/blogs/${singleBlog.slug}`}
                            target="_blank"
                            rel="noreferrer"
                            dir="ltr"
                          >
                            https://nsn.sa/blogs/
                            {singleBlog.slug}
                          </a>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                watch("seo.pageDescription")?.slice(0, 160) ||
                                `${description}`?.slice(0, 160),
                            }}
                          ></p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
              <Col lg={4} xs={12}>
                <div className="form-group mt-3">
                  <button type="submit" className="btn btn-blue">
                    <FormattedMessage id="save" />
                  </button>
                </div>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
