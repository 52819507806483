import server from "./server";

export const getControlReferencesApi = async () => {
  const response = await server().get("/controlReferences");
  return response.data;
};

export const getSingleControlReferenceApi = async (id) => {
  const response = await server().get(`/controlReferences/${id}`);
  return response.data;
};

export const addControlReferenceApi = async (payload) => {
  const response = await server().post("/controlReferences", payload);
  return response.data;
};

export const editControlReferenceApi = async ({ id, data }) => {
  const response = await server().put(`/controlReferences/${id}`, data);
  return response.data;
};

export const deleteControlReferenceApi = async (id) => {
  const response = await server().delete(`/controlReferences/${id}`);
  return response.data;
};
