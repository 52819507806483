import server from "./server";

export const getCategoriesApi = async () => {
  const response = await server().get("/categories");
  return response.data;
};

export const getSingleProductCategoryApi = async (id) => {
  const response = await server().get(`/categories/${id}`);
  return response.data;
};

export const addProductCategoriesApi = async (payload) => {
  const response = await server().post("/categories", payload);
  return response.data;
};

export const editCategoriesApi = async ({ id, data }) => {
  const response = await server().put(`/categories/${id}`, data);
  return response.data;
};

export const editCategoriesOrderApi = async (data) => {
  const response = await server().put(`/categories/reorderCategories`, data);
  return response.data;
};

export const deleteProductCategoriesApi = async (id) => {
  const response = await server().delete(`/categories/${id}`);
  return response.data;
};
