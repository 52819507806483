import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";
const Index = () => {
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addUser" })}
          cardTxt={formatMessage({ id: "users" })}
          icon={peopleIcon}
          url="/users/adduser"
        />
        <Table data={users} />
      </div>
    </>
  );
};

export default Index;
