import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSectors } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const Sectors = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { sectors } = useSelector((state) => state.sectors);
  useEffect(() => {
    dispatch(getSectors());
  }, [dispatch]);

  return (
    <>
      <div className="sectors-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addSector" })}
          cardTxt={formatMessage({ id: "sectors" })}
          icon={peopleIcon}
          url="/sectors/add"
        />
        <Table data={sectors} />
      </div>
    </>
  );
};

export default Sectors;
