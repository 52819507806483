import {
  GET_QUESTIONS,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_SUCCESS,
  ADD_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAILURE,
  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  GET_SINGLE_QUESTION,
  GET_SINGLE_QUESTION_SUCCESS,
  GET_SINGLE_QUESTION_FAILURE,
  APPROVE_QUESTION_COMMENT,
  APPROVE_QUESTION_COMMENT_SUCCESS,
  APPROVE_QUESTION_COMMENT_FAILURE,
  REJECT_QUESTION_COMMENT,
  REJECT_QUESTION_COMMENT_SUCCESS,
  REJECT_QUESTION_COMMENT_FAILURE,
} from "./actionTypes";

export const getQuestions = (payload) => {
  return {
    type: GET_QUESTIONS,
    payload,
  };
};

export const getQuestionsSuccess = (users) => {
  return {
    type: GET_QUESTIONS_SUCCESS,
    payload: users,
  };
};

export const getQuestionsFailure = (error) => {
  return {
    type: GET_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const getSingleQuestion = (id) => {
  return {
    type: GET_SINGLE_QUESTION,
    payload: id,
  };
};

export const getSingleQuestionSuccess = (user) => {
  return {
    type: GET_SINGLE_QUESTION_SUCCESS,
    payload: user,
  };
};

export const getSingleQuestionFailure = (error) => {
  return {
    type: GET_SINGLE_QUESTION_FAILURE,
    payload: error,
  };
};

export const addQuestion = (payload) => {
  return {
    type: ADD_QUESTION,
    payload,
  };
};

export const addQuestionSuccess = (payload) => {
  return {
    type: ADD_QUESTION_SUCCESS,
    payload,
  };
};

export const addQuestionFailure = (error) => {
  return {
    type: ADD_QUESTION_FAILURE,
    payload: error,
  };
};

export const approveQuestionComment = (payload) => {
  return {
    type: APPROVE_QUESTION_COMMENT,
    payload,
  };
};

export const approveQuestionCommentSuccess = (payload) => {
  return {
    type: APPROVE_QUESTION_COMMENT_SUCCESS,
    payload,
  };
};

export const approveQuestionCommentFailure = (error) => {
  return {
    type: APPROVE_QUESTION_COMMENT_FAILURE,
    payload: error,
  };
};

export const rejectQuestionComment = (payload) => {
  return {
    type: REJECT_QUESTION_COMMENT,
    payload,
  };
};

export const rejectQuestionCommentSuccess = (payload) => {
  return {
    type: REJECT_QUESTION_COMMENT_SUCCESS,
    payload,
  };
};

export const rejectQuestionCommentFailure = (error) => {
  return {
    type: REJECT_QUESTION_COMMENT_FAILURE,
    payload: error,
  };
};

export const editQuestion = (payload) => {
  return {
    type: EDIT_QUESTION,
    payload,
  };
};

export const editQuestionSuccess = (payload) => {
  return {
    type: EDIT_QUESTION_SUCCESS,
    payload,
  };
};

export const editQuestionFailure = (error) => {
  return {
    type: EDIT_QUESTION_FAILURE,
    payload: error,
  };
};

export const deleteQuestion = (id) => {
  return {
    type: DELETE_QUESTION,
    payload: id,
  };
};

export const deleteQuestionSuccess = (id) => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    payload: id,
  };
};

export const deleteQuestionFailure = (error) => {
  return {
    type: DELETE_QUESTION_FAILURE,
    payload: error,
  };
};
