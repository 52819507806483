import server from "./server";

export const getSubscribersApi = async () => {
  const response = await server().get("/subscribers");
  return response.data;
};

export const deleteSubscribersApi = async (id) => {
  const response = await server().delete(`/subscribers/${id}`);
  return response.data;
};
