export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAILURE = "GET_PACKAGES_FAILURE";

export const GET_SINGLE_PACKAGE = "GET_SINGLE_PACKAGE";
export const GET_SINGLE_PACKAGE_SUCCESS = "GET_SINGLE_PACKAGE_SUCCESS";
export const GET_SINGLE_PACKAGE_FAILURE = "GET_SINGLE_PACKAGE_FAILURE";

export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAILURE = "ADD_PACKAGE_FAILURE";

export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const EDIT_PACKAGE_SUCCESS = "EDIT_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_FAILURE = "EDIT_PACKAGE_FAILURE";

export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAILURE = "DELETE_PACKAGE_FAILURE";
