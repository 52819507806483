import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_SEO_REDIRECTS,
  DELETE_SEO_REDIRECTS,
  GET_SEO_REDIRECTS,
  ADD_SEO_REDIRECTS,
  EDIT_SEO_REDIRECTS,
} from "./actionTypes";

import {
  getAllSeoRedirectsApi,
  deleteSeoRedirectApi,
  getSeoRedirectsApi,
  addSeoRedirectApi,
  editSeoRedirectApi,
} from "api/seoRedirects";

import {
  getAllSeoRedirectsSuccess,
  getAllSeoRedirectsFailure,
  deleteSeoRedirectSuccess,
  deleteSeoRedirectFailure,
  getSeoRedirectSuccess,
  getSeoRedirectFailure,
  addSeoRedirectSuccess,
  addSeoRedirectFailure,
  editSeoRedirectSuccess,
  editSeoRedirectFailure,
} from "./actions";

// dispatch actions
function* getAllSeoRedirects() {
  try {
    const { data: contacts } = yield call(getAllSeoRedirectsApi);

    yield put(getAllSeoRedirectsSuccess(contacts));
  } catch (error) {
    yield put(getAllSeoRedirectsFailure(error));
  }
}

function* getSeoRedirect({ payload }) {
  try {
    const { data } = yield call(getSeoRedirectsApi, payload);

    yield put(getSeoRedirectSuccess(data));
  } catch (error) {
    yield put(getSeoRedirectFailure(error));
  }
}

function* deleteSeoRedirect({ payload }) {
  try {
    yield call(deleteSeoRedirectApi, payload);
    yield put(deleteSeoRedirectSuccess(payload));
  } catch (error) {
    yield put(deleteSeoRedirectFailure(error));
  }
}

function* addSeoRedirect({ payload }) {
  try {
    const { data } = yield call(addSeoRedirectApi, payload?.data);

    yield put(addSeoRedirectSuccess(data));
    if (payload?.navigate) payload.navigate("/seoRedirects");
  } catch (error) {
    yield put(addSeoRedirectFailure(error));
  }
}

function* editSeoRedirect({ payload }) {
  try {
    const { data } = yield call(editSeoRedirectApi, payload);

    yield put(editSeoRedirectSuccess(data));
    if (payload?.navigate) payload.navigate("/seoRedirects");
  } catch (error) {
    yield put(editSeoRedirectFailure(error));
  }
}

// watchers
export function* watchGetALLSeoRedirects() {
  yield takeEvery(GET_ALL_SEO_REDIRECTS, getAllSeoRedirects);
}

export function* watchDeleteSeoRedirect() {
  yield takeEvery(DELETE_SEO_REDIRECTS, deleteSeoRedirect);
}
export function* watchAddSeoRedirects() {
  yield takeEvery(ADD_SEO_REDIRECTS, addSeoRedirect);
}

export function* watchEditSeoRedirect() {
  yield takeEvery(EDIT_SEO_REDIRECTS, editSeoRedirect);
}

export function* watchGetSeoRedirect() {
  yield takeEvery(GET_SEO_REDIRECTS, getSeoRedirect);
}

function* contactsSaga() {
  yield all([
    fork(watchGetALLSeoRedirects),
    fork(watchDeleteSeoRedirect),
    fork(watchAddSeoRedirects),
    fork(watchEditSeoRedirect),
    fork(watchGetSeoRedirect),
  ]);
}

export default contactsSaga;
