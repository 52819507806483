import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { useFieldArray } from "react-hook-form";

const SlidersArray = ({ control, register, setValue, watch, errors, slug }) => {
  // const handleInputOnChange = (e, targetIndex) => {
  //   const { name, value } = e.target;

  //   const newSlidersArray = sliders.map((slider, index) => {
  //     if (targetIndex !== index) return slider;

  //     // make an object with locals inside the big one
  //     const inputName = name?.split(".")?.[0];
  //     const local = name?.split(".")?.[1];

  //     if (local) {
  //       return {
  //         ...slider,
  //         [inputName]: {
  //           ...slider?.[inputName],
  //           [local]: value,
  //         },
  //       };
  //     }
  //     return { ...slider, [name]: value };
  //   });
  //   setSliders(newSlidersArray);
  // };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections",
  });

  const subSections = (index) =>
    watch(`sections.${index}.sections`)?.length
      ? watch(`sections.${index}.sections`)
      : [];
  return (
    <div className="mb-4">
      <Row className="gap-2">
        <Col lg={12}>
          {/* <h5>
            <FormattedMessage id={"sliders"} />
          </h5> */}

          {fields.map((slider, index) => {
            return (
              <div key={index}>
                <Row
                  className={`justify-content-center ${
                    slider?.slug === slug ? "" : "d-none"
                  }`}
                >
                  <Col lg={8} xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"defaultValue"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`sections.${index}.title.${lang}`, {})}
                        />
                      )}
                    </MultiLangWrapper>

                    {/* <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"description"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.description}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <textarea
                          key={`${index}-${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(
                            `sections.${index}.description.${lang}`,
                            {}
                          )}
                        />
                      )}
                    </MultiLangWrapper> */}
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
      {/* <Col lg={4} xs={12} className="text-left">
        <button
          type="button"
          className="btn btn-green add_new_section__ mt-2"
          onClick={() => append({})}
        >
          <FormattedMessage id={"addSlider"} />
        </button>
      </Col> */}
    </div>
  );
};

export default SlidersArray;
