import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addCategory } from "store/actions";
import { useNavigate } from "react-router-dom";
import server from "api/server";
import { toast } from "react-toastify";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { FormattedMessage } from "react-intl";

const AddCategory = ({ category, handleClose = () => {} }) => {
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { error } = useSelector((state) => state.categories);
  useEffect(() => {
    if (error === "Validation Error")
      toast.error("تاكد من صحة البيانات (منها عدم تكرار الاسم)");
  }, [error]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path) {
    //   data["image"] = selectedImage.path;
    // }
    // data["icon"] = data["image"];
    // if (!data.icon?.length > 0) return toast.error("صورة التصنيف مفقودة");
    // if (category?.parentCategory)
    //   data.parentCategory = category?.parentCategory || "";
    dispatch(addCategory({ data, navigate, callBack: handleClose }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="">
          {/* <div className="card-head">
            <h4>
              <FormattedMessage id="addCategory" />
            </h4>
          </div> */}
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <Row>
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <h5>
                          <FormattedMessage id={"title"} />
                        </h5>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  <Row />
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <h5>
                          <FormattedMessage id={"description"} />
                        </h5>
                      }
                      errors={errors?.description}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <textarea
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`description.${lang}`, {
                            required: true,
                          })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  <Row />

                  <Col xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="isAvailable" />
                      </h5>
                      <div>
                        <input
                          type="checkbox"
                          className="form-control form-outline"
                          {...register("isAvailable")}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.isAvailable && (
                          <FormattedMessage id="requiredField" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group d-inline-flex">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="add" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
