import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_SUCCESS,
  ADD_PERMISSION,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
  EDIT_PERMISSION,
  EDIT_PERMISSION_SUCCESS,
  EDIT_PERMISSION_FAILURE,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  GET_SINGLE_PERMISSION,
  GET_SINGLE_PERMISSION_SUCCESS,
  GET_SINGLE_PERMISSION_FAILURE,
} from "./actionTypes";

export const getPermissions = () => {
  return {
    type: GET_PERMISSIONS,
  };
};

export const getPermissionsSuccess = (users) => {
  return {
    type: GET_PERMISSIONS_SUCCESS,
    payload: users,
  };
};

export const getPermissionsFailure = (error) => {
  return {
    type: GET_PERMISSIONS_FAILURE,
    payload: error,
  };
};

export const getSinglePermission = (id) => {
  return {
    type: GET_SINGLE_PERMISSION,
    payload: id,
  };
};

export const getSinglePermissionSuccess = (user) => {
  return {
    type: GET_SINGLE_PERMISSION_SUCCESS,
    payload: user,
  };
};

export const getSinglePermissionFailure = (error) => {
  return {
    type: GET_SINGLE_PERMISSION_FAILURE,
    payload: error,
  };
};

export const addPermission = (payload) => {
  return {
    type: ADD_PERMISSION,
    payload,
  };
};

export const addPermissionSuccess = (payload) => {
  return {
    type: ADD_PERMISSION_SUCCESS,
    payload,
  };
};

export const addPermissionFailure = (error) => {
  return {
    type: ADD_PERMISSION_FAILURE,
    payload: error,
  };
};

export const editPermission = (payload) => {
  return {
    type: EDIT_PERMISSION,
    payload,
  };
};

export const editPermissionSuccess = (payload) => {
  return {
    type: EDIT_PERMISSION_SUCCESS,
    payload,
  };
};

export const editPermissionFailure = (error) => {
  return {
    type: EDIT_PERMISSION_FAILURE,
    payload: error,
  };
};

export const deletePermission = (id) => {
  return {
    type: DELETE_PERMISSION,
    payload: id,
  };
};

export const deletePermissionSuccess = (id) => {
  return {
    type: DELETE_PERMISSION_SUCCESS,
    payload: id,
  };
};

export const deletePermissionFailure = (error) => {
  return {
    type: DELETE_PERMISSION_FAILURE,
    payload: error,
  };
};
