import { toast } from "react-toastify";
import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCTS,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_FAILURE,
  ADD_FAKE_PRODUCT,
  ADD_FAKE_PRODUCT_SUCCESS,
  ADD_FAKE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_MAIN_DATA,
  UPDATE_PRODUCT_MAIN_DATA_SUCCESS,
  UPDATE_PRODUCT_MAIN_DATA_FAILURE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  DELETE_EMPTY_PRODUCT,
  DELETE_EMPTY_PRODUCT_SUCCESS,
  DELETE_EMPTY_PRODUCT_FAILURE,
  GET_VARIATIONS,
  GET_VARIATIONS_SUCCESS,
  GET_VARIATIONS_FAILURE,
  GET_VARIATIONS_STYLES,
  GET_VARIATIONS_STYLES_SUCCESS,
  GET_VARIATIONS_STYLES_FAILURE,
} from "./actionTypes";
import { getId } from "helpers/functions";

const initialState = {
  products: [],
  metadata: {},
  loading: false,
  error: "",
  singleProduct: {},
};

const stores = (state = initialState, action) => {
  if (action.payload?.statusCode === 500) {
    toast.error("حدث خطا بالشبكة");
  }
  switch (action.type) {
    case GET_PRODUCTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        // products: action.payload.products.reverse(),
        products: action.payload.products || action.payload?.[0]?.products,
        metadata: action.payload.metadata,
      };
      break;
    case GET_PRODUCTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PRODUCT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleProduct: action.payload.product,
      };
      break;
    case GET_SINGLE_PRODUCT_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleProduct: {},
      };
      break;

    case ADD_PRODUCT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        products: [action.payload.product, ...state.products],

        error: "",
      };
      break;

    case ADD_PRODUCT_FAILURE:
      var err = { ...action.payload };
      // console.log("action.payload", { ...err });
      if (err?.response?.status === 422) {
        toast.error(
          `فشلت عملية اضافة الخدمة ، ${[
            ...new Set(err?.response?.data?.errors?.map((e) => e?.param)),
          ]
            ?.map((e) => `خطأ خاص ب${e}`)
            ?.join(" - ")}`
        );
      } else toast.error("فشلت عملية اضافة الخدمة");

      state = {
        ...state,
        error: err?.response?.data?.message || err,
        loading: false,
      };

      break;

    case UPDATE_PRODUCT_MAIN_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_PRODUCT_MAIN_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        products: [
          ...state.products.map((product) => {
            if (getId(product) !== getId(action.payload)) return product;
            return action.payload;
          }),
        ],
      };
      break;

    case UPDATE_PRODUCT_MAIN_DATA_FAILURE:
      // eslint-disable-next-line no-redeclare
      var err = { ...action.payload };

      // console.log("action.payload", { ...err });

      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.length
          ? toast.error(
              `فشلت عملية تعديل الخدمة ، ${[
                ...new Set(
                  err?.response?.data?.errors?.map((e) => e?.param || e?.msg)
                ),
              ]
                ?.map((e) => `خطأ خاص ب${e}`)
                ?.join(" - ")}`
            )
          : toast.error("فشلت عملية تعديل الخدمة");
      } else toast.error("فشلت عملية تعديل الخدمة");

      state = { ...state, error: action.payload, loading: false };
      break;
    //==deete a product
    case DELETE_PRODUCT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_PRODUCT_SUCCESS:
      const productsAfterDeleteing = [
        ...state?.products?.filter(
          (product) => getId(product) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        products: productsAfterDeleteing,
      };
      break;

    case DELETE_PRODUCT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    //==deete products
    case DELETE_PRODUCTS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_PRODUCTS_SUCCESS:
      const productsAfterDeleteings = [
        ...state?.products?.filter(
          (product) => !action.payload?.includes(getId(product))
        ),
      ];
      state = {
        ...state,
        loading: false,
        products: productsAfterDeleteings,
      };
      break;

    case DELETE_PRODUCTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case ADD_FAKE_PRODUCT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_FAKE_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        products: [action.payload, ...state.products],
      };
      break;

    case ADD_FAKE_PRODUCT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_PRODUCT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        products: state.products.map((product) => {
          if (getId(product) !== getId(action.payload.product)) return product;
          return action.payload.product;
        }),
      };
      break;
    case EDIT_PRODUCT_FAILURE:
      // eslint-disable-next-line no-redeclare
      var err = { ...action.payload };

      // console.log("action.payload", { ...err });

      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.length
          ? toast.error(
              `فشلت عملية تعديل الخدمة ، ${[
                ...new Set(
                  err?.response?.data?.errors?.map((e) => e?.param || e?.msg)
                ),
              ]
                ?.map((e) => `خطأ خاص ب${e}`)
                ?.join(" - ")}`
            )
          : toast.error("فشلت عملية تعديل الخدمة");
      } else toast.error("فشلت عملية تعديل الخدمة");

      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_EMPTY_PRODUCT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_EMPTY_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        products: state.products.filter(
          (product) => getId(product) !== getId(action.payload)
        ),
      };
      break;

    case DELETE_EMPTY_PRODUCT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    //--get variations data
    case GET_VARIATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_VARIATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        variations: action.payload.variations,
      };
      break;

    case GET_VARIATIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    //--get variations display styles data

    case GET_VARIATIONS_STYLES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_VARIATIONS_STYLES_SUCCESS:
      state = {
        ...state,
        loading: false,
        variationsStyles: action.payload.variationStyles,
      };
      break;

    case GET_VARIATIONS_STYLES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
