/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import Arabic from "./lang/ar.json";
import English from "./lang/en.json";

const Wrapper = (props) => {
  const [locale, setLocale] = useState(localStorage.getItem("lang") || "en");
  const [messages, setMessages] = useState(Arabic);

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      const editorAlert = document.querySelectorAll(
        ".tox-notifications-container"
      );
      if (editorAlert.length)
        editorAlert.forEach((e) => (e.style.display = "none"));
    });
  }, []);
  useEffect(() => {
    const lang = locale;
    if (lang === "ar") {
      setLocale("ar");
      setMessages(Arabic);

      document.body.setAttribute("dir", "rtl");
      document.documentElement.lang = "ar";
      document.querySelector("title").textContent = "لوحة التحكم";
    }

    if (lang === "en") {
      setLocale("en");
      setMessages(English);

      document.body.setAttribute("dir", "ltr");
      document.documentElement.lang = "en";
      document.querySelector("title").textContent = "Dashboard";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    // selectLanguage
    <Context.Provider value={{ locale, setLocale }}>
      <IntlProvider messages={messages} locale={locale} onError={() => {}}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;
