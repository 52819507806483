import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
} from "./actionTypes";

export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS,
  };
};

export const getNotificationsSuccess = (notifications) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};

export const getNotificationsFailure = (error) => {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};
