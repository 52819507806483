import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllContacts } from "store/actions";

import Table from "./Table";

const ContactUs = () => {
  const { contacts, loading } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  return (
    <div className="property-wrap">
      <Table data={contacts} loading={loading} />
    </div>
  );
};

export default ContactUs;
