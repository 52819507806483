import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_SINGLE_PAYMENT_METHOD,
  GET_SINGLE_PAYMENT_METHOD_SUCCESS,
  GET_SINGLE_PAYMENT_METHOD_FAILURE,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  EDIT_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD_SUCCESS,
  EDIT_PAYMENT_METHOD_FAILURE,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
} from "./actionTypes";

export const getPaymentMethods = () => {
  return {
    type: GET_PAYMENT_METHODS,
  };
};

export const getPaymentMethodsSuccess = (paymentMethods) => {
  return {
    type: GET_PAYMENT_METHODS_SUCCESS,
    payload: paymentMethods,
  };
};

export const getPaymentMethodsFailure = (error) => {
  return {
    type: GET_PAYMENT_METHODS_FAILURE,
    payload: error,
  };
};

export const getSinglePaymentMethod = (id) => {
  return {
    type: GET_SINGLE_PAYMENT_METHOD,
    payload: id,
  };
};

export const getSinglePaymentMethodSuccess = (theme) => {
  return {
    type: GET_SINGLE_PAYMENT_METHOD_SUCCESS,
    payload: theme,
  };
};

export const getSinglePaymentMethodFailure = (error) => {
  return {
    type: GET_SINGLE_PAYMENT_METHOD_FAILURE,
    payload: error,
  };
};

export const addPaymentMethod = (payload) => {
  return {
    type: ADD_PAYMENT_METHOD,
    payload,
  };
};

export const addPaymentMethodSuccess = (payload) => {
  return {
    type: ADD_PAYMENT_METHOD_SUCCESS,
    payload,
  };
};

export const addPaymentMethodFailure = (error) => {
  return {
    type: ADD_PAYMENT_METHOD_FAILURE,
    payload: error,
  };
};

export const editPaymentMethod = (payload) => {
  return {
    type: EDIT_PAYMENT_METHOD,
    payload,
  };
};

export const editPaymentMethodSuccess = (payload) => {
  return {
    type: EDIT_PAYMENT_METHOD_SUCCESS,
    payload,
  };
};

export const editPaymentMethodFailure = (error) => {
  return {
    type: EDIT_PAYMENT_METHOD_FAILURE,
    payload: error,
  };
};

export const deletePaymentMethod = (id) => {
  return {
    type: DELETE_PAYMENT_METHOD,
    payload: id,
  };
};

export const deletePaymentMethodSuccess = (id) => {
  return {
    type: DELETE_PAYMENT_METHOD_SUCCESS,
    payload: id,
  };
};

export const deletePaymentMethodFailure = (error) => {
  return {
    type: DELETE_PAYMENT_METHOD_FAILURE,
    payload: error,
  };
};
