import {
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_PRODUCT_TYPES_FAILURE,
} from "./actionTypes";

const initialState = {
  productTypes: [],
  loading: false,
  error: "",
};

const productTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCT_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        productTypes: [...action.payload.types],
      };
      break;
    case GET_PRODUCT_TYPES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default productTypes;
