import {
  ADD_BANK,
  ADD_BANK_FAILURE,
  ADD_BANK_SUCCESS,
  DELETE_BANK,
  DELETE_BANK_FAILURE,
  DELETE_BANK_SUCCESS,
  GET_BANKS,
  GET_BANKS_FAILURE,
  GET_BANKS_SUCCESS,
  GET_SINGLE_BANK,
  GET_SINGLE_BANK_FAILURE,
  GET_SINGLE_BANK_SUCCESS,
} from "./actionTypes";

const initialState = {
  banks: [],
  loading: false,
  error: "",
  singleBank: {},
};

const banks = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANKS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BANKS_SUCCESS:
      state = {
        ...state,
        loading: false,
        banks: action.payload.banks,
      };
      break;
    case GET_BANKS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_BANK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BANK_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBank: action.payload.bank,
      };
      break;
    case GET_SINGLE_BANK_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleBank: {},
      };
      break;

    case ADD_BANK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BANK_SUCCESS:
      state = {
        ...state,
        loading: false,
        banks: action.payload.banks,
      };
      break;
    case ADD_BANK_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_BANK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BANK_SUCCESS:
      const banksAfterDeleteing = [
        ...state?.banks?.filter((bank) => bank?.id !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        banks: banksAfterDeleteing,
      };
      break;
    case DELETE_BANK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default banks;
