import { getId } from "helpers/functions";
import {
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  ADD_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILURE,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  GET_SINGLE_BRAND,
  GET_SINGLE_BRAND_SUCCESS,
  GET_SINGLE_BRAND_FAILURE,
  EDIT_BRAND,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILURE,
  ADD_EMPTY_BRAND,
  ADD_EMPTY_BRAND_SUCCESS,
  ADD_EMPTY_BRAND_FAILURE,
  DELETE_EMPTY_BRAND,
  DELETE_EMPTY_BRAND_SUCCESS,
  DELETE_EMPTY_BRAND_FAILURE,
} from "./actionTypes";

const initialState = {
  brands: [],
  loading: false,
  error: "",
  singleBrand: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANDS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BRANDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: action.payload.brands,
      };
      break;
    case GET_BRANDS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBrand: action.payload.brand,
      };
      break;
    case GET_SINGLE_BRAND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleBrand: {},
      };
      break;

    case ADD_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: state.brands.map((brand) => {
          if (getId(brand) !== getId(action.payload)) return brand;
          return action.payload.brand;
        }),
      };
      break;
    case ADD_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case EDIT_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: [
          ...state.brands.map((brand) => {
            if (getId(brand) !== getId(action.payload)) return brand;
            return action.payload;
          }),
        ],
      };
      break;

    case EDIT_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BRAND_SUCCESS:
      const brandsAfterDeleteing = [
        ...state?.brands?.filter((brand) => getId(brand) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        brands: brandsAfterDeleteing,
      };
      break;
    case DELETE_BRAND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case ADD_EMPTY_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_EMPTY_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: [action.payload, ...state.brands],
      };
      break;

    case ADD_EMPTY_BRAND_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_EMPTY_BRAND:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_EMPTY_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brands: state.brands.filter(
          (brand) => getId(brand) !== getId(action.payload)
        ),
      };
      break;

    case DELETE_EMPTY_BRAND_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brands;
