import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import StoresSaga from "./stores/saga";
import CategoriesSaga from "./categories/saga";
import PackagesSaga from "./packages/saga";
import UsersSaga from "./users/saga";
import productTypesSaga from "./productTypes/saga";
import productsSaga from "./products/saga";
import brandsSaga from "./brands/saga";
import optionsSaga from "./options/saga";
import featuresSaga from "./features/saga";
import blogsSaga from "./blogs/saga";
import blogCategoriesSaga from "./blogCategories/saga";
import languagesSaga from "./languages/saga";
import ordersSaga from "./orders/saga";
import dashboardSaga from "./dashboard/saga";
import notificationsSaga from "./notifications/saga";
import couponsSaga from "./coupons/saga";
import unitsSaga from "./measuringUnits/saga";
import settingsSaga from "./settings/saga";
import pagesSaga from "./pages/saga";
import currenciesSaga from "./currencies/saga";
import paymentMethodsSaga from "./paymentMethods/saga";
import visitsReportSaga from "./visitsReport/saga";
import contactsSaga from "./contacts/saga";
import subscribersSaga from "./subscribers/saga";
import banksSaga from "./banks/saga";
import seoRedirectsSaga from "./seoRedirects/saga";
import adminsSaga from "./admins/saga";
import questionsSaga from "./questions/saga";
import rolesSaga from "./roles/saga";
import permissionsSaga from "./permissions/saga";
import sectionsSaga from "./questions/sections/saga";

import controlReferencesSaga from "./filters/controlReferences/saga";
import controlDomainsSaga from "./filters/controlDomains/saga";
import sectorsSaga from "./filters/sectors/saga";
import subControlDomainsSaga from "./filters/subControlDomains/saga";
import organizationTypesSaga from "./filters/organizationTypes/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    StoresSaga(),
    CategoriesSaga(),
    rolesSaga(),
    permissionsSaga(),
    PackagesSaga(),
    UsersSaga(),
    productTypesSaga(),
    productsSaga(),
    brandsSaga(),
    optionsSaga(),
    featuresSaga(),
    blogsSaga(),
    blogCategoriesSaga(),
    languagesSaga(),
    ordersSaga(),
    dashboardSaga(),
    notificationsSaga(),
    couponsSaga(),
    unitsSaga(),
    settingsSaga(),
    pagesSaga(),
    currenciesSaga(),
    paymentMethodsSaga(),
    visitsReportSaga(),
    contactsSaga(),
    subscribersSaga(),
    banksSaga(),
    seoRedirectsSaga(),
    adminsSaga(),
    questionsSaga(),
    sectionsSaga(),
    controlReferencesSaga(),
    controlDomainsSaga(),
    sectorsSaga(),
    subControlDomainsSaga(),
    organizationTypesSaga(),
  ]);
}
