import {
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
  ADD_OPTION,
  ADD_OPTION_SUCCESS,
  ADD_OPTION_FAILURE,
  EDIT_OPTION,
  EDIT_OPTION_SUCCESS,
  EDIT_OPTION_FAILURE,
  DELETE_OPTION,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_FAILURE,
} from "./actionTypes";

export const getOptions = () => {
  return {
    type: GET_OPTIONS,
  };
};

export const getOptionsSuccess = (options) => {
  return {
    type: GET_OPTIONS_SUCCESS,
    payload: options,
  };
};

export const getOptionsFailure = (error) => {
  return {
    type: GET_OPTIONS_FAILURE,
    payload: error,
  };
};

export const addOption = (payload) => {
  return {
    type: ADD_OPTION,
    payload,
  };
};

export const addOptionSuccess = (payload) => {
  return {
    type: ADD_OPTION_SUCCESS,
    payload,
  };
};

export const addOptionFailure = (error) => {
  return {
    type: ADD_OPTION_FAILURE,
    payload: error,
  };
};

export const editOption = (payload) => {
  return {
    type: EDIT_OPTION,
    payload,
  };
};

export const editOptionSuccess = (payload) => {
  return {
    type: EDIT_OPTION_SUCCESS,
    payload,
  };
};

export const editOptionFailure = (error) => {
  return {
    type: EDIT_OPTION_FAILURE,
    payload: error,
  };
};

export const deleteOption = (id) => {
  return {
    type: DELETE_OPTION,
    payload: id,
  };
};

export const deleteOptionSuccess = (id) => {
  return {
    type: DELETE_OPTION_SUCCESS,
    payload: id,
  };
};

export const deleteOptionFailure = (error) => {
  return {
    type: DELETE_OPTION_FAILURE,
    payload: error,
  };
};
