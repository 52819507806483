import {
  GET_SUBSCRIBERS,
  GET_SUBSCRIBERS_SUCCESS,
  GET_SUBSCRIBERS_FAILURE,
  DELETE_SUBSCRIBERS,
  DELETE_SUBSCRIBERS_SUCCESS,
  DELETE_SUBSCRIBERS_FAILURE,
} from "./actionTypes";

export const getSubscribers = () => {
  return {
    type: GET_SUBSCRIBERS,
  };
};

export const getSubscribersSuccess = (payload) => {
  return {
    type: GET_SUBSCRIBERS_SUCCESS,
    payload,
  };
};

export const getSubscribersFailure = (error) => {
  return {
    type: GET_SUBSCRIBERS_FAILURE,
    payload: error,
  };
};

export const deleteSubscribers = (payload) => {
  return {
    type: DELETE_SUBSCRIBERS,
    payload,
  };
};

export const deleteSubscribersSuccess = (payload) => {
  return {
    type: DELETE_SUBSCRIBERS_SUCCESS,
    payload,
  };
};

export const deleteSubscribersFailure = (error) => {
  return {
    type: DELETE_SUBSCRIBERS_FAILURE,
    payload: error,
  };
};
