import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  changeOrderStatusApi,
  getOrdersApi,
  getSingleOrderApi,
} from "api/orders";

// Login Redux States
import {
  CHANGE_ORDER_STATUS,
  GET_ORDERS,
  GET_SINGLE_ORDER,
} from "./actionTypes";
import {
  changeOrderStatusFailure,
  changeOrderStatusSuccess,
  getOrdersFailure,
  getOrdersSuccess,
  getSingleOrderFailure,
  getSingleOrdersuccess,
} from "./actions";

function* getOrders({ payload }) {
  try {
    const { data } = yield call(getOrdersApi, payload);
    yield put(getOrdersSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getOrdersFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleOrder({ payload }) {
  try {
    const { data } = yield call(getSingleOrderApi, payload);
    yield put(getSingleOrdersuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleOrderFailure(error?.response?.data || error));
  }
}

function* changeOrderStatus({ payload }) {
  try {
    const { data } = yield call(changeOrderStatusApi, payload);
    yield put(changeOrderStatusSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(changeOrderStatusFailure(error?.response?.data || error));
  }
}

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getOrders);
}

export function* watchGetSingleOrder() {
  yield takeEvery(GET_SINGLE_ORDER, getSingleOrder);
}

export function* watchChangeOrderStatus() {
  yield takeEvery(CHANGE_ORDER_STATUS, changeOrderStatus);
}

function* ordersSaga() {
  yield all([fork(watchGetOrders)]);
  yield all([fork(watchGetSingleOrder)]);
  yield all([fork(watchChangeOrderStatus)]);
}

export default ordersSaga;
