import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";
import BootyPagination from "components/shared/Pagination";
import { useSearchParams } from "react-router-dom";

const Questions = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { questions, metadata } = useSelector((state) => state.questions);

  useEffect(() => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    dispatch(getQuestions(paramsStr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  return (
    <>
      <div className="questions-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addQuestion" })}
          cardTxt={formatMessage({ id: "questions" })}
          icon={peopleIcon}
          url="/questions/add"
        />
        <Table data={questions} />
        <BootyPagination metaData={metadata} />
      </div>
    </>
  );
};

export default Questions;
