import server from "./server";

export const getSectorsApi = async () => {
  const response = await server().get("/sectors");
  return response.data;
};

export const getSingleSectorApi = async (id) => {
  const response = await server().get(`/sectors/${id}`);
  return response.data;
};

export const addSectorApi = async (payload) => {
  const response = await server().post("/sectors", payload);
  return response.data;
};

export const editSectorApi = async ({ id, data }) => {
  const response = await server().put(`/sectors/${id}`, data);
  return response.data;
};

export const deleteSectorApi = async (id) => {
  const response = await server().delete(`/sectors/${id}`);
  return response.data;
};

export const setDefaultItemApi = async ({ route, id }) => {
  const response = await server().get(`/${route}/setDefault/${id}`);
  return response.data;
};
