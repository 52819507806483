import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { useFieldArray } from "react-hook-form";
import removeImg from "assets/svgs/close.svg";

const SlidersArray = ({ control, register, setValue, watch, errors }) => {
  // const handleInputOnChange = (e, targetIndex) => {
  //   const { name, value } = e.target;

  //   const newSlidersArray = sliders.map((slider, index) => {
  //     if (targetIndex !== index) return slider;

  //     // make an object with locals inside the big one
  //     const inputName = name?.split(".")?.[0];
  //     const local = name?.split(".")?.[1];

  //     if (local) {
  //       return {
  //         ...slider,
  //         [inputName]: {
  //           ...slider?.[inputName],
  //           [local]: value,
  //         },
  //       };
  //     }
  //     return { ...slider, [name]: value };
  //   });
  //   setSliders(newSlidersArray);
  // };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections",
  });

  const subSections = (index) =>
    watch(`sections.${index}.sections`)?.length
      ? watch(`sections.${index}.sections`)
      : [];
  return (
    <div className="form-group branches sliders">
      <Row className="gap-2">
        <Col lg={12}>
          {/* <h5>
            <FormattedMessage id={"sliders"} />
          </h5> */}

          {fields.map((slider, index) => {
            return (
              <div key={index} className="add-multi-component">
                {/* <button
                  type="button"
                  onClick={() => remove(index)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button> */}
                <Row className="justify-content-center">
                  <Col lg={8} xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.sections?.[index]?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={`${index}${key}`}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`sections.${index}.title.${lang}`, {})}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                </Row>

                <div className="form-group card">
                  <Row>
                    {subSections(index).map((_, subIndex) => (
                      <SubSection
                        register={register}
                        index={index}
                        subIndex={subIndex}
                        errors={errors}
                        key={subIndex}
                        setValue={setValue}
                        watch={watch}
                      />
                    ))}
                  </Row>
                  <Row>
                    <Col lg={3} md="4" xs={12} className="text-left">
                      <button
                        type="button"
                        className="btn btn-green add_new_section__ mt-2"
                        onClick={() =>
                          setValue(`sections.${index}.sections`, [
                            ...subSections(index),
                            {},
                          ])
                        }
                      >
                        <FormattedMessage id={"addSubSection"} />
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
      {/* <Col lg={4} xs={12} className="text-left">
        <button
          type="button"
          className="btn btn-green add_new_section__ mt-2"
          onClick={() => append({})}
        >
          <FormattedMessage id={"addSlider"} />
        </button>
      </Col> */}
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const SubSection = ({ register, index, subIndex, watch, setValue, errors }) => {
  return (
    <Col lg={6} xs={12}>
      <Row className="add-multi-component my-2">
        <button
          type="button"
          onClick={() =>
            setValue(
              `sections.${index}.sections`,
              watch(`sections.${index}.sections`).filter(
                (_, i) => i !== subIndex
              )
            )
          }
          className="removeImg"
        >
          <img src={removeImg} alt="" />
        </button>

        <Col xs="12">
          {/* {!slider?.sections && ( */}
          <ImageUpdate
            value={watch(`sections.${index}.sections.${subIndex}.image`)}
            setValue={(v) =>
              setValue(`sections.${index}.sections.${subIndex}.image`, v)
            }
          />
          {/* )} */}
        </Col>

        <Col xs={12}>
          <MultiLangWrapper
            label={
              <label>
                <FormattedMessage id={"title"} />
              </label>
            }
            errors={errors?.sections?.[index]?.title}
            customClass="form-group selectHolder col-12"
          >
            {({ lang, classes, key }) => (
              <input
                key={`${index}${key}`}
                type="text"
                className={`form-control form-outline ${classes}`}
                {...register(
                  `sections.${index}.sections.${subIndex}.title.${lang}`,
                  {}
                )}
              />
            )}
          </MultiLangWrapper>

          <MultiLangWrapper
            label={
              <label>
                <FormattedMessage id={"description"} />
              </label>
            }
            errors={errors?.sections?.[index]?.description}
            customClass="form-group selectHolder col-12"
          >
            {({ lang, classes, key }) => (
              <textarea
                key={`${index}-${key}`}
                type="text"
                className={`form-control form-outline ${classes}`}
                {...register(
                  `sections.${index}.sections.${subIndex}.description.${lang}`,
                  {}
                )}
              />
            )}
          </MultiLangWrapper>
        </Col>
      </Row>
    </Col>
  );
};

export default SlidersArray;
