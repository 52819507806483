import { sidebarData } from "data";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import logoIcon from "assets/images/logo-icon.svg";
import { FormattedMessage } from "react-intl";
import { handleImage, sidebarToggleFunction } from "helpers/functions";
import { useDispatch, useSelector } from "react-redux";
import { getAllPages } from "store/actions";
import { checkPermissions } from "helpers/authentication/checkPermissions";

const checkActive = (url, parent = false) => {
  if (typeof document == "undefined") return;

  const hrefParams = new URLSearchParams(
    document?.location?.href?.split("?")?.[1]
  );
  const itemParams = new URLSearchParams(url?.split("?")?.[1]);

  const href = document?.location?.href
    ?.replace(document.location.origin, "")
    ?.split("/add")?.[0]
    ?.split("/edit")?.[0]
    ?.split("/builder")?.[0]
    ?.split("?")?.[0];

  if (!href) return false;

  const checkDeviceType =
    hrefParams.get("deviceType") === itemParams.get("deviceType");

  const filteredItemUrl = url
    ?.replace("/add", "")
    ?.replace("/edit", "")
    ?.split("?")?.[0];

  return (
    href === filteredItemUrl &&
    // || (parent && href?.includes(filteredItemUrl))
    checkDeviceType
  );
};

const Sidebar = () => {
  // const { locale } = useIntl();
  // const { pages } = useSelector((state) => state.pages);
  // const pagesArr = pages?.map((e) => ({
  //   id: e?._id,
  //   to: `/pages/${e?.slug}`,
  //   text: e?.title?.[locale],
  // }));
  const dispatch = useDispatch();
  const { mainSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getAllPages());
  }, [dispatch]);

  var isResizing = false;

  (function () {
    const container = document.querySelector(".main-wrapper");
    const left = document.querySelector(".main-sidebar");
    const right = document.querySelector(".content-wrap");
    const head = document.querySelector(".head-wrp");
    const handle = document.querySelector("#drag");

    handle?.addEventListener("mousedown", function (e) {
      isResizing = true;
    });

    document.onmousemove = function (e) {
      // we don't want to do anything if we aren't resizing.
      if (!isResizing) {
        return;
      }

      var offsetRight =
        container?.clientWidth - (e.clientX - container.offsetLeft);

      left.style.width = offsetRight + "px";
      right.style.marginRight = offsetRight + "px";
      head.style.paddingRight = offsetRight + "px";
    };

    document.onmouseup = function (e) {
      // stop resizing
      isResizing = false;
    };
  })();
  const settingLogo = mainSettings?.find(
    (setting) => setting?.title === "logo"
  )?.value;

  return (
    <>
      <div className="main-sidebar">
        <div id="drag"></div>
        <div className="logo">
          <div className="inner">
            <NavLink to="/">
              <img
                src={handleImage(settingLogo)}
                alt=""
                className="logo-icon"
              />
              {/* <img src={logoInfo} alt="" className="logo-info" /> */}
            </NavLink>
          </div>
        </div>
        {/* <div className="stores-selection">
          <Dropdown>
            <span>
              {store && (
                <>
                  <i>برو</i>
                  <img src={rippon} alt="" className="rippon" />
                </>
              )}
              <img src={activeStore?.logo || ""} alt="" />
            </span>
            <div>
              <Dropdown.Toggle>
                {(activeStore && activeStore.name?.[locale]) || "أختر المتجر"}
              </Dropdown.Toggle>
              {store && (
                <a
                  href={`${process.env.REACT_APP_FRONT_WEBSITE}/${activeStore?.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  زيـارة المـتجر
                </a>
              )}
            </div>

            <Dropdown.Menu>
              <div className="drop-scrollable">
                {stores?.map((store) => (
                  <Dropdown.Item
                    key={store.id}
                    onClick={() => {
                      localStorage.setItem("store", store.id);
                      dispatch(
                        getActiveStore({ storeId: store.id, deviceType })
                      );
                      window.location.reload();
                    }}
                    className={
                      activeStore?.id === store.id ? "active-store" : ""
                    }
                  >
                    {store.name?.[locale]}
                    <img src={store?.logo} alt="" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <div className="side-nav">
          <ul onClick={sidebarToggleFunction}>
            <li>
              {sidebarData?.map((item, index) => {
                let children = item?.children || [];
                // || (item?.text == "pages" ? pagesArr : []);
                if (!checkPermissions(item?.permissions) && !item?.separator)
                  return false;
                if (item?.separator)
                  return <hr className="border-white" key={index} />;
                return (
                  <div key={item?.id} className="side-nav-child">
                    <NavLink
                      to={`${item?.to}`}
                      className={({ isActive: ww }) =>
                        checkActive(item?.to, children?.length > 0) ||
                        children
                          ?.map((e) => checkActive(e?.to))
                          ?.find((e) => e === true)
                          ? "active"
                          : ""
                      }
                    >
                      {item?.svg}
                      <span>
                        <FormattedMessage id={`${item?.text}`} />
                      </span>
                    </NavLink>
                    {children?.length >= 1 && (
                      <div className="arrow">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.74125 5.91552L0.774312 2.94857C0.631219 2.80548 0.631219 2.57349 0.774312 2.43041L1.12036 2.08437C1.26321 1.94152 1.49472 1.94124 1.63791 2.08376L4.00033 4.43511L6.36274 2.08376C6.50593 1.94124 6.73745 1.94152 6.88029 2.08437L7.22634 2.43041C7.36943 2.5735 7.36943 2.80549 7.22634 2.94857L4.25942 5.91552C4.11633 6.0586 3.88434 6.0586 3.74125 5.91552Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    )}
                    {children?.length >= 1 && (
                      <ul className="children">
                        <li>
                          {children?.map((childNav) => (
                            <NavLink
                              key={childNav?.id}
                              to={childNav?.to}
                              className={({ isActive: ww }) =>
                                checkActive(childNav?.to) ? "active-child" : ""
                              }
                            >
                              <span>
                                <FormattedMessage
                                  id={`${childNav?.text ?? null}`}
                                />
                              </span>
                            </NavLink>
                          ))}
                        </li>
                      </ul>
                    )}
                  </div>
                );
              })}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
