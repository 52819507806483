export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAILURE = "GET_BLOGS_FAILURE";

export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAILURE = "GET_SINGLE_BLOG_FAILURE";

export const ADD_BLOG = "ADD_BLOG";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_FAILURE = "ADD_BLOG_FAILURE";

export const EDIT_BLOG = "EDIT_BLOG";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_FAILURE = "EDIT_BLOG_FAILURE";

export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAILURE = "DELETE_BLOG_FAILURE";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";
