export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE = "GET_QUESTIONS_FAILURE";

export const GET_SINGLE_QUESTION = "GET_SINGLE_QUESTION";
export const GET_SINGLE_QUESTION_SUCCESS = "GET_SINGLE_QUESTION_SUCCESS";
export const GET_SINGLE_QUESTION_FAILURE = "GET_SINGLE_QUESTION_FAILURE";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAILURE = "ADD_QUESTION_FAILURE";

export const APPROVE_QUESTION_COMMENT = "APPROVE_QUESTION_COMMENT";
export const APPROVE_QUESTION_COMMENT_SUCCESS =
  "APPROVE_QUESTION_COMMENT_SUCCESS";
export const APPROVE_QUESTION_COMMENT_FAILURE =
  "APPROVE_QUESTION_COMMENT_FAILURE";

export const REJECT_QUESTION_COMMENT = "REJECT_QUESTION_COMMENT";
export const REJECT_QUESTION_COMMENT_SUCCESS =
  "REJECT_QUESTION_COMMENT_SUCCESS";
export const REJECT_QUESTION_COMMENT_FAILURE =
  "REJECT_QUESTION_COMMENT_FAILURE";

export const EDIT_QUESTION = "EDIT_QUESTION";
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
export const EDIT_QUESTION_FAILURE = "EDIT_QUESTION_FAILURE";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";
