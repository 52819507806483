import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationTypes } from "store/actions";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const OrganizationTypes = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { organizationTypes } = useSelector((state) => state.organizationTypes);
  useEffect(() => {
    dispatch(getOrganizationTypes());
  }, [dispatch]);

  return (
    <>
      <div className="organizationTypes-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addOrganizationType" })}
          cardTxt={formatMessage({ id: "organizationTypes" })}
          icon={peopleIcon}
          url="/organizationTypes/add"
        />
        <Table data={organizationTypes} />
      </div>
    </>
  );
};

export default OrganizationTypes;
