export const GET_OPTIONS = "GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAILURE = "GET_OPTIONS_FAILURE";
export const ADD_OPTION = "ADD_OPTION";
export const ADD_OPTION_SUCCESS = "ADD_OPTION_SUCCESS";
export const ADD_OPTION_FAILURE = "ADD_OPTION_FAILURE";
export const EDIT_OPTION = "EDIT_OPTION";
export const EDIT_OPTION_SUCCESS = "EDIT_OPTION_SUCCESS";
export const EDIT_OPTION_FAILURE = "EDIT_OPTION_FAILURE";
export const DELETE_OPTION = "DELETE_OPTION";
export const DELETE_OPTION_SUCCESS = "DELETE_OPTION_SUCCESS";
export const DELETE_OPTION_FAILURE = "DELETE_OPTION_FAILURE";
