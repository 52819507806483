import {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_FAILURE,
  ADD_PRODUCT_CATEGORIES,
  ADD_PRODUCT_CATEGORIES_SUCCESS,
  ADD_PRODUCT_CATEGORIES_FAILURE,
  EDIT_PRODUCT_CATEGORIES,
  EDIT_PRODUCT_CATEGORIES_SUCCESS,
  EDIT_PRODUCT_CATEGORIES_FAILURE,
  DELETE_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORIES_SUCCESS,
  DELETE_PRODUCT_CATEGORIES_FAILURE,
  GET_SINGLE_PRODUCT_CATEGORY,
  GET_SINGLE_PRODUCT_CATEGORY_SUCCESS,
  GET_SINGLE_PRODUCT_CATEGORY_FAILURE,
  EDIT_PRODUCT_CATEGORIES_ORDER,
  EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS,
  EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE,
} from "./actionTypes";

export const getCategories = () => {
  return {
    type: GET_PRODUCT_CATEGORIES,
  };
};

export const getCategoriesSuccess = (payload) => {
  return {
    type: GET_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const getCategoriesFailure = (error) => {
  return {
    type: GET_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const getSingleProductCategory = (id) => {
  return {
    type: GET_SINGLE_PRODUCT_CATEGORY,
    payload: id,
  };
};

export const getSingleProductCategorySuccess = (productCategory) => {
  return {
    type: GET_SINGLE_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategory,
  };
};

export const getSingleProductCategoryFailure = (error) => {
  return {
    type: GET_SINGLE_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  };
};

export const addCategory = (payload) => {
  return {
    type: ADD_PRODUCT_CATEGORIES,
    payload,
  };
};

export const addProductCategoriesSuccess = (payload) => {
  return {
    type: ADD_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const addProductCategoriesFailure = (error) => {
  return {
    type: ADD_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const editCategory = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES,
    payload,
  };
};

export const editCategoriesSuccess = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_SUCCESS,
    payload,
  };
};

export const editCategoriesFailure = (error) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};
// prouct categories eidt order
export const editCategoriesOrder = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_ORDER,
    payload,
  };
};

export const editCategoriesOrderSuccess = (payload) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_ORDER_SUCCESS,
    payload,
  };
};

export const editCategoriesOrderFailure = (error) => {
  return {
    type: EDIT_PRODUCT_CATEGORIES_ORDER_FAILURE,
    payload: error,
  };
};

export const deleteProductCategories = (id) => {
  return {
    type: DELETE_PRODUCT_CATEGORIES,
    payload: id,
  };
};

export const deleteProductCategoriesSuccess = (id) => {
  return {
    type: DELETE_PRODUCT_CATEGORIES_SUCCESS,
    payload: id,
  };
};

export const deleteProductCategoriesFailure = (error) => {
  return {
    type: DELETE_PRODUCT_CATEGORIES_FAILURE,
    payload: error,
  };
};
