import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getControlReferencesApi,
  addControlReferenceApi,
  editControlReferenceApi,
  deleteControlReferenceApi,
  getSingleControlReferenceApi,
} from "api/controlReferences";

// Login Redux States
import {
  ADD_CONTROL_REFERENCE,
  DELETE_CONTROL_REFERENCE,
  EDIT_CONTROL_REFERENCE,
  GET_SINGLE_CONTROL_REFERENCE,
  GET_CONTROL_REFERENCES,
} from "./actionTypes";
import {
  addControlReferenceFailure,
  addControlReferenceSuccess,
  getControlReferencesSuccess,
  getControlReferencesFailure,
  editControlReferenceSuccess,
  editControlReferenceFailure,
  deleteControlReferenceSuccess,
  deleteControlReferenceFailure,
  getSingleControlReferenceSuccess,
  getSingleControlReferenceFailure,
} from "./actions";

function* getControlReferences() {
  try {
    const { data } = yield call(getControlReferencesApi);
    yield put(getControlReferencesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getControlReferencesFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleControlReference({ payload }) {
  try {
    const { data } = yield call(getSingleControlReferenceApi, payload);
    yield put(getSingleControlReferenceSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleControlReferenceFailure(error?.response?.data || error));
  }
}

function* addControlReference({ payload }) {
  try {
    const { data } = yield call(addControlReferenceApi, payload.data);
    yield put(addControlReferenceSuccess(data));
    yield payload.navigate("/controlReferences");
  } catch (error) {
    console.log(error);
    yield put(
      addControlReferenceFailure(error.response?.data?.message || error)
    );
  }
}

function* editControlReference({ payload }) {
  try {
    const { data } = yield call(editControlReferenceApi, payload);
    yield put(editControlReferenceSuccess(data));
    yield payload.navigate("/controlReferences");
  } catch (error) {
    console.log(error);
    yield put(
      editControlReferenceFailure(error.response?.data?.message || error)
    );
  }
}

function* deleteControlReference({ payload }) {
  try {
    yield call(deleteControlReferenceApi, payload);
    yield put(deleteControlReferenceSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteControlReferenceFailure(error?.response?.data || error));
  }
}

export function* watchGetControlReferences() {
  yield takeEvery(GET_CONTROL_REFERENCES, getControlReferences);
}

export function* watchSingleControlReference() {
  yield takeEvery(GET_SINGLE_CONTROL_REFERENCE, getSingleControlReference);
}

export function* watchAddControlReference() {
  yield takeEvery(ADD_CONTROL_REFERENCE, addControlReference);
}

export function* watchEditControlReference() {
  yield takeEvery(EDIT_CONTROL_REFERENCE, editControlReference);
}

export function* watchDeleteControlReference() {
  yield takeEvery(DELETE_CONTROL_REFERENCE, deleteControlReference);
}

function* ControlReferencesSaga() {
  yield all([fork(watchGetControlReferences)]);
  yield all([fork(watchSingleControlReference)]);
  yield all([fork(watchAddControlReference)]);
  yield all([fork(watchEditControlReference)]);
  yield all([fork(watchDeleteControlReference)]);
}

export default ControlReferencesSaga;
