import { getId } from "helpers/functions";
import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAILURE,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  GET_SINGLE_ADMIN,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
} from "./actionTypes";

const initialState = {
  admins: [],
  loading: false,
  error: "",
  singleAdmin: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_ADMINS_SUCCESS:
      state = {
        ...state,
        loading: false,
        admins: action.payload.admins,
      };
      break;
    case GET_ADMINS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_ADMIN:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case GET_SINGLE_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleAdmin: action.payload.admin,
      };
      break;

    case GET_SINGLE_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleAdmin: {},
      };
      break;

    case ADD_ADMIN:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case ADD_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        admins: action.payload.admins,
      };
      break;
    case ADD_ADMIN_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_ADMIN:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case EDIT_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleAdmin: action.payload.admin,
      };
      break;
    case EDIT_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case DELETE_ADMIN:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case DELETE_ADMIN_SUCCESS:
      const citiesAfterDeleteing = [
        ...state?.admins?.filter((admin) => getId(admin) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        admins: citiesAfterDeleteing,
      };
      break;
    case DELETE_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brands;
