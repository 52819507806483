import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import {
  getCurrentUser,
  //  getSettings
} from "store/actions";
import Loader from "./components/shared/Loader";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";

// import StoresIndex from "views/stores/Index";
// import AddStore from "views/stores/AddStore";
// import EditStore from "views/stores/EditStore";
// import ShowStore from "views/stores/ShowStore";

import UsersIndex from "views/users/Index";
import AddUser from "views/users/AddUser";
import EditUser from "views/users/EditUser";

import CategoriesIndex from "views/categories/Index";
import AddCategory from "views/categories/AddCategory";
import EditCategory from "views/categories/EditCategory";

import BlogsIndex from "views/blogs/Index";
import AddBlog from "views/blogs/AddBlog";
import EditBlog from "views/blogs/EditBlog";
import ShowBlog from "views/blogs/ShowBlog";

import BlogCategoriesIndex from "views/blogsCategories/Index";
import AddBlogCategory from "views/blogsCategories/AddBlogCategory";
import EditBlogCategory from "views/blogsCategories/EditBlogCategory";

import Settings from "views/settings/index";

import Pages from "views/pages/index";
import EditPage from "views/pages/EditPage";
import AddPage from "views/pages/AddPage";

import Profile from "views/Profile";
// import BuilderPage from "views/builder";
import AboutUsPage from "views/pages/AboutUs";
// import HomeHero from "views/pages/HomeHero";
import HomeIntro from "views/pages/HomeIntro";
import WhyUsPage from "views/pages/WhyUs";
import ContactUs from "views/ContactUs";
import ShowContact from "views/ContactUs/ShowContact";
import Subscribers from "views/subscribers/Index";
import TermsConditions from "views/pages/termsConditions";

import Questions from "views/questions/Index";
import AddQuestion from "views/questions/Add";
import EditQuestion from "views/questions/Edit";
import SubControlDomains from "views/filters/subControlDomains/Index";
import AddSubControlDomain from "views/filters/subControlDomains/Add";
import EditSubControlDomain from "views/filters/subControlDomains/Edit";
import ControlDomains from "views/filters/controlDomains/Index";
import AddControlDomain from "views/filters/controlDomains/Add";
import EditControlDomain from "views/filters/controlDomains/Edit";
import ControlReferences from "views/filters/controlReferences/Index";
import AddControlReference from "views/filters/controlReferences/Add";
import EditControlReference from "views/filters/controlReferences/Edit";
import OrganizationTypes from "views/filters/organizationTypes/Index";
import AddOrganizationType from "views/filters/organizationTypes/Add";
import EditOrganizationType from "views/filters/organizationTypes/Edit";
import Sectors from "views/filters/sectors/Index";
import AddSector from "views/filters/sectors/Add";
import EditSector from "views/filters/sectors/Edit";
import Packages from "views/packages/Index";
import AddPackage from "views/packages/Add";
import EditPackage from "views/packages/Edit";
import HowWorksPage from "views/pages/HowWorks";
import PackageInfo from "views/pages/PackagesInfo";
import Faqs from "views/pages/Faqs";
import PrivacyTerms from "views/pages/PrivacyTerms";
import CookiesPolicy from "views/pages/CookiesPolicy";
import AddSection from "views/questions/sections/Add";
import EditSection from "views/questions/sections/Edit";
import ShowSection from "views/questions/sections/Show";
import StayConnected from "views/pages/StayConnected";
import Roles from "views/roles/Index";
import AddRole from "views/roles/Add";
import EditRole from "views/roles/Edit";
import Admins from "views/admins/Index";
import AddAdmin from "views/admins/Add";
import EditAdmin from "views/admins/Edit";
import RegoSee from "views/pages/RegoSee";
import RegoTell from "views/pages/RegoTell";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser());
      // dispatch(getSettings("languages"));
    }
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />

              <Route
                path="/questions"
                element={
                  <AuthRoute>
                    <Questions />
                  </AuthRoute>
                }
              />
              <Route
                path="/questions/add"
                element={
                  <AuthRoute>
                    <AddQuestion />
                  </AuthRoute>
                }
              />

              <Route
                path="/questions/:id"
                element={
                  <AuthRoute>
                    <EditQuestion />
                  </AuthRoute>
                }
              >
                <Route
                  path="sections/add"
                  element={
                    <AuthRoute>
                      <AddSection />
                    </AuthRoute>
                  }
                />

                <Route
                  path="sections/:sectionId"
                  element={
                    <AuthRoute>
                      <EditSection />
                    </AuthRoute>
                  }
                />
                <Route
                  path="sections/show/:sectionId"
                  element={
                    <AuthRoute>
                      <ShowSection />
                    </AuthRoute>
                  }
                />
              </Route>

              <Route
                path="/packages"
                element={
                  <AuthRoute>
                    <Packages />
                  </AuthRoute>
                }
              />
              <Route
                path="/packages/add"
                element={
                  <AuthRoute>
                    <AddPackage />
                  </AuthRoute>
                }
              />

              <Route
                path="/packages/:id"
                element={
                  <AuthRoute>
                    <EditPackage />
                  </AuthRoute>
                }
              />
              {/* ------------------------------- */}

              <Route
                path="/subControlDomains"
                element={
                  <AuthRoute>
                    <SubControlDomains />
                  </AuthRoute>
                }
              />
              <Route
                path="/subControlDomains/add"
                element={
                  <AuthRoute>
                    <AddSubControlDomain />
                  </AuthRoute>
                }
              />

              <Route
                path="/subControlDomains/:id"
                element={
                  <AuthRoute>
                    <EditSubControlDomain />
                  </AuthRoute>
                }
              />
              {/* ---- */}

              <Route
                path="/controlDomains"
                element={
                  <AuthRoute>
                    <ControlDomains />
                  </AuthRoute>
                }
              />
              <Route
                path="/controlDomains/add"
                element={
                  <AuthRoute>
                    <AddControlDomain />
                  </AuthRoute>
                }
              />

              <Route
                path="/controlDomains/:id"
                element={
                  <AuthRoute>
                    <EditControlDomain />
                  </AuthRoute>
                }
              />
              {/* ---- */}

              <Route
                path="/controlReferences"
                element={
                  <AuthRoute>
                    <ControlReferences />
                  </AuthRoute>
                }
              />
              <Route
                path="/controlReferences/add"
                element={
                  <AuthRoute>
                    <AddControlReference />
                  </AuthRoute>
                }
              />

              <Route
                path="/controlReferences/:id"
                element={
                  <AuthRoute>
                    <EditControlReference />
                  </AuthRoute>
                }
              />
              {/* ---- */}

              <Route
                path="/organizationTypes"
                element={
                  <AuthRoute>
                    <OrganizationTypes />
                  </AuthRoute>
                }
              />
              <Route
                path="/organizationTypes/add"
                element={
                  <AuthRoute>
                    <AddOrganizationType />
                  </AuthRoute>
                }
              />

              <Route
                path="/organizationTypes/:id"
                element={
                  <AuthRoute>
                    <EditOrganizationType />
                  </AuthRoute>
                }
              />
              {/* ---- */}

              <Route
                path="/sectors"
                element={
                  <AuthRoute>
                    <Sectors />
                  </AuthRoute>
                }
              />
              <Route
                path="/sectors/add"
                element={
                  <AuthRoute>
                    <AddSector />
                  </AuthRoute>
                }
              />

              <Route
                path="/sectors/:id"
                element={
                  <AuthRoute>
                    <EditSector />
                  </AuthRoute>
                }
              />

              <Route
                path="/users"
                element={
                  <AuthRoute>
                    <UsersIndex />
                  </AuthRoute>
                }
              />
              <Route
                path="/users/adduser"
                element={
                  <AuthRoute>
                    <AddUser />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/:id"
                element={
                  <AuthRoute>
                    <EditUser />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories"
                element={
                  <AuthRoute>
                    <CategoriesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/addcategory"
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/:id"
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs"
                element={
                  <AuthRoute>
                    <BlogsIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/addblog"
                element={
                  <AuthRoute>
                    <AddBlog />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs/:id"
                element={
                  <AuthRoute>
                    <EditBlog />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/show/:id"
                element={
                  <AuthRoute>
                    <ShowBlog />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/categories"
                element={
                  <AuthRoute>
                    <BlogCategoriesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/categories/addcategory"
                element={
                  <AuthRoute>
                    <AddBlogCategory />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs/categories/:id"
                element={
                  <AuthRoute>
                    <EditBlogCategory />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <AuthRoute>
                    <Settings />
                  </AuthRoute>
                }
              />

              {/* pages */}
              <Route
                path="/pages"
                element={
                  <AuthRoute>
                    <Pages />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/addPage"
                element={
                  <AuthRoute>
                    <AddPage />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/:id"
                element={
                  <AuthRoute>
                    <EditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/contactUs/show/:id"
                element={
                  <AuthRoute>
                    <ShowContact />
                  </AuthRoute>
                }
              />
              {/*  */}
              <Route
                path="/contactUs"
                element={
                  <AuthRoute>
                    <ContactUs />
                  </AuthRoute>
                }
              />
              {/*  */}
              <Route
                path="/subscribers"
                element={
                  <AuthRoute>
                    <Subscribers />
                  </AuthRoute>
                }
              />
              {/* contineu poages */}
              <Route
                path="/pages/why-us"
                element={
                  <AuthRoute>
                    <WhyUsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/how-works"
                element={
                  <AuthRoute>
                    <HowWorksPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/faqs"
                element={
                  <AuthRoute>
                    <Faqs />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/packageInfo"
                element={
                  <AuthRoute>
                    <PackageInfo />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/terms-and-conditions"
                element={
                  <AuthRoute>
                    <TermsConditions />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/privacyTerms"
                element={
                  <AuthRoute>
                    <PrivacyTerms />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/cookiesPolicy"
                element={
                  <AuthRoute>
                    <CookiesPolicy />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/about-us"
                element={
                  <AuthRoute>
                    <AboutUsPage />
                  </AuthRoute>
                }
              />

              {/* ============== */}
              {/* <Route
                path="/pages/home-hero"
                element={
                  <AuthRoute>
                    <HomeHero />
                  </AuthRoute>
                }
              /> */}
              <Route
                path="/pages/home-intro"
                element={
                  <AuthRoute>
                    <HomeIntro />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/stayConnected"
                element={
                  <AuthRoute>
                    <StayConnected />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/regoSee"
                element={
                  <AuthRoute>
                    <RegoSee />
                  </AuthRoute>
                }
              />
              <Route
                path="/pages/regoTell"
                element={
                  <AuthRoute>
                    <RegoTell />
                  </AuthRoute>
                }
              />

              <Route
                path="/roles"
                element={
                  <AuthRoute path={"/roles"}>
                    <Roles />
                  </AuthRoute>
                }
              />
              <Route
                path="/roles/addRole"
                element={
                  <AuthRoute path={"/roles/addRole"}>
                    <AddRole />
                  </AuthRoute>
                }
              />
              <Route
                path="/roles/:id"
                element={
                  <AuthRoute path={"/roles/:id"}>
                    <EditRole />
                  </AuthRoute>
                }
              />

              <Route
                path="/admins"
                element={
                  <AuthRoute path={"/admins"}>
                    <Admins />
                  </AuthRoute>
                }
              />
              <Route
                path="/admins/addAdmin"
                element={
                  <AuthRoute path={"/admins/addAdmin"}>
                    <AddAdmin />
                  </AuthRoute>
                }
              />
              <Route
                path="/admins/:id"
                element={
                  <AuthRoute path={"/admins/:id"}>
                    <EditAdmin />
                  </AuthRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <AuthRoute>
                    <Profile />
                  </AuthRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
            {/* <Route
              path="/builder"
              element={
                <AuthRoute>
                  <BuilderPage />
                </AuthRoute>
              }
            /> */}
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
