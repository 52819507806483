import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ALL_SETTINGS,
  EDIT_MAIN_SETTINGS,
  EDIT_SOCIALS_SETTINGS,
  EDIT_MAIL_SETTINGS,
  EDIT_ACCOUNTS_SETTINGS,
  ADD_ACCOUNTS_SETTINGS,
  DELETE_SETTINGS,
} from "./actionTypes";

import { getSettingsBySlug } from "../../helpers/functions";

import {
  getAllSettingsApi,
  editMainSettingsApi,
  editSocialsSettingsApi,
  editMailSettingsApi,
  editAccountsSettingsApi,
  addAccountsSettingsApi,
  deleteSettingsApi,
} from "api/settings";

import {
  getAllSettingsSuccess,
  getAllSettingsFailure,
  editMainSettingsSuccess,
  editMainSettingsFailure,
  editSocialsSettingsSuccess,
  editSocialsSettingsFailure,
  editMailSettingsSuccess,
  editMailSettingsFailure,
  editAccountsSettingsSuccess,
  editAccountsSettingsFailure,
  addAccountsSettingsSuccess,
  addAccountsSettingsFailure,
  deleteSettingsSuccess,
  deleteSettingsFailure,
} from "./actions";

// api calls
function* getAllSettingss() {
  try {
    const {
      data: { settings },
    } = yield call(getAllSettingsApi);

    const mainSettings = getSettingsBySlug(settings, "main")?.items;
    const accountsSettings = getSettingsBySlug(settings, "bankAccounts")?.items;
    const socialSettings = getSettingsBySlug(settings, "social")?.items;
    const mailSettings = getSettingsBySlug(settings, "EmailContent")?.items;

    yield put(
      getAllSettingsSuccess({
        settings,
        mainSettings,
        socialSettings,
        mailSettings,
        accountsSettings,
      })
    );
  } catch (error) {
    yield put(getAllSettingsFailure(error.response?.data?.message || error));
  }
}

function* editMainSettings({ payload }) {
  try {
    const {
      data: { settings },
    } = yield call(editMainSettingsApi, payload);

    yield put(editMainSettingsSuccess(settings));
  } catch (error) {
    yield put(editMainSettingsFailure(error.response?.data?.message || error));
  }
}

function* addAccountsSettings({ payload }) {
  try {
    const {
      data: { setting },
    } = yield call(addAccountsSettingsApi, payload);

    yield put(addAccountsSettingsSuccess(setting));
  } catch (error) {
    yield put(
      addAccountsSettingsFailure(error.response?.data?.message || error)
    );
  }
}

function* editAccountsSettings({ payload }) {
  try {
    const {
      data: { settings },
    } = yield call(editAccountsSettingsApi, payload);

    yield put(editAccountsSettingsSuccess(settings));
  } catch (error) {
    yield put(
      editAccountsSettingsFailure(error.response?.data?.message || error)
    );
  }
}

function* deleteSettings({ payload }) {
  try {
    yield call(deleteSettingsApi, payload);

    yield put(deleteSettingsSuccess(payload));
  } catch (error) {
    yield put(deleteSettingsFailure(error.response?.data?.message || error));
  }
}

function* editSocialsSettings({ payload }) {
  try {
    const {
      data: { settings },
    } = yield call(editSocialsSettingsApi, payload);

    yield put(editSocialsSettingsSuccess(settings));
  } catch (error) {
    yield put(
      editSocialsSettingsFailure(error.response?.data?.message || error)
    );
  }
}

function* editMailsSettings({ payload }) {
  try {
    const {
      data: { settings },
    } = yield call(editMailSettingsApi, payload);

    yield put(editMailSettingsSuccess(settings));
  } catch (error) {
    yield put(editMailSettingsFailure(error.response?.data?.message || error));
  }
}

// watchers
export function* watchgetAllSettings() {
  yield takeEvery(GET_ALL_SETTINGS, getAllSettingss);
}

export function* watchEditMainSettings() {
  yield takeEvery(EDIT_MAIN_SETTINGS, editMainSettings);
}

export function* watchAddAccountsSettings() {
  yield takeEvery(ADD_ACCOUNTS_SETTINGS, addAccountsSettings);
}

export function* watchEditAccountsSettings() {
  yield takeEvery(EDIT_ACCOUNTS_SETTINGS, editAccountsSettings);
}

export function* watchDeleteSettings() {
  yield takeEvery(DELETE_SETTINGS, deleteSettings);
}

export function* watchEditSocialsSettings() {
  yield takeEvery(EDIT_SOCIALS_SETTINGS, editSocialsSettings);
}
export function* watchEditMailSettings() {
  yield takeEvery(EDIT_MAIL_SETTINGS, editMailsSettings);
}

function* settingsSaga() {
  yield all([fork(watchgetAllSettings)]);
  yield all([fork(watchEditMainSettings)]);
  yield all([fork(watchAddAccountsSettings)]);
  yield all([fork(watchEditAccountsSettings)]);
  yield all([fork(watchDeleteSettings)]);
  yield all([fork(watchEditSocialsSettings)]);
  yield all([fork(watchEditMailSettings)]);
}

export default settingsSaga;
