import {
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATION_TYPES_FAILURE,
  GET_ORGANIZATION_TYPES_SUCCESS,
  ADD_ORGANIZATION_TYPE,
  ADD_ORGANIZATION_TYPE_SUCCESS,
  ADD_ORGANIZATION_TYPE_FAILURE,
  EDIT_ORGANIZATION_TYPE,
  EDIT_ORGANIZATION_TYPE_SUCCESS,
  EDIT_ORGANIZATION_TYPE_FAILURE,
  DELETE_ORGANIZATION_TYPE,
  DELETE_ORGANIZATION_TYPE_SUCCESS,
  DELETE_ORGANIZATION_TYPE_FAILURE,
  GET_SINGLE_ORGANIZATION_TYPE,
  GET_SINGLE_ORGANIZATION_TYPE_SUCCESS,
  GET_SINGLE_ORGANIZATION_TYPE_FAILURE,
  SET_DEFAULT_ORGANIZATION_TYPE_SUCCESS,
} from "./actionTypes";

export const getOrganizationTypes = () => {
  return {
    type: GET_ORGANIZATION_TYPES,
  };
};

export const getOrganizationTypesSuccess = (users) => {
  return {
    type: GET_ORGANIZATION_TYPES_SUCCESS,
    payload: users,
  };
};

export const getOrganizationTypesFailure = (error) => {
  return {
    type: GET_ORGANIZATION_TYPES_FAILURE,
    payload: error,
  };
};

export const getSingleOrganizationType = (id) => {
  return {
    type: GET_SINGLE_ORGANIZATION_TYPE,
    payload: id,
  };
};

export const getSingleOrganizationTypeSuccess = (user) => {
  return {
    type: GET_SINGLE_ORGANIZATION_TYPE_SUCCESS,
    payload: user,
  };
};

export const getSingleOrganizationTypeFailure = (error) => {
  return {
    type: GET_SINGLE_ORGANIZATION_TYPE_FAILURE,
    payload: error,
  };
};

export const addOrganizationType = (payload) => {
  return {
    type: ADD_ORGANIZATION_TYPE,
    payload,
  };
};

export const addOrganizationTypeSuccess = (payload) => {
  return {
    type: ADD_ORGANIZATION_TYPE_SUCCESS,
    payload,
  };
};

export const addOrganizationTypeFailure = (error) => {
  return {
    type: ADD_ORGANIZATION_TYPE_FAILURE,
    payload: error,
  };
};

export const editOrganizationType = (payload) => {
  return {
    type: EDIT_ORGANIZATION_TYPE,
    payload,
  };
};

export const editOrganizationTypeSuccess = (payload) => {
  return {
    type: EDIT_ORGANIZATION_TYPE_SUCCESS,
    payload,
  };
};

export const editOrganizationTypeFailure = (error) => {
  return {
    type: EDIT_ORGANIZATION_TYPE_FAILURE,
    payload: error,
  };
};

export const deleteOrganizationType = (id) => {
  return {
    type: DELETE_ORGANIZATION_TYPE,
    payload: id,
  };
};

export const deleteOrganizationTypeSuccess = (id) => {
  return {
    type: DELETE_ORGANIZATION_TYPE_SUCCESS,
    payload: id,
  };
};

export const deleteOrganizationTypeFailure = (error) => {
  return {
    type: DELETE_ORGANIZATION_TYPE_FAILURE,
    payload: error,
  };
};

export const setDefaultOrganizationTypeSuccess = (payload) => {
  return {
    type: SET_DEFAULT_ORGANIZATION_TYPE_SUCCESS,
    payload,
  };
};
