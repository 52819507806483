import server from "./server";

export const getBrandsApi = async () => {
  const response = await server().get("/brands");
  return response.data;
};

export const getSingleBrandApi = async (id) => {
  const response = await server().get(`/brands/${id}`);
  return response.data;
};

export const addBrandApi = async (payload) => {
  const response = await server().post("/brands", payload);
  return response.data;
};

export const editBrandApi = async ({ id, data }) => {
  const response = await server().put(`/brands/${id}`, data);
  return response.data;
};

export const deleteBrandApi = async (id) => {
  const response = await server().delete(`/brands/${id}`);
  return response.data;
};
