import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editSubControlDomain,
  getControlDomains,
  getSingleSubControlDomain,
} from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSelect from "react-select";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";

const EditSubControlDomain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { locale } = useIntl();

  const { singleSubControlDomain, loading } = useSelector(
    (state) => state?.subControlDomains
  );
  const { controlDomains } = useSelector((state) => state?.controlDomains);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getSingleSubControlDomain(id));
    if (controlDomains?.length === 0) dispatch(getControlDomains());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singleSubControlDomain?.title,
      controlDomain: getId(singleSubControlDomain?.controlDomain),
    });
  }, [reset, singleSubControlDomain]);

  const onSubmit = (data) => {
    dispatch(editSubControlDomain({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editSubControlDomain" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col xs={12}>
                    <MultiLangWrapper
                      label={
                        <label>
                          <FormattedMessage id={"title"} />
                        </label>
                      }
                      errors={errors?.title}
                      customClass="form-group selectHolder col-12"
                    >
                      {({ lang, classes, key }) => (
                        <input
                          key={key}
                          type="text"
                          className={`form-control form-outline ${classes}`}
                          {...register(`title.${lang}`, { required: true })}
                        />
                      )}
                    </MultiLangWrapper>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="controlDomain" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="controlDomain"
                          rules={{ required: true }}
                          render={({ field: { onChange, value: valuee } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={controlDomains?.map((item) => ({
                                label: item?.title?.[locale],
                                value: getId(item),
                              }))}
                              value={controlDomains
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find(({ value }) => valuee === value)}
                              isMulti={false}
                              onChange={(value) => {
                                onChange(getId(value));
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="save" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubControlDomain;
