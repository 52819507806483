import server from "./server";

export const getProductTypesApi = async () => {
  const response = await server().get("/productTypes");
  return response.data;
};

export const addProductTypesApi = async (payload) => {
  const response = await server().post("/productTypes", payload);
  return response.data;
};

export const editProductTypesApi = async ({ id, data }) => {
  const response = await server().put(`/productTypes/${id}`, data);
  return response.data;
};

export const deleteProductTypesApi = async (id) => {
  const response = await server().delete(`/productTypes/${id}`);
  return response.data;
};
