import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addRole, getPermissions } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { useEffect } from "react";

const AddRole = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions, loading } = useSelector((state) => state?.permissions);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "permissions",
  });

  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);
  const onSubmit = (data) => {
    dispatch(addRole({ data, navigate }));
  };

  const unselectedPermissions = permissions
    ?.map((p) => p?.name)
    ?.sort((a, b) => a.localeCompare(b));

  const handleSelectAll = () => {
    const checkboxes = document.querySelectorAll(".permission-checkbox");

    if (getValues("permissions").length === permissions.length) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      return setValue("permissions", []);
    }
    setValue(
      "permissions",
      permissions?.map((p) => p?.slug)
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addRole" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="name" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col xs="12">
                    <label
                      className="btn btn-info mb-3"
                      onClick={handleSelectAll}
                    >
                      <FormattedMessage id="selectAll" />
                    </label>
                    <div className="form-group branches features">
                      {/* <h5>
                        <FormattedMessage id={"statistics"} />
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            append({});
                          }}
                        >
                          <FormattedMessage id={"addstatistic"} />
                        </button>
                      </h5> */}
                      <Row>
                        {[
                          ...new Set([...fields, ...unselectedPermissions]),
                        ].map((item, index) => {
                          return (
                            <Col xxl="4" lg={6} xs={12} key={index}>
                              <label className="form-group permission">
                                <input
                                  type="checkbox"
                                  className="form-control form-outline permission-checkbox"
                                  placeholder={formatMessage({
                                    id: "permission",
                                  })}
                                  value={item?.slug || `${item}`}
                                  {...register(`permissions[]`)}
                                />
                                <h5 className="mx-2 d-inline-block mb-0">
                                  {item?.name || `${item}`}
                                  <br />
                                  <span
                                    className="small"
                                    style={{ color: "inherit" }}
                                  >
                                    {item?.slug}
                                  </span>
                                </h5>
                              </label>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="add" />
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRole;
