import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";

import Loader from "components/shared/Loader";
import { addPage } from "store/actions";
import { Input, Textarea } from "components/shared/FormComponents";

const AddPage = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.pages);
  // eslint-disable-next-line no-unused-vars
  const [description, setDescription] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // error
  useEffect(() => {
    if (error) {
      let errorMessage = formatMessage({ id: "sendingErrorMessage" });

      if (error?.statusCode === 422) {
        errorMessage = formatMessage({ id: "validationAddPageErrorMessage" });
      }

      toast.error(errorMessage, {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
      });
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    data = {
      description: description,
      title: data?.title,
      content: {},
    };

    dispatch(addPage({ data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"addPage"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"title"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>
                      <Input
                        name="title.ar"
                        register={register}
                        errors={errors}
                        rules={{ required: false }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"title"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>
                      <Input
                        name="title.en"
                        register={register}
                        errors={errors}
                        rules={{ required: false }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Textarea
                        register={register}
                        name="description.ar"
                        errors={errors}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>

                      <Textarea
                        register={register}
                        name="description.en"
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPage;
