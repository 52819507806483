import server from "./server";

export const getSectionsApi = async (questionId) => {
  const response = await server().get(`/sections/${questionId}`);
  return response.data;
};

export const getSingleSectionApi = async (id) => {
  const response = await server().get(`/sections/${id}`);
  return response.data;
};

export const addSectionApi = async ({ data }) => {
  const response = await server().post(`/sections/`, data);
  return response.data;
};

export const editSectionApi = async ({ id, data }) => {
  const response = await server().put(`/sections/${id}`, data);
  return response.data;
};

export const deleteSectionApi = async (id) => {
  const response = await server().delete(`/sections/${id}`);
  return response.data;
};
