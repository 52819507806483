export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const GET_ALL_CONTACTS_SUCCESS = "GET_ALL_CONTACTS_SUCCESS";
export const GET_ALL_CONTACTS_FAILURE = "GET_ALL_CONTACTS_FAILURE";

export const DELETE_CONTACTS = "DELETE_CONTACTS";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";
export const DELETE_CONTACTS_FAILURE = "DELETE_CONTACTS_FAILURE";

export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";
