import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getSectorsApi,
  addSectorApi,
  editSectorApi,
  deleteSectorApi,
  getSingleSectorApi,
  setDefaultItemApi,
} from "api/sectors";

// Login Redux States
import {
  ADD_SECTOR,
  DELETE_SECTOR,
  EDIT_SECTOR,
  GET_SINGLE_SECTOR,
  GET_SECTORS,
  SET_DEFAULT_ITEM,
} from "./actionTypes";
import {
  addSectorFailure,
  addSectorSuccess,
  getSectorsSuccess,
  getSectorsFailure,
  editSectorSuccess,
  editSectorFailure,
  deleteSectorSuccess,
  deleteSectorFailure,
  getSingleSectorSuccess,
  getSingleSectorFailure,
  setDefaultSectorSuccess,
} from "./actions";
import {
  getSubControlDomainsSuccess,
  setDefaultSubControlDomainSuccess,
} from "../subControlDomains/actions";
import {
  getControlDomainsSuccess,
  setDefaultControlDomainSuccess,
} from "../controlDomains/actions";
import {
  getControlReferencesSuccess,
  setDefaultControlReferenceSuccess,
} from "../controlReferences/actions";
import {
  getOrganizationTypesSuccess,
  setDefaultOrganizationTypeSuccess,
} from "../organizationTypes/actions";

function* getSectors() {
  try {
    const { data } = yield call(getSectorsApi);
    yield put(getSectorsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSectorsFailure(error?.response?.data.errors[0].msg));
  }
}

function* getSingleSector({ payload }) {
  try {
    const { data } = yield call(getSingleSectorApi, payload);
    yield put(getSingleSectorSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleSectorFailure(error?.response?.data || error));
  }
}

function* addSector({ payload }) {
  try {
    const { data } = yield call(addSectorApi, payload.data);
    yield put(addSectorSuccess(data));
    yield payload.navigate("/sectors");
  } catch (error) {
    console.log(error);
    yield put(addSectorFailure(error.response?.data?.message || error));
  }
}

function* editSector({ payload }) {
  try {
    const { data } = yield call(editSectorApi, payload);
    yield put(editSectorSuccess(data));
    yield payload.navigate("/sectors");
  } catch (error) {
    console.log(error);
    yield put(editSectorFailure(error.response?.data?.message || error));
  }
}

function* deleteSector({ payload }) {
  try {
    yield call(deleteSectorApi, payload);
    yield put(deleteSectorSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteSectorFailure(error?.response?.data || error));
  }
}

function* setDefaultItem({ payload }) {
  try {
    const { data } = yield call(setDefaultItemApi, payload);
    if (payload?.route === "sectors")
      // yield put(setDefaultSectorSuccess(payload));
      yield put(getSectorsSuccess(data));
    if (payload?.route === "subControlDomains")
      // yield put(setDefaultSubControlDomainSuccess(payload));
      yield put(getSubControlDomainsSuccess(data));
    if (payload?.route === "controlDomains")
      // yield put(setDefaultControlDomainSuccess(payload));
      yield put(getControlDomainsSuccess(data));
    if (payload?.route === "controlReferences")
      // yield put(setDefaultControlReferenceSuccess(payload));
      yield put(getControlReferencesSuccess(data));
    if (payload?.route === "organizationTypes")
      // yield put(setDefaultOrganizationTypeSuccess(payload));
      yield put(getOrganizationTypesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(deleteSectorFailure(error?.response?.data || error));
  }
}

export function* watchGetSectors() {
  yield takeEvery(GET_SECTORS, getSectors);
}

export function* watchSingleSector() {
  yield takeEvery(GET_SINGLE_SECTOR, getSingleSector);
}

export function* watchAddSector() {
  yield takeEvery(ADD_SECTOR, addSector);
}

export function* watchEditSector() {
  yield takeEvery(EDIT_SECTOR, editSector);
}

export function* watchDeleteSector() {
  yield takeEvery(DELETE_SECTOR, deleteSector);
}

export function* watchDefaultItem() {
  yield takeEvery(SET_DEFAULT_ITEM, setDefaultItem);
}

function* SectorsSaga() {
  yield all([fork(watchGetSectors)]);
  yield all([fork(watchSingleSector)]);
  yield all([fork(watchAddSector)]);
  yield all([fork(watchEditSector)]);
  yield all([fork(watchDeleteSector)]);
  yield all([fork(watchDefaultItem)]);
}

export default SectorsSaga;
