import React from "react";
import { Row } from "react-bootstrap";
import { sidebarData } from "../../data";
import TopBlockItem from "./TopBlockItem";

const BlocksTop = () => {
  const topCards = sidebarData
    ?.filter((e) => !e?.separator)
    ?.map((topcard) => {
      if (topcard.text === "dashboard") return "";
      return <TopBlockItem key={topcard.id} topcard={topcard} />;
    });
  return (
    <>
      <div className="topCards">
        <Row>{topCards}</Row>
      </div>
    </>
  );
};

export default BlocksTop;
