import React from "react";
import removeImg from "assets/svgs/close.svg";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MultiLangWrapper from "components/shared/MultiLangsWrapper";
import { Editor } from "components/shared/Editor";
import { Controller } from "react-hook-form";

const SlidersArray = ({ sliders, setSliders, control }) => {
  const handleInputOnChange = (e, targetIndex) => {
    const { name, value } = e.target;
    const newSlidersArray = sliders.map((slider, index) => {
      if (targetIndex !== index) return slider;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...slider,
          [inputName]: {
            ...slider?.[inputName],
            [local]: value,
          },
        };
      }
      return { ...slider, [name]: value };
    });

    setSliders(newSlidersArray);
  };

  const removeSlider = (targetIndex) => {
    const newSliders = sliders.filter((slider, index) => index !== targetIndex);
    setSliders(() => newSliders);
  };

  const addSlider = () => {
    setSliders(() => [...sliders, {}]);
  };

  return (
    <div className="branches mt-3">
      <Row>
        <Col lg={12}>
          {/* <h5>
            <FormattedMessage id={"sliders"} />
          </h5> */}
          <Row>
            {sliders.map((slider, index) => {
              return (
                <div className="position-relative add-multi-component mt-3 row">
                  {/* <button
                    type="button"
                    onClick={() => removeSlider(index)}
                    className="removeImg"
                  >
                    <img src={removeImg} alt="" />
                  </button>
                  <Col xs="12" md="6" key={index}>
                    <Row>
                      <MultiLangWrapper
                        label={
                          <label>
                            <FormattedMessage id={"title"} />
                          </label>
                        }
                        customClass="form-group selectHolder col-12"
                      >
                        {({ lang, classes, key }) => (
                          <input
                            key={`${index}${key}`}
                            type="text"
                            name={`title.${lang}`}
                            className={`form-control form-outline ${classes}`}
                            onChange={(e) => handleInputOnChange(e, index)}
                            value={slider?.title?.[lang]}
                          />
                        )}
                      </MultiLangWrapper>
                    </Row>
                  </Col> */}
                  <Col xs="12" xxl="8" key={index}>
                    <Row>
                      <MultiLangWrapper
                        label={
                          <label>
                            <FormattedMessage id={"content"} />
                          </label>
                        }
                        customClass="form-group selectHolder col-12"
                      >
                        {({ lang, classes, key }) => (
                          <div className={`${classes}`}>
                            <Controller
                              key={key}
                              control={control}
                              name={`sections.${index}.title.${lang}`}
                              render={({ field: { value, onChange } }) => (
                                <Editor
                                  type="text"
                                  key={key}
                                  initialValue={value}
                                  className={`form-control form-outline`}
                                  onChange={(_, editor) => {
                                    const data = editor.getContent();
                                    onChange(data);
                                  }}
                                  init={{
                                    directionality:
                                      lang === "ar" ? "rtl" : "ltr",
                                  }}
                                />
                              )}
                            />
                          </div>
                        )}
                      </MultiLangWrapper>
                    </Row>
                  </Col>
                </div>
              );
            })}
          </Row>
        </Col>

        {/* <Col lg={4} xs={12} className="text-left mt-2">
          <button
            type="button"
            className="btn btn-green add_new_section__ mt-2"
            onClick={() => {
              addSlider();
            }}
          >
            <FormattedMessage id={"addSlider"} />
          </button>
        </Col> */}
      </Row>
    </div>
  );
};

export default SlidersArray;
