import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getRolesApi,
  addRoleApi,
  editRoleApi,
  deleteRoleApi,
  getSingleRoleApi,
} from "../../api/roles";

// Login Redux States
import {
  ADD_ROLE,
  DELETE_ROLE,
  EDIT_ROLE,
  GET_SINGLE_ROLE,
  GET_ROLES,
} from "./actionTypes";
import {
  addRoleFailure,
  addRoleSuccess,
  getRolesSuccess,
  getRolesFailure,
  editRoleSuccess,
  editRoleFailure,
  deleteRoleSuccess,
  deleteRoleFailure,
  getSingleRoleSuccess,
  getSingleRoleFailure,
} from "./actions";

function* getRoles() {
  try {
    const { data } = yield call(getRolesApi);
    yield put(getRolesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getRolesFailure(error?.response?.data?.errors?.[0].msg));
  }
}

function* getSingleRole({ payload }) {
  try {
    const { data } = yield call(getSingleRoleApi, payload);
    yield put(getSingleRoleSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleRoleFailure(error?.response?.data || error));
  }
}

function* addRole({ payload }) {
  try {
    const { data } = yield call(addRoleApi, payload.data);
    yield put(addRoleSuccess(data));
    yield payload.navigate("/roles");
  } catch (error) {
    console.log(error);
    yield put(addRoleFailure(error.response?.data || error));
  }
}

function* editRole({ payload }) {
  try {
    const { data } = yield call(editRoleApi, payload);
    yield put(editRoleSuccess(data));
    yield payload.navigate("/roles");
  } catch (error) {
    console.log(error);
    yield put(editRoleFailure(error.response?.data || error));
  }
}

function* deleteRole({ payload }) {
  try {
    yield call(deleteRoleApi, payload);
    yield put(deleteRoleSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteRoleFailure(error?.response?.data || error));
  }
}

export function* watchGetRoles() {
  yield takeEvery(GET_ROLES, getRoles);
}

export function* watchSingleRole() {
  yield takeEvery(GET_SINGLE_ROLE, getSingleRole);
}

export function* watchAddRole() {
  yield takeEvery(ADD_ROLE, addRole);
}

export function* watchEditRole() {
  yield takeEvery(EDIT_ROLE, editRole);
}

export function* watchDeleteRole() {
  yield takeEvery(DELETE_ROLE, deleteRole);
}

function* RolesSaga() {
  yield all([fork(watchGetRoles)]);
  yield all([fork(watchSingleRole)]);
  yield all([fork(watchAddRole)]);
  yield all([fork(watchEditRole)]);
  yield all([fork(watchDeleteRole)]);
}

export default RolesSaga;
