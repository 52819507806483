import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import removeImg from "assets/svgs/close.svg";
import imageUpload from "assets/svgs/imageUpload.svg";
import { editMainSettings } from "store/actions";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
// import MultiLangWrapper from "components/shared/MultiLangsWrapper";
// import { toast } from "react-toastify";

const ordered = [
  "siteName",
  "description_ar",
  "description_en",
  "copyright",
  "logo",
  "keywords",
  "robots",
  "cartTax",
];

const MainSettings = ({ settings, title }) => {
  const { loading } = useSelector((state) => state.settings);
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);

      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage("");
  };

  const onSubmit = (data) => {
    let updatedMainSettings = [];

    for (const [key, value] of Object.entries(data)) {
      let tempOptoint = {
        title: key,
        value: value,
      };

      if (key === "logo" && selectedImage?.path) {
        tempOptoint.value = "uploads/" + selectedImage?.path?.split("/").at(-1);
      } else if (key === "logo" && !selectedImage?.path) {
        continue;
      }

      updatedMainSettings.push(tempOptoint);
    }

    dispatch(editMainSettings(updatedMainSettings));
  };
  useEffect(() => {
    settings?.map((setting) => setValue(setting?.title, setting.value));
  }, [setValue, settings]);

  const renderMainSettings = useCallback(
    () =>
      settings
        ?.sort(
          (a, b) => ordered?.indexOf(a?.title) - ordered?.indexOf(b?.title)
        )
        ?.map((setting) => {
          // setValue(setting?.title, setting.value);

          if (setting?.title === "logo") {
            return (
              <Col lg={12} xs={12} key={setting?.title} style={{ order: 1 }}>
                <div className="form-group required">
                  <h5>{setting.name[locale]}</h5>
                  <div className="image-uploader image-contain">
                    <div className="img-pat">
                      <img src={imageUpload} width={46} height={46} alt="" />
                    </div>
                    <label>
                      {selectedImage && (
                        <div>
                          <img
                            src={
                              selectedImage && selectedImage.preview
                                ? URL.createObjectURL(selectedImage.preview)
                                : setting.value
                            }
                            width={160}
                            height={160}
                            alt=""
                          />
                          <button
                            className="removeImg"
                            onClick={removeSelectedImage}
                          >
                            <img src={removeImg} alt="" />
                          </button>
                        </div>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        onChange={imageChange}
                      />
                    </label>
                  </div>
                </div>
              </Col>
            );
          }
          if (setting?.type === "textarea")
            return (
              <Col lg={12} xs={12} key={setting?.title} style={{ order: 2 }}>
                <div className="form-group required">
                  <h5>{setting.name?.[locale]}</h5>
                  <div>
                    <textarea
                      type="text"
                      className="form-control form-outline h-auto"
                      rows="7"
                      placeholder={setting.name?.[locale]}
                      {...register(setting?.title, { required: true })}
                    />
                  </div>
                  <p className="error-hint">
                    {errors?.[setting?.title] && (
                      <FormattedMessage id={"requiredField"} />
                    )}
                  </p>
                </div>
              </Col>
            );
          if (setting?.type === "number")
            return (
              <Col lg={12} xs={12} key={setting?.title} style={{ order: 2 }}>
                <div className="form-group required">
                  <h5>{setting.name?.[locale]}</h5>
                  <div>
                    <input
                      type="number"
                      className="form-control form-outline"
                      placeholder={setting.name?.[locale]}
                      {...register(setting?.title, { required: true })}
                    />
                  </div>
                  <p className="error-hint">
                    {errors?.[setting?.title] && (
                      <FormattedMessage id={"requiredField"} />
                    )}
                  </p>
                </div>
              </Col>
            );
          return (
            <Col lg={12} xs={12} key={setting?.title} style={{ order: 2 }}>
              <div className="form-group required">
                <h5>{setting.name?.[locale]}</h5>
                <div>
                  <input
                    type="text"
                    className="form-control form-outline"
                    placeholder={setting.name?.[locale]}
                    {...register(setting?.title, { required: true })}
                  />
                </div>
                <p className="error-hint">
                  {errors?.[setting?.title] && (
                    <FormattedMessage id={"requiredField"} />
                  )}
                </p>
              </div>
            </Col>
          );
        }),
    [errors?.setting?.title, locale, register, selectedImage, settings]
  );
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>{title}</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>{renderMainSettings()}</Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSettings;
